import { useNavigate } from '@reach/router';
import { FormSubmit } from 'components';
import { useAnalytics } from 'modules/analytics/hooks/useAnalytics';
import { AppDispatch, ApplicationState } from 'modules/redux-store';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { buttons } from 'styles';

import { AuthThunks } from '../redux';

export const AnonymousLogin: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { sendCartItemsInteraction } = useAnalytics();
  const { products } = useSelector((state: ApplicationState) => state.cart);

  const navigate = useNavigate();
  const { authStateChanging, isUserAnon } = useSelector(
    (state: ApplicationState) => state.auth,
  );

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const handleAnonymousLogin = async () => {
    const result = await dispatch(AuthThunks.anonymousLoginAsync());
    sendCartItemsInteraction(
      {
        interactionProducts: products.map((product) => {
          return {
            Name: product.title,
            ItemId: product.id,
          };
        }),
      },
      result?.user?.uid,
    );
    if (!result) {
      navigate('/kosarica/podaci', { replace: true });
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleAnonymousLogin)}>
        <FormSubmit
          text="Nastavi"
          className={buttons.ghost.medium}
          stateIsChanging={isUserAnon && authStateChanging}
          isUserAnon={isUserAnon}
        />
      </form>
    </FormProvider>
  );
};
