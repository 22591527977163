import { Distributor } from 'modules/distributors';
import {
  FirebaseFunctionsService,
  FirestoreListenerActions,
  FirestoreQueryParam,
} from 'modules/firebase';
import { AppDispatch, ApplicationState } from 'modules/redux-store';

import { ClientRequest } from '../models';
import { RequestsService } from '../services';
import { RequestsActions } from './actions';

const funcService = FirebaseFunctionsService();

const getAllRequestsFromClients =
  (distributorId: string) =>
  (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const clientRequestsListener =
      getState().listeners[`clientRequestsListener-${distributorId}`];
    if (clientRequestsListener) {
      return;
    }

    dispatch(RequestsActions.addLoading());

    const successFunction = (data: ClientRequest[]) => {
      const currentRequests = getState().clientRequests.clientRequests;

      data.forEach((newRequest) => {
        const sameRequestIndex = currentRequests.findIndex(
          (currentRequest) =>
            currentRequest.id &&
            newRequest.id &&
            currentRequest.id === newRequest.id,
        );
        if (sameRequestIndex !== -1) {
          currentRequests.splice(sameRequestIndex, 1);
        }
      });

      dispatch(
        RequestsActions.addClientRequests([...currentRequests, ...data]),
      );
    };

    const errorFunction = (error: string) =>
      dispatch(RequestsActions.addError(error));

    const listener = RequestsService.Database?.filterAndListen(
      successFunction,
      errorFunction,
      new FirestoreQueryParam('distributorId', '==', distributorId),
    );

    if (listener) {
      dispatch(
        FirestoreListenerActions.SetListener(
          `clientRequestsListener-${distributorId}`,
          listener,
        ),
      );
    }
  };

const getAdminDistributorRequests =
  (distributorId: string) => async (dispatch: AppDispatch) => {
    const allRequests = await RequestsService.Database?.filter({
      field: 'distributorId',
      operator: '==',
      value: distributorId,
    });

    if (allRequests)
      dispatch(
        RequestsActions.addClientRequests(allRequests as ClientRequest[]),
      );
  };

const getOwnRequests =
  (clientId: string) =>
  (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const ownRequestsListener = getState().listeners.ownRequestsListener;
    if (ownRequestsListener) {
      return;
    }

    dispatch(RequestsActions.addLoading());

    const successFunction = (data: ClientRequest[]) => {
      dispatch(RequestsActions.addOwnRequests(data));
    };

    const errorFunction = (error: string) =>
      dispatch(RequestsActions.addError(error));

    const listener = RequestsService.Database?.filterAndListen(
      successFunction,
      errorFunction,
      new FirestoreQueryParam('clientId', '==', clientId),
    );

    if (listener) {
      dispatch(
        FirestoreListenerActions.SetListener('ownRequestsListener', listener),
      );
    }
  };

const moveToArchive =
  (id: string, userRole: 'customer' | 'distributor') => () => {
    RequestsService.Database?.archive(id, userRole);
  };

const moveToTrash =
  (id: string, userRole: 'customer' | 'distributor') => () => {
    RequestsService.Database?.moveToTrash(id, userRole);
  };

const setIsRead = (id: string, propName: string) => () => {
  RequestsService.Database?.setIsRead(id, propName);
};

const deleteMail = (id: string) => () => {
  RequestsService.Database?.remove(id);
};

const addNewOffer =
  (
    data: Partial<ClientRequest>,
    distributor?: Distributor,
    sendOfferToMail?: boolean,
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(RequestsActions.addLoading());

    const { clientRequestId, clientInformation, externalOffers } = data;

    const { fileDetails, ...dataWithoutPdf } = data;

    const error = await RequestsService.Database?.update(dataWithoutPdf);

    if (error) {
      dispatch(RequestsActions.addError(error.message));
      return;
    }

    if (sendOfferToMail && distributor) {
      const requestId = `#WBSHOP_${clientRequestId}`;

      const attachment = externalOffers
        ? [
            {
              //send last external offer only
              filename:
                externalOffers[externalOffers?.length - 1]?.documentName,
              path: externalOffers[externalOffers?.length - 1]?.documentUrl,
            },
          ]
        : fileDetails?.fileReader
        ? [
            {
              filename: fileDetails?.fileName,
              path: String(fileDetails?.fileReader.result),
            },
          ]
        : undefined;

      await (
        await funcService
      ).sendOfferNotificationToEmail(
        clientInformation?.email,
        distributor,
        requestId,
        attachment,
        clientInformation?.isClientAnon,
      );
    }

    return;
  };

export const RequestsThunks = {
  getAllRequestsFromClients,
  getOwnRequests,
  moveToArchive,
  moveToTrash,
  setIsRead,
  deleteMail,
  addNewOffer,
  getAdminDistributorRequests,
};
