import { RegistrationForm } from 'modules/authentication';
import { ClientInformation } from 'modules/cart';
import { Distributor } from 'modules/distributors';
import { Attachment } from 'modules/inbox';

export async function FirebaseFunctionsService() {
  const firebase = (
    await (
      await import('./FirebaseService')
    ).FirebaseService
  ).Instance()?.instance;
  await import('firebase/functions');

  async function registerNewUser(data: RegistrationForm) {
    const registerCloudFn = firebase
      ?.functions()
      .httpsCallable('registerNewUser');

    if (!registerCloudFn) return;

    const result = await registerCloudFn({ ...data });
    return result.data;
  }

  async function sendPasswordResetLink(email: string) {
    const registerCloudFn = firebase
      ?.functions()
      .httpsCallable('sendPasswordResetLink');

    if (!registerCloudFn) return;

    const result = await registerCloudFn({ email });
    return result.data;
  }

  async function confirmEmailVerificationLink(email: string) {
    const registerCloudFn = firebase
      ?.functions()
      .httpsCallable('confirmEmailValidity');

    if (!registerCloudFn) return;

    const result = await registerCloudFn({ email });
    return result.data;
  }

  async function sendOfferNotificationToEmail(
    emailTo?: string,
    distributor?: Distributor,
    requestId?: string,
    attachments?: Attachment[],
    isClientAnon?: boolean,
  ) {
    const registerCloudFn = firebase
      ?.functions()
      .httpsCallable('sendOfferNotificationToEmail');

    if (!registerCloudFn) return;

    const result = await registerCloudFn({
      emailTo,
      distributor,
      requestId,
      attachments,
      isClientAnon,
    });

    return result.data;
  }

  async function sendRequestNotificationToAdmin(
    distributor?: Distributor[],
    user?: ClientInformation,
  ) {
    const registerCloudFn = firebase
      ?.functions()
      .httpsCallable('sendRequestNotificationToAdmin');

    if (!registerCloudFn) return;

    const result = await registerCloudFn({
      distributor,
      firstName: user?.firstName,
      lastName: user?.lastName,
    });

    return result.data;
  }

  async function sendRequestInfoNotificationToDistributor(
    pdf: {
      fileReader: FileReader | null;
      fileName: string;
    },
    distributors?: Distributor[],
    user?: ClientInformation,
  ) {
    const registerCloudFn = firebase
      ?.functions()
      .httpsCallable('sendRequestInfoNotificationToDistributor');

    if (!registerCloudFn) return;

    const pdfAttachment: Attachment = {
      filename: pdf.fileName,
      path: String(pdf.fileReader?.result),
    };

    const result = await registerCloudFn({
      distributors,
      firstName: user?.firstName,
      lastName: user?.lastName,
      pdf: pdfAttachment,
    });

    return result.data;
  }

  async function sendConfirmationToUser(
    pdf: {
      fileReader: FileReader | null;
      fileName: string;
    },
    distributors: Distributor[],
    email: string,
  ) {
    const registerCloudFn = firebase
      ?.functions()
      .httpsCallable('sendConfirmationToUser');

    if (!registerCloudFn) return;

    const pdfAttachment: Attachment = {
      filename: pdf.fileName,
      path: String(pdf.fileReader?.result),
    };

    const result = await registerCloudFn({
      distributors,
      email: email,
      pdf: pdfAttachment,
    });

    return result.data;
  }

  async function sendInteractionToApi(
    endpoint: string,
    dataToSend: Record<string, unknown>,
  ) {
    const registerCloudFn = firebase
      ?.functions()
      .httpsCallable('sendInteractionToApi');

    if (!registerCloudFn) return;

    const result = await registerCloudFn({ endpoint, dataToSend });

    return result.data;
  }

  return {
    registerNewUser,
    sendPasswordResetLink,
    confirmEmailVerificationLink,
    sendOfferNotificationToEmail,
    sendRequestNotificationToAdmin,
    sendRequestInfoNotificationToDistributor,
    sendInteractionToApi,
    sendConfirmationToUser,
  };
}
