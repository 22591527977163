export interface ExternalOfferModel {
  documentUrl: string;
  documentName: string;
  date: number;
  distributorRemark: string;
}

export class ExternalOfferModel {
  constructor(object: Partial<ExternalOfferModel>) {
    this.documentUrl = object.documentUrl || '';
    this.documentName = object.documentName || '';
    this.date = object.date || Date.now();
    this.distributorRemark = object.distributorRemark || '';
  }
}
