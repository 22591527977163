import { Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import React from 'react';
import { cardStyles } from 'styles';

interface Props {
  title: string;
  navigateTo: string;
  imageAlt: string;
  imageSrc: any;
}
export const Card: React.FC<Props> = ({
  title,
  navigateTo,
  imageSrc,
  imageAlt,
}) => {
  return (
    <Link to={navigateTo}>
      <div css={cardStyles.wrapper}>
        <GatsbyImage {...imageSrc} css={cardStyles.image} alt={imageAlt} />
        <div css={cardStyles.container}>
          <p css={cardStyles.title}>{title}</p>
        </div>
      </div>
    </Link>
  );
};
