import { CloseIcon, ToastSuccessIcon } from 'assets';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import { ToastContentProps } from 'react-toastify';
import { buttons, inbox, spacers } from 'styles';

interface OwnProps {
  title?: string;
  text?: string;
  showSuccessIcon?: boolean;
}

type Props = OwnProps & Partial<ToastContentProps>;

export const SuccessToast: React.FC<Props> = ({
  title,
  text,
  closeToast,
  showSuccessIcon = true,
}) => {
  const { formatMessage } = useIntl();
  return (
    <div css={inbox.toastWrapper}>
      <div css={inbox.toastInfo}>
        {showSuccessIcon && <ToastSuccessIcon />}
        <strong css={[spacers.left.large]}>
          {title || formatMessage({ id: 'inbox.success-toast.title' })}
        </strong>
        <p css={[spacers.left.xLarge]}>
          {text || formatMessage({ id: 'inbox.success-toast.text' })}
        </p>
      </div>

      <button onClick={closeToast} css={buttons.container}>
        <CloseIcon />
      </button>
    </div>
  );
};
