import { SerializedStyles } from '@emotion/core';
import { ErrorMessage } from '@hookform/error-message';
import { SelectChevron } from 'assets';
import { useIntl } from 'gatsby-plugin-intl';
import { Brick } from 'modules/calculator';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Select, { components } from 'react-select';
import { fields, selectStyles } from 'styles';

type DropdownValue = Brick;

interface Props {
  name: string;
  label: string | JSX.Element;
  sublabel?: string;
  defaultValue?: SelectOption<string>;
  options: SelectOption<string | number>[];
  required?: boolean;
  disabled?: boolean;
  getDropdownValue?: (value: SelectOption<DropdownValue>) => void;
  triggerReset?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  className?: SerializedStyles;
}

export interface SelectOption<T> {
  value: T;
  id?: string;
  label: string | JSX.Element;
}

export const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectChevron />
    </components.DropdownIndicator>
  );
};

export const ValidatedSelect: React.FC<Props> = ({
  name,
  label,
  defaultValue,
  options,
  placeholder,
  required,
  getDropdownValue,
  className,
  isDisabled = false,
  triggerReset = false,
}) => {
  const { formatMessage } = useIntl();
  const { control, errors, reset, watch, setValue } = useFormContext();

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue, reset]);

  useEffect(() => {
    if (getDropdownValue) {
      if (watch()[name]) getDropdownValue(watch()[name]);
    }
  }, [getDropdownValue, name, watch]);

  useEffect(() => {
    if (triggerReset) {
      setValue(name, defaultValue || '');
    }
  }, [defaultValue, name, setValue, triggerReset, reset]);

  return (
    <div css={className || fields.field}>
      <label
        css={errors && errors[name] ? fields.labelError : fields.label}
        htmlFor={name}
      >
        {label}
      </label>

      <Controller
        as={<Select isDisabled={isDisabled} />}
        name={name}
        control={control}
        defaultValue={defaultValue || ''}
        options={options}
        placeholder={
          placeholder
            ? placeholder
            : formatMessage({ id: 'validated-select.placeholder' })
        }
        rules={{
          required: required
            ? formatMessage({ id: 'shared.validation.required' })
            : false,
        }}
        components={{ DropdownIndicator }}
        styles={selectStyles(name, errors)}
      />

      <ErrorMessage
        errors={errors}
        name={name}
        as={<p css={fields.errorMsg} />}
      >
        {({
          messages,
        }: {
          messages: Array<{ type: string; message: string }>;
        }) =>
          messages &&
          Object.entries(messages).map(([type, message]) => (
            <p key={type}>{message}</p>
          ))
        }
      </ErrorMessage>
    </div>
  );
};
