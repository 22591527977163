const sets = [
  { to: 'c', from: '[ĆČčć]' },
  { to: 'd', from: '[Đđ]' },
  { to: 'z', from: '[Žž]' },
  { to: 's', from: '[Šš]' },
];

export function slugify(text: string) {
  sets.forEach((set) => {
    text = text?.replace(new RegExp(set.from, 'gi'), set.to);
  });

  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '');
}
