import {
  CartProduct,
  ClientInformation,
  ClientSummary,
  Currency,
} from '../models';
import { CartActions } from './actions';
import { CartTypes } from './types';

export interface CartState {
  error?: string;
  products: CartProduct[];
  clientInfo: ClientInformation;
  clientSummary: ClientSummary;
  requestId: string;
}

const INITIAL_STATE: CartState = {
  products: [],
  clientInfo: {
    address: '',
    location: { lat: 0, lng: 0 },
    postCode: '',
    city: '',
    country: { value: '', label: '' },
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
  requestId: '',
  clientSummary: {
    totalWithoutTax: 0,
    tax: 0,
    total: 0,
    currency: Currency.CRO,
  },
};

export default (
  state: CartState = INITIAL_STATE,
  action: CartActions,
): CartState => {
  switch (action.type) {
    case CartTypes.UpdateProducts: {
      return {
        ...state,
        products: action.payload.products,
      };
    }

    case CartTypes.AddClientInfo: {
      return { ...state, clientInfo: action.payload.client };
    }

    case CartTypes.SetRequestId: {
      return { ...state, requestId: action.payload.id };
    }

    case CartTypes.SetClientSummary: {
      return {
        ...state,
        clientSummary: action.payload.prices,
      };
    }

    case CartTypes.AddError: {
      return { ...state, error: action.payload.error };
    }

    default:
      return state || INITIAL_STATE;
  }
};
