import { ControlledCheckbox } from 'components';
import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';
import { listing } from 'styles';
import { RoofWindowsState } from 'template/AllRoofWindowsListing';

interface Props {
  models: string[];
  handleChange: (name: string, value: boolean, section?: string) => void;
  filterState: RoofWindowsState;
}

export const RoofWindowFilters: React.FC<Props> = ({
  models,
  handleChange,
  filterState,
}) => (
  <>
    <p css={listing.filterTitle}>
      <FormattedMessage id="products.category.models" />
    </p>
    <ul css={listing.accordionItem}>
      {models.map((model) => (
        <li key={model}>
          <ControlledCheckbox
            name={model}
            text={model}
            section="models"
            onChange={handleChange}
            checked={filterState.models[model]}
          />
        </li>
      ))}
    </ul>
  </>
);
