import { graphql, Link, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { DefaultPhoto } from 'models';
import { ApplicationState } from 'modules/redux-store';
import React from 'react';
import { useSelector } from 'react-redux';
import { product } from 'styles';
import { getProductRoute } from 'utils';

export const LastViewedProducts: React.FC = () => {
  const defaultImagePhoto = useStaticQuery<DefaultPhoto>(graphql`
    query {
      defaultImagePhoto: file(relativePath: { eq: "no-image-product.png" }) {
        id
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const { lastViewedProducts } = useSelector(
    (state: ApplicationState) => state.products,
  );

  if (!lastViewedProducts.length) return null;

  return (
    <section css={product.horizontalListWrapper}>
      <h4 css={product.subTitle}>
        <FormattedMessage id="products.last-viewed" />
      </h4>

      <div css={product.horizontalListScroll}>
        <div css={product.horizontalList}>
          {lastViewedProducts.map((item, index) => {
            const productImage =
              item.commercial.assets.mainImg ||
              item.commercial.assets.otherImgs[0].imgUrl;

            return (
              <Link
                css={product.horizontalListItem}
                key={index}
                to={getProductRoute(item.commercial)}
              >
                {productImage && (
                  <Image
                    fixed={{
                      src:
                        item.commercial.assets.mainImg ||
                        item.commercial.assets.otherImgs[0].imgUrl,
                      srcSet: '',
                      width: 140,
                      height: 140,
                    }}
                  />
                )}

                {!productImage && (
                  <Image
                    {...defaultImagePhoto.defaultImagePhoto.childImageSharp}
                    alt="Bez slike"
                    imgStyle={{ height: 'auto' }}
                  />
                )}

                <p>{item.commercial.localizedName}</p>
              </Link>
            );
          })}
        </div>
      </div>
    </section>
  );
};
