import {
  FirestoreListenerActions,
  FirestoreQueryParam,
} from 'modules/firebase';
import { AppDispatch, ApplicationState } from 'modules/redux-store';

import { Distributor } from '../models';
import { DistributorService } from '../services';
import { DistributorActions } from './actions';

const getActiveDistributors =
  () => (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const distributorListener = getState().listeners.distributorListener;
    if (distributorListener) return;

    dispatch(DistributorActions.addLoading());

    const successFunction = (data: Distributor[]) =>
      dispatch(DistributorActions.addActiveDistributors(data));

    const errorFunction = (error: string) =>
      dispatch(DistributorActions.addError(error));

    const listener = DistributorService.Database?.filterAndListen(
      successFunction,
      errorFunction,
      new FirestoreQueryParam('isActive', '==', true),
    );

    if (listener)
      dispatch(
        FirestoreListenerActions.SetListener('distributorListener', listener),
      );
  };

const getDistributorById = async (id: string) =>
  (await DistributorService.Database?.find(id)) as Distributor;

const getAllDistributors = async () =>
  (await DistributorService.Database?.findAll()) as Distributor[];

export const DistributorThunks = {
  getActiveDistributors,
  getDistributorById,
  getAllDistributors,
};
