import React from 'react';

export const Step3: React.FC = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 3.5V2.5H5.5L4.5 0.5H1.5V3.5"
      stroke="#B30000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.5 5.5V10.5C0.5 10.7652 0.605357 11.0196 0.792893 11.2071C0.98043 11.3946 1.23478 11.5 1.5 11.5H10.5C10.7652 11.5 11.0196 11.3946 11.2071 11.2071C11.3946 11.0196 11.5 10.7652 11.5 10.5V5.5H0.5Z"
      stroke="#B30000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
