import { Collection, Distributor } from 'consts';
import { navigate } from 'gatsby';
import {
  AuthService,
  FirebaseFunctionsService,
  FirestoreService,
} from 'modules/firebase';
import { ClientRequest } from 'modules/inbox';
import { AppDispatch, ApplicationState } from 'modules/redux-store';
import { nanoid } from 'nanoid/non-secure';
import Geocode from 'react-geocode';

import {
  CartProduct,
  ClientInformation,
  ClientSummary,
  Currency,
  DistributorState,
} from '../models';
import { createPdfRequest } from '../utils';
import { CartActions } from './actions';

const authService = AuthService();
const funcService = FirebaseFunctionsService();

const addProduct =
  (product: CartProduct) =>
  (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const products = getState().cart.products;

    let productsToAdd = [...products];

    if (products.some((prod) => prod.id === product.id)) {
      productsToAdd.forEach((prod, i) => {
        if (prod.id === product.id) {
          productsToAdd[i].quantity.quantity += product.quantity.quantity;
        }
      });
      dispatch(CartActions.updateProducts(productsToAdd));
      return;
    }

    productsToAdd = [...productsToAdd, product];

    dispatch(CartActions.updateProducts(productsToAdd));
  };

const addProducts =
  (products: CartProduct[]) =>
  (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const stateProducts = getState().cart.products;

    let productsToAdd = [...stateProducts];

    products.forEach((newProduct) => {
      if (productsToAdd.some((prod) => prod.id === newProduct.id)) {
        productsToAdd.forEach((stateProduct, stateProductIndex) => {
          if (stateProduct.id === newProduct.id) {
            productsToAdd[stateProductIndex].quantity.quantity +=
              newProduct.quantity.quantity;
          }
        });
      } else {
        productsToAdd = [...productsToAdd, newProduct];
      }
    });
    dispatch(CartActions.updateProducts(productsToAdd));
  };

const removeProduct =
  (id: string) => (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const products = getState().cart.products;

    const remainingProducts = products.filter((item) => item.id !== id);

    let totalPrice = 0;

    remainingProducts.forEach((product: CartProduct) => {
      totalPrice += product.quantity.quantity * product.price;
    });

    const prices = {
      totalWithoutTax: +totalPrice.toFixed(2),
      tax: +(totalPrice * 0.25).toFixed(2),
      total: +(totalPrice * 1.25).toFixed(2),
      currency: Currency.CRO,
    };
    dispatch(CartActions.updateProducts(remainingProducts));
    dispatch(CartActions.setClientSummary(prices));
  };

const removeAll = () => (dispatch: AppDispatch) => {
  dispatch(CartActions.updateProducts([]));
};

const updateDryfix =
  (quantity: number) =>
  (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const products = getState().cart.products;

    const productsToUpdate = [...products];

    productsToUpdate.forEach((product) => {
      if (product.id === '4ab935a8-7fef-54c9-a52e-044e251d2708') {
        product.quantity.quantity = quantity;
      }
    });
    dispatch(CartActions.updateProducts(productsToUpdate));
  };

const addQuantity =
  (id: string) => (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const products = getState().cart.products;

    const productsToUpdate = [...products];

    let totalPrice = 0;

    productsToUpdate.forEach((product) => {
      if (product.id === id) {
        product.quantity.quantity++;
      }
      totalPrice += product.quantity.quantity * product.price;
    });

    const prices = {
      totalWithoutTax: +totalPrice.toFixed(2),
      tax: +(totalPrice * 0.25).toFixed(2),
      total: +(totalPrice * 1.25).toFixed(2),
      currency: Currency.CRO,
    };

    dispatch(CartActions.updateProducts(productsToUpdate));

    dispatch(CartActions.setClientSummary(prices));
  };

const removeQuantity =
  (id: string) => (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const products = getState().cart.products;

    const productsToUpdate = [...products];

    let totalPrice = 0;

    productsToUpdate.forEach((product) => {
      if (product.id === id) {
        product.quantity.quantity--;
      }
      totalPrice += product.quantity.quantity * product.price;
    });

    const prices = {
      totalWithoutTax: +totalPrice.toFixed(2),
      tax: +(totalPrice * 0.25).toFixed(2),
      total: +(totalPrice * 1.25).toFixed(2),
      currency: Currency.CRO,
    };

    dispatch(CartActions.updateProducts(productsToUpdate));
    dispatch(CartActions.setClientSummary(prices));
  };

const addClientInfo =
  (client: ClientInformation) => (dispatch: AppDispatch) => {
    Geocode.setApiKey(process.env.GATSBY_API_KEY || '');

    Geocode.fromAddress(
      client.address +
        ' ' +
        client.postCode +
        ' ' +
        client.city +
        ' ' +
        client.country,
    ).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        client.location = {
          lat,
          lng,
        };

        if (!client.addBusinessDataToProfile) {
          delete client.business;
        } else {
          if (client.business) authService.setBusinessData(client.business);
        }
        delete client.addBusinessDataToProfile;

        dispatch(CartActions.addClientInformation(client));
      },
      (error) => {
        console.error(error);
      },
    );
  };

const sendCartData =
  (
    distrubutorData: DistributorState,
    distributors: Distributor[],
    isClientAnonymous?: boolean,
  ) =>
  async (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const clientService = FirestoreService<ClientRequest>(
      Collection.ClientRequest,
    );

    const { clientInfo, products, clientSummary } = getState().cart;
    const { user } = getState().auth;
    const { getPdfFile } = createPdfRequest();

    const ids = Object.keys(distrubutorData).filter(
      (id: string) =>
        distrubutorData[id] && id !== 'contactConsent' && id != 'subscription',
    );

    const clientRequestId = nanoid(11);

    const distributorsSelected = distributors.filter((item: Distributor) =>
      ids.includes(item.id),
    );

    dispatch(CartActions.setClientRequestId(clientRequestId));

    const pdf = await getPdfFile(clientInfo, products);

    await (
      await funcService
    ).sendRequestNotificationToAdmin(distributorsSelected, clientInfo);

    await (
      await funcService
    ).sendRequestInfoNotificationToDistributor(
      pdf,
      distributorsSelected,
      clientInfo,
    );

    await (
      await funcService
    ).sendConfirmationToUser(
      pdf,
      distributorsSelected,
      user?.email || clientInfo.email,
    );

    const requestPromises = ids.map(async (id) => {
      const newRequest = new ClientRequest({
        distributorMsgLocation: 'inbox',
        clientMsgLocation: 'requested',
        clientRequestId: clientRequestId,
        contactConsent: distrubutorData.contactConsent,
        distributorId: id,
        clientId: authService.getUserUid(),
        productRequests: {
          table: products.map((item) => ({
            product: item.product,
            amount: item.quantity.quantity,
            unit: item.quantity.type,
            deliveryLocation: item.deliveryLocation,
            suggestedPrice: item.price,
          })),
          summary: clientSummary,
        },
        date: Date.now(),
        distributorRead: false,
        isAnswered: false,
        clientInformation: {
          ...clientInfo,
          country: clientInfo?.country.value || '',
          isClientAnon: isClientAnonymous,
        },
      });

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { fileDetails, ...newRequestWithoutFile } = newRequest;
      return clientService?.add({ ...newRequestWithoutFile });
    });

    const results = await Promise.all(requestPromises);
    if (results.some((result) => typeof result === 'string')) {
      dispatch(
        CartActions.addError('Error while sending a request to distributors'),
      );
      return;
    }

    navigate('/kosarica/narudzba-uspjesna');
    dispatch(CartActions.updateProducts([]));
  };

const setClientSummary = (prices: ClientSummary) => (dispatch: AppDispatch) => {
  dispatch(CartActions.setClientSummary(prices));
};

export const CartThunks = {
  addProduct,
  addProducts,
  removeProduct,
  removeAll,
  addQuantity,
  removeQuantity,
  addClientInfo,
  updateDryfix,
  sendCartData,
  setClientSummary,
};
