import { css } from '@emotion/core';
import { colors, fontFamily, fontWeights, mqFrom, typescale } from 'styles';

const title = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  display: flex;
  max-width: 90%;

  ${typescale.medium};

  ${mqFrom.Med} {
    ${typescale.large};
  }

  color: ${colors.dark};
`;

const description = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};

  ${typescale.base};

  color: ${colors.textSecondary}; ;
`;

const accordion = css`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  display: flex;
  flex-direction: row;
`;

const accordionHeader = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const accordionContent = (isOpen: boolean) => css`
  cursor: auto;
  padding-top: 22px;
  overflow: hidden;
  transition: all 0.3s linear;
  max-height: 0;
  display: flex;
  flex-direaction: row;

  ${isOpen && `max-height:500px;`}
`;

const iconWrapper = css`
  cursor: pointer;
  position: relative;
  height: 30px;
  top: 7px;
  padding-right: 32px;
  margin-right: 32px;
`;

const horizontalIcon = css`
  position: absolute;
  background-color: ${colors.primary};
  width: 20px;
  height: 2px;
  top: 9px;
`;

const verticalIcon = (isOpen: boolean) => css`
  position: absolute;
  background-color: ${colors.primary};
  width: 2px;
  top: 0;
  left: 9px;
  height: 20px;

  transition: all 0.3s ease-in-out;
  ${isOpen && `transform: rotate(90deg);`}
`;

const line = css`
  width: 105%;
  height: 1px;
  margin-top: 32px;
  background-color: ${colors.dark};
  opacity: 0.1;
`;

const titleWrapper = css`
  cursor: pointer;
  width: 100%;
`;

export const accordionStyles = {
  accordion,
  accordionHeader,
  iconWrapper,
  horizontalIcon,
  verticalIcon,
  title,
  accordionContent,
  description,
  titleWrapper,
  line,
};
