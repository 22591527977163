import { FormattedMessage } from 'gatsby-plugin-intl';
import { MessageLocation } from 'modules/inbox';
import React from 'react';
import { inbox } from 'styles';

interface Props {
  activeMailCategory: MessageLocation;
  handleMailCategoryChange(event: React.MouseEvent<HTMLButtonElement>): void;
  distributor: boolean;
}

export const MailCategoryTabs: React.FC<Props> = ({
  activeMailCategory,
  handleMailCategoryChange,
  distributor,
}) => (
  <div css={inbox.mailCategories}>
    <button
      type="button"
      data-category="inbox"
      onClick={handleMailCategoryChange}
      css={[
        inbox.categoryBtn,
        activeMailCategory === 'inbox' && inbox.categoryBtnActive,
      ]}
    >
      <FormattedMessage id="inbox.mail-category-tabs.inbox" />
    </button>
    {!distributor && (
      <button
        type="button"
        data-category="requested"
        onClick={handleMailCategoryChange}
        css={[
          inbox.categoryBtn,
          activeMailCategory === 'requested' && inbox.categoryBtnActive,
        ]}
      >
        <FormattedMessage id="inbox.mail-category-tabs.requested" />
      </button>
    )}
    {distributor && (
      <button
        type="button"
        data-category="sent"
        onClick={handleMailCategoryChange}
        css={[
          inbox.categoryBtn,
          activeMailCategory === 'sent' && inbox.categoryBtnActive,
        ]}
      >
        <FormattedMessage id="inbox.mail-category-tabs.sent" />
      </button>
    )}
    <button
      type="button"
      data-category="archive"
      onClick={handleMailCategoryChange}
      css={[
        inbox.categoryBtn,
        activeMailCategory === 'archive' && inbox.categoryBtnActive,
      ]}
    >
      <FormattedMessage id="inbox.mail-category-tabs.archive" />
    </button>
    <button
      type="button"
      data-category="trash"
      onClick={handleMailCategoryChange}
      css={[
        inbox.categoryBtn,
        activeMailCategory === 'trash' && inbox.categoryBtnActive,
      ]}
    >
      <FormattedMessage id="inbox.mail-category-tabs.trash" />
    </button>
  </div>
);
