import { css } from '@emotion/core';
import {
  colors,
  fontFamily,
  fontWeights,
  mqFrom,
  typescale,
  unit,
} from 'styles';

const infoTitle = css`
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightBold};
  ${typescale.large};

  color: ${colors.dark};
`;

const infoSection = css`
  display: flex;
  padding-top: ${unit * 4}px;
  flex-direction: column;

  transition: all 0.5s;

  ${mqFrom.Med} {
    padding-top: ${unit * 8}px;
    flex-direction: row;
  }

  ${mqFrom.Sml} {
    flex-direction: row;
  }
`;

const content = css`
  padding-top: ${unit * 3}px;

  ${mqFrom.Sml} {
    padding-top: 0;
    padding-right: ${unit * 5}px;
  }
`;

const titleWrapper = css`
  display: flex;
  flex-direction: row;
`;

const step = css`
  text-transform: uppercase;

  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightRegular};
  ${typescale.tiny};

  color: ${colors.primary};
  white-space: nowrap;
  padding: 0 ${unit * 2}px;
  padding-bottom: ${unit * 3}px;
`;

const dot = css`
  border: none;
  border-top: 2px dotted ${colors.dark};
  margin-top: 6px;
  color: ${colors.dark};
  height: 1px;
  width: 100%;

  opacity: 0.2;
`;

const subtitle = css`
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightBold};
  ${typescale.medium};

  color: ${colors.dark};

  padding-bottom: ${unit * 4}px;
`;

const text = css`
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightRegular};
  ${typescale.small};

  color: ${colors.textSecondary};
`;

const closeWrapper = css`
  display: flex;
  justify-content: space-between;
`;

export const copyStyles = {
  infoTitle,
  infoSection,
  content,
  titleWrapper,
  step,
  dot,
  closeWrapper,
  subtitle,
  text,
};
