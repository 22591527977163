import { useEffect, useState } from 'react';

export function usePagination<T>(
  data: T[],
  itemsPerPage: number,
  pageNumber?: number,
) {
  const maxPage = Math.ceil(data.length / itemsPerPage);
  const currentPageNumber =
    pageNumber && pageNumber >= maxPage ? maxPage : pageNumber;
  const [currentPage, setCurrentPage] = useState(Number(currentPageNumber));
  const [currentData, setCurrentData] = useState(data);

  useEffect(() => {
    setCurrentPage(Number(currentPageNumber));
  }, [currentPageNumber]);

  useEffect(() => {
    const startingIndex =
      currentPageNumber && currentPageNumber - 1 > 0
        ? currentPageNumber - 1
        : currentPage - 1 > 0
        ? currentPage - 1
        : 0;
    const begin = startingIndex * itemsPerPage;
    const end = begin + itemsPerPage;

    setCurrentData(data.slice(begin, end));

    if (!currentPage) {
      setCurrentPage(1);
    }
  }, [currentPage, data, itemsPerPage, currentPageNumber]);

  function next() {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  }

  function jump(page: number) {
    const pageNumber = Math.max(1, page);
    setCurrentPage(() => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, currentData, currentPage, maxPage };
}
