import { graphql, useStaticQuery } from 'gatsby';

export const useWallsCatAndModels = () => {
  const { allWallCategoriesWithModelsJson } = useStaticQuery(graphql`
    query allWallCategoriesWithModelsJson {
      allWallCategoriesWithModelsJson {
        nodes {
          category
          models
        }
      }
    }
  `);

  const { nodes } = allWallCategoriesWithModelsJson;

  const sortedAllWallCategoriesWithModels = [
    nodes[2],
    nodes[0],
    nodes[4],
    nodes[3],
    nodes[1],
  ];

  return { sortedAllWallCategoriesWithModels, allWallCategoriesWithModelsJson };
};
