import { SerializedStyles } from '@emotion/utils';
import { BackArrow } from 'assets';
import { FormattedMessage } from 'gatsby-plugin-intl';
import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { buttons, modal, spacers } from 'styles';
import { disableBodyScroll } from 'utils';

interface Props {
  isShowing: boolean;
  hide: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  onConfirm: (id?: string) => void;
  onBackClick?: VoidFunction;
  showButtons?: boolean;
  containerStyles?: SerializedStyles;
  title?: string;
}

export const Modal: React.FC<Props> = ({
  isShowing,
  hide,
  onConfirm,
  children,
  title,
  onBackClick,
  containerStyles,
  showButtons = true,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleConfirm = useCallback(() => {
    onConfirm();
    hide();
  }, [hide, onConfirm]);

  const onBlur = useCallback(
    (event: MouseEvent) => {
      if (event.target === modalRef.current) {
        hide();
      }
    },
    [hide],
  );

  const onEscOrEnter = useCallback(
    (event: KeyboardEvent) => {
      if (isShowing) {
        switch (event.keyCode) {
          case 27:
            if (isShowing) {
              hide();
            }
            break;
          case 13:
            if (isShowing) {
              onConfirm();
              hide();
            }
            break;
          default:
            break;
        }
      }
    },
    [hide, isShowing, onConfirm],
  );

  useEffect(() => {
    disableBodyScroll(isShowing);
    window.addEventListener('keydown', onEscOrEnter, false);
    window.addEventListener('mousedown', onBlur, false);

    return () => {
      window.removeEventListener('keydown', onEscOrEnter, false);
      window.removeEventListener('mousedown', onBlur, false);
    };
  }, [onBlur, onEscOrEnter, isShowing]);

  if (!isShowing) return null;

  return ReactDOM.createPortal(
    <Fragment>
      <div css={modal.overlay} />
      <div
        css={modal.wrapper}
        aria-modal
        aria-hidden
        tabIndex={-1}
        role="dialog"
        ref={modalRef}
      >
        <div css={containerStyles || modal.container}>
          <div>
            <div
              css={[modal.header, (title || onBackClick) && modal.spaceBetween]}
            >
              <div css={modal.titleWrapper}>
                {onBackClick && (
                  <div onClick={onBackClick} css={modal.backWrapper}>
                    <BackArrow />
                  </div>
                )}
                {title && <h2 css={modal.title}>{title}</h2>}
              </div>
              <button
                type="button"
                css={modal.close}
                data-dismiss="modal"
                aria-label="Close"
                onClick={hide}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>

          <div css={modal.content}>{children}</div>

          {showButtons && (
            <div css={modal.footer}>
              <button
                type="button"
                css={[buttons.primary.medium, spacers.right.large]}
                onClick={handleConfirm}
              >
                <FormattedMessage id="shared.accept" />
              </button>

              <button
                type="button"
                css={[buttons.container, modal.declineButton]}
                onClick={hide}
              >
                <FormattedMessage id="shared.back" />
              </button>
            </div>
          )}
        </div>
      </div>
    </Fragment>,
    document.body,
  );
};
