import { ChevronDown } from 'assets';
import { ControlledCheckbox } from 'components';
import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { listing } from 'styles';
import { RoofListingState } from 'template/AllRoofListing';

interface Props {
  categories: string[];
  models: string[];
  colors: string[];
  filterState: RoofListingState;
  handleChange: (name: string, value: boolean, section?: string) => void;
}

export const RoofFilters: React.FC<Props> = ({
  categories,
  models,
  colors,
  filterState,
  handleChange,
}) => {
  return (
    <>
      <p css={listing.filterTitle}>
        <FormattedMessage id="products.category.roof-categories" />
      </p>
      <ul css={listing.accordionItem}>
        {categories.map((category) => (
          <li key={category}>
            <ControlledCheckbox
              name={category}
              text={category}
              section="categories"
              checked={filterState.categories[category]}
              onChange={handleChange}
            />
          </li>
        ))}
      </ul>

      <p css={listing.filterTitle}>
        <FormattedMessage id="products.category.models" />
      </p>
      <ul css={listing.accordionItem}>
        {models.map((model) => (
          <li key={model}>
            <ControlledCheckbox
              name={model}
              text={model}
              section="models"
              checked={filterState.models[model]}
              onChange={handleChange}
            />
          </li>
        ))}
      </ul>

      {colors && (
        <>
          <Accordion allowMultipleExpanded allowZeroExpanded>
            <AccordionItem css={listing.accordionItem}>
              <AccordionItemHeading>
                <AccordionItemButton css={listing.accordionTitle}>
                  <p>
                    <FormattedMessage id="products.category.pieces" />
                  </p>
                  <ChevronDown />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <ControlledCheckbox
                  name="10"
                  text="10+"
                  section="piecesPerSqMeter"
                  onChange={handleChange}
                  checked={filterState.piecesPerSqMeter['10']}
                />
                <ControlledCheckbox
                  name="20"
                  text="20+"
                  section="piecesPerSqMeter"
                  onChange={handleChange}
                  checked={filterState.piecesPerSqMeter['20']}
                />
                <ControlledCheckbox
                  name="30"
                  text="30+"
                  section="piecesPerSqMeter"
                  onChange={handleChange}
                  checked={filterState.piecesPerSqMeter['30']}
                />
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem css={listing.accordionItem}>
              <AccordionItemHeading>
                <AccordionItemButton css={listing.accordionTitle}>
                  <p>
                    <FormattedMessage id="products.category.paint" />
                  </p>
                  <ChevronDown />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {colors.map((color) => (
                  <ControlledCheckbox
                    key={color}
                    name={color}
                    text={color}
                    section="colors"
                    checked={filterState.colors[color]}
                    onChange={handleChange}
                  />
                ))}
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem css={listing.accordionItem}>
              <AccordionItemHeading>
                <AccordionItemButton css={listing.accordionTitle}>
                  <p>
                    <FormattedMessage id="products.category.slope" />
                  </p>
                  <ChevronDown />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <ControlledCheckbox
                  name="group1"
                  text="do 15°"
                  section="minRoofPitch"
                  onChange={handleChange}
                  checked={filterState.minRoofPitch['group1']}
                />
                <ControlledCheckbox
                  name="group2"
                  text="od 15° do 20°"
                  section="minRoofPitch"
                  onChange={handleChange}
                  checked={filterState.minRoofPitch['group2']}
                />
                <ControlledCheckbox
                  name="group3"
                  text="od 20° do 30°"
                  section="minRoofPitch"
                  onChange={handleChange}
                  checked={filterState.minRoofPitch['group3']}
                />
                <ControlledCheckbox
                  name="group4"
                  text="preko 30°"
                  section="minRoofPitch"
                  onChange={handleChange}
                  checked={filterState.minRoofPitch['group4']}
                />
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </>
      )}
    </>
  );
};
