enum types {
  AuthStateChange = 'auth/AUTH_STATE_CHANGE',
  LoginSuccess = 'auth/LOGIN_SUCCESS',
  LoginError = 'auth/LOGIN_ERROR',
  Logout = 'auth/LOGOUT_USER',
}

export const AuthTypes = {
  ...types,
};
