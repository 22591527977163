import { css, SerializedStyles } from '@emotion/core';

import { color } from './mixins.styles';
import { colors, fontFamily, globalRadial, unit } from './vars.styles';

/**
 * Button base
 */
const buttonBase = css`
  display: inline-flex;
  align-items: center;
  position: relative;

  touch-action: manipulation;
  user-select: none;
  font-size: 14px;

  /**
  * Remove border radius on iOS buttons
  */
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-border-radius: 0;

  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  text-align: center;
  background: transparent;
  border: none;
  border-radius: ${globalRadial}px;
  font-family: ${fontFamily.sans};

  transition: opacity 0.15s ease-out, background 0.15s ease-out,
    box-shadow 0.15s ease-out;

  &:focus {
    outline: 0;
  }

  /**
  * Disabled
  */

  &.is-disabled,
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
    background-color: transparent;
  }

  &.is-disabled {
    pointer-events: none;
  }
`;

const primary = css`
  ${buttonBase}

  background-color: ${colors.primary};
  color: white;

  &:hover,
  &:focus {
    box-shadow: 0px 0px 0px 5px ${color(colors.primary, 0.25)};
  }

  &:active {
    box-shadow: 0px 0px 0px 5px ${color(colors.primary, 0.4)};
  }

  &:disabled,
  &.is-disabled {
    background: ${colors.primary};
    opacity: 0.3;
    box-shadow: none;
  }
`;

const secondary = css`
  ${buttonBase}

  background-color: ${colors.secondary};
  color: #000000;

  &:hover,
  &:focus {
    box-shadow: 0px 0px 0px 5px ${color(colors.secondary, 0.5)};
  }

  &:active {
    box-shadow: 0px 0px 0px 5px ${color(colors.secondary, 0.7)};
  }

  &:disabled,
  &.is-disabled {
    opacity: 0.3;
    box-shadow: none;
  }
`;

const teritary = css`
  ${buttonBase}

  background-color: transparent;
  color: #000000;

  &:hover,
  &:focus {
    box-shadow: 0px 0px 0px 5px ${color(colors.primary, 0.5)};
  }

  &:active {
    box-shadow: 0px 0px 0px 5px ${color(colors.primary, 0.7)};
  }

  &:disabled,
  &.is-disabled {
    opacity: 0.3;
    box-shadow: none;
  }
`;

const ghost = css`
  ${buttonBase}

  background: transparent;
  color: ${colors.primary};
  border: 1px solid ${colors.primary};

  &:hover,
  &:focus {
    box-shadow: 0px 0px 0px 5px ${color(colors.primary, 0.25)};
  }

  &:active {
    box-shadow: 0px 0px 0px 5px ${color(colors.primary, 0.4)};
  }

  &:disabled,
  &.is-disabled {
    opacity: 0.3;
    box-shadow: none;
  }
`;

const container = css`
  ${buttonBase}

  background: transparent;
  border: none;
`;

const hidden = css`
  visibility: hidden;
`;

const disabled = css`
  opacity: 0.3;
  box-shadow: none;
  pointer-events: none;
`;
/**
 * Button sizes
 */
const xsmall = css`
  padding: 0 ${unit * 5}px;
  line-height: 32px;
`;

const small = css`
  padding: 0 ${unit * 8}px;
  line-height: 40px;
`;

const medium = css`
  padding: 0 ${unit * 12}px;
  line-height: 48px;
`;

const large = css`
  padding: 0 ${unit * 15}px;
  line-height: 60px;
`;

/**
 * Size helper
 */
const createSizeMap = (style: SerializedStyles) => ({
  xsmall: css(style, xsmall),
  small: css(style, small),
  medium: css(style, medium),
  large: css(style, large),
});

/**
 * Export all available button styles
 */
export const buttons = {
  primary: createSizeMap(primary),
  secondary: createSizeMap(secondary),
  teritary: createSizeMap(teritary),
  ghost: createSizeMap(ghost),
  container,
  buttonBase,
  hidden,
  disabled,
};
