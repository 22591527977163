import { useLocation } from '@reach/router';
import { Alert } from 'components';
import { Link } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import { AppDispatch, ApplicationState } from 'modules/redux-store';
import { SearchInput, SearchThunks } from 'modules/search';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { headerStyles, layout, spacers } from 'styles';
import { isFullPageRoute } from 'utils';

import { FacadeSubnav } from './FacadeSubnav';
import { HeaderManagement } from './HeaderManagement';
import { MobileNavigation } from './mobile';
import { Navigation } from './Navigation';
import { RoofSubnav } from './RoofSubnav';
import { RoofWindowsSubnav } from './RoofWindowSubnav';
import { WallsSubnav } from './WallsSubnav';

const INITIAL_STATE = {
  walls: false,
  roof: false,
  facade: false,
  roofWindows: false,
};

export interface SubNavState {
  walls: boolean;
  roof: boolean;
  facade: boolean;
  roofWindows: boolean;
}

export interface ManagementState {
  profileOpen: boolean;
  cartOpen: boolean;
}

const INITIAL_STATE_MANAGEMENT: ManagementState = {
  profileOpen: false,
  cartOpen: false,
};

export const Header: React.FC = () => {
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();

  const [subnavState, setSubnavState] = useState<SubNavState>(INITIAL_STATE);
  const [state, setState] = useState<ManagementState>(INITIAL_STATE_MANAGEMENT);

  const { isActive } = useSelector((state: ApplicationState) => state.search);

  const { user } = useSelector((state: ApplicationState) => state.auth);
  let timeout: NodeJS.Timeout;

  useEffect(() => {
    setState(INITIAL_STATE_MANAGEMENT);
  }, [pathname]);

  const unClickable = isActive && headerStyles.unClickableHeader;

  const navStyle =
    (state.cartOpen || state.profileOpen) && headerStyles.onModalOpen;

  return (
    <section
      css={[
        isFullPageRoute(pathname) ? headerStyles.headerNone : false,
        headerStyles.navHeight,
      ]}
    >
      <header
        css={
          isFullPageRoute(pathname)
            ? headerStyles.headerNone
            : headerStyles.root
        }
        onMouseLeave={handleSubnavClose}
      >
        <WallsSubnav
          visible={subnavState.walls}
          handleClose={handleSubnavClose}
        />
        <RoofSubnav
          visible={subnavState.roof}
          handleClose={handleSubnavClose}
        />
        <FacadeSubnav
          visible={subnavState.facade}
          handleClose={handleSubnavClose}
        />
        <RoofWindowsSubnav
          visible={subnavState.roofWindows}
          handleClose={handleSubnavClose}
        />

        {(state.cartOpen || state.profileOpen) && (
          <div css={layout.overlay} onClick={closeHeaderManagement} />
        )}

        <div css={headerStyles.scene}>
          <div css={headerStyles.headerWrapper(isActive)}>
            <div css={[headerStyles.headerFront(!isActive), unClickable]}>
              <Link to="/">
                <img
                  css={headerStyles.logo}
                  src={require('content/Wienerberger-new-logo.png')}
                  alt="Wienerberger"
                />
              </Link>

              <div css={[headerStyles.visibleHeader, navStyle]}>
                <Navigation
                  handleSubnavOpen={handleSubnavOpen}
                  subnavState={subnavState}
                  pathname={pathname}
                />
              </div>

              <HeaderManagement
                onEnter={handleSubnavClose}
                onSearchClick={onSearchClick}
                state={state}
                handleOpen={handleOpenManagementModals}
              />
            </div>

            <div css={headerStyles.headerTop(isActive)}>
              <SearchInput onClose={onCloseSearch} />
            </div>
          </div>
        </div>
        <MobileNavigation isActive={isActive} />
      </header>

      {user && !user.emailVerified && (
        <div css={[layout.wrapper, spacers.top.extraHuge]}>
          <Alert
            type="warning"
            text={formatMessage({ id: 'navigation.header.warning' })}
          />
        </div>
      )}
    </section>
  );

  function onSearchClick() {
    setState(INITIAL_STATE_MANAGEMENT);
    dispatch(SearchThunks.setIsActive(true));
  }

  function handleSubnavOpen(event: React.PointerEvent<HTMLAnchorElement>) {
    const { id } = event.currentTarget.dataset;
    if (!id) return;
    timeout = setTimeout(() => {
      setSubnavState({
        ...INITIAL_STATE,
        [id]: true,
      });
    }, 200);
  }

  function handleSubnavClose() {
    clearTimeout(timeout);
    setSubnavState({
      ...INITIAL_STATE,
    });
  }

  function onCloseSearch() {
    dispatch(SearchThunks.setIsActive(false));
  }

  function handleOpenManagementModals(
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) {
    const { id } = event.currentTarget.dataset;
    if (!id) return;

    setState({
      ...INITIAL_STATE_MANAGEMENT,
      [id]: !state[id],
    });
  }

  function closeHeaderManagement() {
    setState(INITIAL_STATE_MANAGEMENT);
  }
};
