import { Product } from 'models';

export function filterByConcept(
  selectedValues: Record<string, boolean>,
  initialList: Product[],
) {
  const checkedValues = Object.entries(selectedValues).reduce(
    (acc: string[], [key, value]) => {
      if (value) return [...acc, key];
      return acc;
    },
    [],
  );

  const filteredConceptProducts = checkedValues.length
    ? initialList.filter((product) =>
        product.commercial.concept
          ? checkedValues.includes(product.commercial.concept.toString())
          : false,
      )
    : initialList;

  return filteredConceptProducts;
}
