import { ArrowRight } from 'assets';
import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';
import { navStyles } from 'styles';

interface Props {
  onItemClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

export const RootNavigation: React.FC<Props> = ({ onItemClick }) => {
  return (
    <ul css={navStyles.list}>
      <li data-id="walls" onClick={onItemClick}>
        <div>
          <strong css={navStyles.title}>
            <FormattedMessage id="navigation.wall" />
          </strong>
        </div>
        <ArrowRight />
      </li>
      <li data-id="roof" onClick={onItemClick}>
        <div>
          <strong css={navStyles.title}>
            <FormattedMessage id="navigation.roof" />
          </strong>
        </div>
        <ArrowRight />
      </li>
      <li data-id="facade" onClick={onItemClick}>
        <div>
          <strong css={navStyles.title}>
            <FormattedMessage id="navigation.facade" />
          </strong>
        </div>
        <ArrowRight />
      </li>
      <li data-id="roofWindows" onClick={onItemClick}>
        <div>
          <strong css={navStyles.title}>
            <FormattedMessage id="navigation.roof-windows" />
          </strong>
        </div>
        <ArrowRight />
      </li>
    </ul>
  );
};
