import { Link } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';
import { layout, spacers } from 'styles';
import { slugify } from 'utils';

import { useRoofCatAndModels } from '../hooks';

interface Props {
  visible: boolean;
  handleClose: VoidFunction;
}

export const RoofSubnav: React.FC<Props> = ({ visible, handleClose }) => {
  const { allRoofCategoriesWithModelsJson } = useRoofCatAndModels();

  return (
    <ul css={[layout.subnav, visible ? layout.subnavVisible : null]}>
      <div css={layout.subnavCol}>
        <Link
          to="/krov/pregled-svih-proizvoda"
          css={[layout.model, spacers.top.extraHuge]}
          onClick={handleClose}
        >
          <FormattedMessage id="navigation.all-items" />
        </Link>

        <Link
          to="/krovni-dodaci/pregled-svih-proizvoda"
          css={layout.model}
          onClick={handleClose}
        >
          <FormattedMessage id="navigation.roof-addons" />
        </Link>
      </div>

      {allRoofCategoriesWithModelsJson.nodes.map(
        (roof: { category: string; models: string[] }) => (
          <div key={roof.category} css={layout.subnavCol}>
            <Link
              to={`/krov/${slugify(roof.category)}`}
              css={[layout.category, spacers.bottom.huge]}
              onClick={handleClose}
            >
              {roof.category}
            </Link>

            {roof.models.map((model) => (
              <Link
                to={`/krov/${slugify(roof.category)}/${slugify(model)}`}
                css={layout.model}
                key={model}
                onClick={handleClose}
              >
                {model}
              </Link>
            ))}
          </div>
        ),
      )}
    </ul>
  );
};
