export const orderPanels = [
  {
    id: 0,
    title: 'Kako naručiti, zatražiti ponudu?',
    description:
      'Preko Wienerberger web shopa možete zatražiti ponudu od naših distributera, na način da stavite u košaricu proizvode ili putem našeg kalkulatora unesite mjere. Kalkulator će po vašim mjerama ubaciti u košaricu sve što je izračunato. Nakon što ste dodali sve proizvode u košaricu, unesite svoje podatke i na sljedećem koraku možete izabrati od kojih distributera želite zaprimiti ponudu.',
  },
  {
    id: 1,
    title: 'Mogu li naručiti direktno od vas?',
    description:
      'Ne, možete naručiti preko ovog web shopa od naših distributera.',
  },
  {
    id: 2,
    title: 'Kako vršite dostavu?',
    description:
      'Dostavu ne vršimo mi, već distributer od kojeg prihvatite ponudu.',
  },
  {
    id: 3,
    title: 'Zašto moram naručiti preko distributera?',
    description:
      'Mi nemamo svoju maloprodaju, samo veleprodaju i prodajemo preko distributera.',
  },
  {
    id: 4,
    title:
      'Šta mogu napraviti ako sam poslao/la upit distributerima, ali nisam dobio/la ponudu?',
    description: `Možete nas kontaktirati na mail:  <a href="mailto:marketing.hr@wienerberger.com">marketing.hr@wienerberger.com</a>, pa ćemo u suradnji sa našim voditeljima prodajnih područja kontaktirati distributere.`,
  },
];
