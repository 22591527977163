import { css } from '@emotion/core';

import { buttons } from './button.styles';
import { color } from './mixins.styles';
import { mqFrom } from './responsive.styles';
import { typescale } from './typescale.styles';
import {
  colors,
  fontFamily,
  fontSize,
  fontWeights,
  lineHeight,
  unit,
} from './vars.styles';

const loginFlex = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  overflow-y: auto;
  height: 100vh;

  section {
    width: 100%;
  }
`;

const logo = css`
  width: 100px;
  height: 48px;
  align-self: flex-start;
  margin: 0 auto;
  image-rendering: -webkit-optimize-contrast;

  & > img {
    width: 100%;
  }

  ${mqFrom.XSml} {
    width: 126px;
    height: 64px;
    align-self: center;
  }

  ${mqFrom.Lrg} {
    margin-top: 60px;
  }
`;

const form = css`
  width: 100%;
  padding: 0 20px;
  ${mqFrom.Lrg} {
    max-width: 420px;
    padding: 0 20px;
  }
`;

const formTitle = css`
  ${typescale.small}
  font-weight: ${fontWeights.weightRegular};

  & > h2 {
    ${typescale.large}
    margin-bottom: 12px;
  }

  & > p {
    margin-bottom: 36px;
  }
`;

const cartWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;

  padding: ${unit * 4}px 0;

  ${mqFrom.Lrg} {
    flex-direction: row;
    & > * + * {
      margin-left: 80px;
    }
  }
`;

const underline = css`
  text-transform: underline;
`;

const dividerHide = css`
  display: none;
`;

const cartDivider = css`
  ${dividerHide};
  ${mqFrom.Lrg} {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    span {
      width: 1px;
      height: 120px;
      margin: 0 ${unit * 13}px;
      height: 220px;
      background: ${colors.dark};
      opacity: 0.1;
    }
  }
`;

const dividerWrapper = css`
  ${mqFrom.Lrg} {
    ${dividerHide};
  }
`;

const title = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-style: normal;

  font-size: ${fontSize.xlarge}px;
  line-height: ${lineHeight.xlarge}px;

  color: ${colors.textSecondary};
`;

const baseSize = css`
  font-size: ${fontSize.tiny}px;
  line-height: ${lineHeight.tiny}px;
`;

const largeSize = css`
  font-size: ${fontSize.small}px;
  line-height: ${lineHeight.small}px;

  padding-top: ${unit * 2}px;
  padding-bottom: ${unit * 8}px;
`;

const baseText = css`
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightRegular};

  color: ${colors.textSecondary};
  opacity: 0.8;
`;

const dividerPadding = css`
  padding: ${unit * 8}px ${unit * 5}px;
  ${mqFrom.Med} {
    padding: ${unit * 12}px 0;
  }
`;

const registerBtn = css`
  ${buttons.primary.medium}
  margin-top: 16px;
  margin-bottom: 26px;
`;

const consent = css`
  display: flex;
  font-family: ${fontFamily.sans};
  ${typescale.tiny}
`;

const smallConsent = css`
  ${typescale.xtiny};
  font-family: ${fontFamily.sans};
`;

const copy = css`
  ${typescale.tiny}
  opacity: 0.6;
  align-self: flex-start;
  margin-left: 20px;

  ${mqFrom.Sml} {
    align-self: center;
    margin: 0;
  }
`;

const socialWrapper = css`
  width: 100%;
  ${buttons.container}
  background: ${colors.backgroundGray};
  border-radius: 4px;
  height: 48px;
  ${typescale.small}
  color: ${colors.dark};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;

  & > p {
    margin-left: 12px;
  }
`;

const orDivider = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  margin: 24px 0;

  & > span {
    width: 64px;
    text-align: center;
    ${typescale.small}
    color: ${color(colors.textSecondary, 0.5)};
  }

  & > div {
    flex: 1;
    height: 1px;
    background: ${colors.dark};
    opacity: 0.1;
  }
`;

const text = {
  base: css(baseText, baseSize),
  large: css(baseText, largeSize),
};

const anonymousWrapper = css`
  ${form};
`;

export const auth = {
  loginFlex,
  logo,
  form,
  formTitle,
  cartWrapper,
  cartDivider,
  title,
  text,
  dividerPadding,
  underline,
  registerBtn,
  consent,
  copy,
  smallConsent,
  socialWrapper,
  orDivider,
  anonymousWrapper,
  dividerWrapper,
};
