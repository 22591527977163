import { useLocation } from '@reach/router';
import { Loading } from 'components';
import { AppDispatch, ApplicationState } from 'modules/redux-store';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isFullPageRoute } from 'utils';

import { AuthThunks } from '../redux';

export const AccessControlList: React.FC = ({ children }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { authStateChanging } = useSelector(
    (state: ApplicationState) => state.auth,
  );
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(AuthThunks.loginWithPersistedUser());
  }, [dispatch]);

  return (
    <Loading
      fullPage
      isLoading={authStateChanging && !isFullPageRoute(pathname)}
    >
      {children}
    </Loading>
  );
};
