import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { MapStyles } from 'consts/MapStyles';
import { Location } from 'modules/map';
import React, { useRef } from 'react';

interface Props {
  location: Location;
}

const containerStyle = {
  width: '100%',
  height: '50vh',
};

export const LocationModal: React.FC<Props> = ({ location }) => {
  const googleMap = useRef<google.maps.Map>();

  return (
    <LoadScript googleMapsApiKey={process.env.GATSBY_API_KEY}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={location}
        zoom={8.6}
        onLoad={handleOnLoad}
        options={{
          styles: MapStyles,
        }}
      >
        {location && (
          <Marker
            position={{
              lat: location.lat,
              lng: location.lng,
            }}
            draggable
          />
        )}
      </GoogleMap>
    </LoadScript>
  );

  function handleOnLoad(map: google.maps.Map) {
    googleMap.current = map;
  }
};
