import React from 'react';

export const CartSuccessIcon: React.FC = () => (
  <svg
    width="50"
    height="60"
    viewBox="0 0 50 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M49 33C49 51 25 59 25 59C25 59 1 51 1 33V7L25 1L49 7V33Z"
      stroke="#B30000"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 29L22 37L38 18.333"
      stroke="#B30000"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
