import { SerializedStyles } from '@emotion/core';
import React from 'react';
import { product } from 'styles';

interface Props {
  text: string;
  className?: SerializedStyles;
  active: boolean;
}

export const Tooltip: React.FC<Props> = ({ text, className, active }) => {
  return <div css={[className, active && product.tooltipActive]}>{text}</div>;
};
