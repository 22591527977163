import { ActionUnion, createAction } from 'modules/redux-store';

import { SearchTypes } from './types';

export const SearchActions = {
  setIsActive: (value: boolean) =>
    createAction(SearchTypes.SetIsActive, { value }),
  setSearchInput: (value: string) =>
    createAction(SearchTypes.SetSearchInput, { value }),
};

export type SearchActions = ActionUnion<typeof SearchActions>;
