import { graphql, useStaticQuery } from 'gatsby';

export const useGetRoofColorImages = () => {
  const roofColorImages = graphql`
    query colorImages {
      colorPlus: file(relativePath: { eq: "colors/ColorPlus.png" }) {
        image: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      natur: file(relativePath: { eq: "colors/Natur.png" }) {
        image: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      naturColor: file(relativePath: { eq: "colors/NaturColor.png" }) {
        image: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `;

  const colorPlus = useStaticQuery(roofColorImages).colorPlus.image;
  const natur = useStaticQuery(roofColorImages).natur.image;
  const naturColor = useStaticQuery(roofColorImages).naturColor.image;

  return {
    colorPlus,
    natur,
    naturColor,
  };
};
