import { SerializedStyles } from '@emotion/core';
import React from 'react';
import { alert } from 'styles';

interface Props {
  type: 'success' | 'error' | 'info' | 'warning';
  text: string;
  className?: SerializedStyles;
}

export const Alert: React.FC<Props> = ({ type, text, className }) => {
  return (
    <div css={[alert[type], className]}>
      <p>{text}</p>
    </div>
  );
};
