import { css } from '@emotion/core';

import { buttons } from './button.styles';
import { mqFrom } from './responsive.styles';
import { typescale } from './typescale.styles';
import { colors, fontFamily, fontWeights } from './vars.styles';

const container = css`
  display: flex;
  padding: 20px;

  ${mqFrom.Med} {
    padding: 40px;
  }

  ${mqFrom.Lrg} {
    padding: 60px;
  }

  ${mqFrom.Xlrg} {
    padding: 80px;
  }

  ${mqFrom.Xxlrg} {
    padding: 100px;
  }
`;

const content = css`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 40px;
  row-gap: 32px;
  width: 100%;

  ${mqFrom.Med} {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 82px;
  }
`;

const title = css`
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightBold};
  ${typescale.xlarge};

  ${mqFrom.Med} {
    ${typescale.xxlarge};
  }

  ${mqFrom.Xlrg} {
    ${typescale.xxxlarge};
  }

  color: ${colors.dark};
`;

const subtitle = css`
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightBold};

  ${typescale.large};

  color: ${colors.dark};
`;

const navigationWrapper = css`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: end;
`;

const navigationButton = css`
  ${buttons.container};
  position: relative;
  margin-right: 32px;
  padding: 8px 0px;
  cursor: pointer;

  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightRegular};

  ${typescale.base};

  color: ${colors.dark};

  &:after {
    content: '';
    position: absolute;
    display: block;
    bottom: -1px;
    left: 0;
    width: 0;
    height: 2px;
    background: ${colors.textSecondary};
    opacity: 0.6;
    transition: width 0.3s;
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }
`;

const navigationButtonActive = css`
  color: ${colors.primary};

  &:after {
    width: 100%;
    background: ${colors.primary};
  }
`;

export const faqStyles = {
  container,
  content,
  subtitle,
  title,
  navigationWrapper,
  navigationButton,
  navigationButtonActive,
};
