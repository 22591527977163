import 'firebase/storage';

import { Collection } from 'consts';

import { FirebaseService } from './FirebaseService';

export function StorageService(collection: Collection) {
  const storage = FirebaseService.Instance()?.instance.storage();

  const addFileAndGetDocUrl = async (docId: string, file: File) => {
    const storageRef = storage?.ref(collection);

    const uploadTask = storageRef?.child(docId).child(file.name).put(file);

    if (!uploadTask) return;

    const urlPromise = new Promise(
      (
        resolve: (value: string) => void,
        reject: (reason?: unknown) => void,
      ) => {
        uploadTask.on(
          'state_changed',
          () => undefined,
          () => {
            reject();
          },
          () => {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then((downloadURL: string) => {
                resolve(downloadURL);
              });
          },
        );
      },
    );

    const downloadUrl = await urlPromise;

    return downloadUrl;
  };

  const deleteFile = (fileUrl: string) => {
    const httpReference = storage?.refFromURL(fileUrl);

    httpReference?.delete();
  };

  return {
    addFileAndGetDocUrl,
    deleteFile,
  };
}
