import { Distributor } from '../models';
import { DistributorActions } from './actions';
import { DistributorTypes } from './types';

export interface DistributorState {
  activeDistributors: Distributor[];
  isLoading: boolean;
  error?: string;
}

const INITIAL_STATE: DistributorState = {
  activeDistributors: [],
  isLoading: true,
  error: undefined,
};

export default (
  state: DistributorState = INITIAL_STATE,
  action: DistributorActions,
) => {
  switch (action.type) {
    case DistributorTypes.addLoading:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };

    case DistributorTypes.addError:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case DistributorTypes.addActiveDistributors:
      return {
        ...state,
        activeDistributors: action.payload.activeDistributors,
      };

    default:
      return state || INITIAL_STATE;
  }
};
