import { SerializedStyles } from '@emotion/core';
import React from 'react';

interface Props {
  fill?: string;
  stroke?: string;
  width?: string;
  height?: string;
  opacity?: string;
  className?: SerializedStyles;
}

export const ChevronRight: React.FC<Props> = ({
  fill,
  stroke,
  opacity,
  width,
  height,
  className,
}) => (
  <svg
    css={className}
    width={width || '6'}
    height={height || '10'}
    viewBox="0 0 6 10"
    fill={fill || 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={opacity || '1'}
      d="M1 1L5 5L1 9"
      stroke={stroke || '#3C3737'}
    />
  </svg>
);
