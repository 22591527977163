import React from 'react';

export const MapIcon: React.FC = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.75 14.7L5.5 13L0.5 14.5V2.5L5.5 1L10.5 3L15.5 1.5V7"
      stroke="#B30000"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M12.5 11.75C13.0523 11.75 13.5 11.3023 13.5 10.75C13.5 10.1977 13.0523 9.75 12.5 9.75C11.9477 9.75 11.5 10.1977 11.5 10.75C11.5 11.3023 11.9477 11.75 12.5 11.75Z"
      stroke="#B30000"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M15.5 10.672C15.5 12.475 12.5 15.25 12.5 15.25C12.5 15.25 9.5 12.475 9.5 10.672C9.5 8.823 11.05 7.75 12.5 7.75C13.95 7.75 15.5 8.823 15.5 10.672Z"
      stroke="#B30000"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path d="M5.5 1V13" stroke="#B30000" strokeMiterlimit="10" />
    <path d="M10.5 3V6.5" stroke="#B30000" strokeMiterlimit="10" />
  </svg>
);
