import React from 'react';

export const ToastSuccessIcon: React.FC = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="7" stroke="white" strokeWidth="2" />
    <path
      d="M5.3335 8.31429L6.87635 9.85714L10.7335 6"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
);
