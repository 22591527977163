export function isFixedHeightRoute(pathname: string) {
  switch (pathname) {
    case '/kosarica/odabir-distributera':
    case '/kosarica/odabir-distributera/':
      return true;

    default:
      return false;
  }
}
