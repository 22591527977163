import { css } from '@emotion/core';
import {
  buttons,
  color,
  colors,
  fontFamily,
  fontSize,
  fontWeights,
  layout,
  lineHeight,
  mqFrom,
  typescale,
  unit,
} from 'styles';

const root = css`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 75vh;
  background-color: white;
`;

const wrapper = css`
  ${layout.wrapper};

  padding: ${unit * 4}px 10px;
  margin-top: 89px;

  ${mqFrom.Med} {
    padding: ${unit * 4}px 40px;
  }

  ${mqFrom.Lrg} {
    padding: ${unit * 4}px 80px;
  }
`;

const flex = css`
  display: flex;
  justify-content: flex-start;
  padding-top: 62px;
  padding-bottom: 80px;
`;

const filters = css`
  display: none;

  ${mqFrom.Lrg} {
    display: block;
    width: 290px;
    margin-right: 40px;
  }
`;

const listSection = css`
  flex: 1;
`;

const listWrapper = css`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0;
  transition: all 0.25s;

  ${mqFrom.Med} {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    margin-bottom: 32px;
  }
`;

const listItem = css`
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.25s ease-out;
  height: 238px;

  &:hover {
    box-shadow: 0px 0px 0px 2px rgba(179, 0, 0, 1);
    cursor: pointer;
  }

  & > a {
    height: 100%;
    width: 100%;
    padding: 20px 0 18px 0;

    ${mqFrom.Med} {
      padding: 28px 0 24px 0;
    }

    ${mqFrom.Lrg} {
      padding: 36px 0 30px 0;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  ${mqFrom.XSml} {
    height: auto;
  }
`;

const text = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightBold};
  ${typescale.large}

  color: ${colors.text};

  padding-bottom: ${unit * 4}px;
`;

const filterTitle = css`
  ${typescale.small}

  color: ${colors.textSecondary};
  opacity: 0.7;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: opacity 0.15s ease-out;
  margin-bottom: 20px;
`;

const accordionTitle = css`
  ${filterTitle}

  outline: none;
  cursor: pointer;
`;

const accordionItem = css`
  margin-bottom: 40px;
  text-align: left;
`;

const categoryTitle = css`
  ${typescale.large}
  font-weight: ${fontWeights.weightRegular};
  color: ${colors.dark};
  margin-bottom: 4px;

  ${mqFrom.Lrg} {
    ${typescale.xlarge}
    margin-bottom: 20px;
  }
`;

const categoryAmount = css`
  ${typescale.tiny}
  color: ${color(colors.textSecondary, 0.8)};

  ${mqFrom.Lrg} {
    ${typescale.small}
    margin-bottom: 20px;
  }
`;

const photo = css`
  width: 80%;
  height: 100%;
  transition: width 0.25s;
`;

const productText = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-style: normal;

  transition: all 0.25s;

  font-size: ${fontSize.tiny}px;
  line-height: ${lineHeight.tiny}px;

  text-align: center;
  padding: ${unit}px ${unit}px;

  ${mqFrom.Med} {
    padding: ${unit * 4}px ${unit}px;
    font-size: ${fontSize.small}px;
    line-height: ${lineHeight.small}px;
  }
  ${mqFrom.Lrg} {
    padding: ${unit * 6}px ${unit}px;
    font-size: ${fontSize.base}px;
    line-height: ${lineHeight.base}px;
  }

  color: ${colors.textSecondary};
`;

const mobileHeader = css`
  padding: 0 20px;
  padding-bottom: 16px;

  ${mqFrom.Lrg} {
    display: none;
  }
`;

const mobileFilters = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const filtersIcon = css`
  ${buttons.container}

  display: flex;
  align-items: center;
`;

const filtersMenuWrapper = css`
  padding: 22px 20px;
`;

const filtersMenuHeader = css`
  ${typescale.large}
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
`;

export const listingStyles = {
  root,
  flex,
  filters,
  listSection,
  listWrapper,
  listItem,
  text,
  filterTitle,
  accordionTitle,
  accordionItem,
  categoryTitle,
  categoryAmount,
  photo,
  productText,
  mobileHeader,
  mobileFilters,
  filtersIcon,
  filtersMenuWrapper,
  filtersMenuHeader,
  wrapper,
};
