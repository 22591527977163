import { FiltersIcon, TimesIcon } from 'assets';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { Product } from 'models';
import React, { useEffect, useState } from 'react';
import { buttons, listing, navStyles, spacers } from 'styles';
import { ModelFiltersState } from 'template/ModelPage';
import { disableBodyScroll, handleShowFilterButton } from 'utils';

import { ModelFilters } from './ModelFilters';
import { ProductBrand } from './ProductBrand';

interface Props {
  products: Product[];
  navCategory: 'Roof' | 'Wall' | 'Facade';
  concepts: string[];
  colors: string[];
  dimensions: string[];
  wallWidths: number[];
  consumptions: number[];
  handleChange: (name: string, value: boolean, section?: string) => void;
  clearFilters: VoidFunction;
  filtersState: ModelFiltersState;
}

export const MobileModelPageFilters: React.FC<Props> = ({
  products,
  navCategory,
  concepts,
  colors,
  dimensions,
  wallWidths,
  consumptions,
  handleChange,
  clearFilters,
  filtersState,
}) => {
  const [navState, setNavState] = useState(false);
  disableBodyScroll(navState);

  const [isFilterPicked, setIsFilterPicked] = useState(false);

  useEffect(() => {
    setIsFilterPicked(handleShowFilterButton(filtersState));
  }, [filtersState]);

  const handleClick = () => {
    clearFilters();
    setIsFilterPicked(false);
  };

  const isButtonHidden = isFilterPicked ? '' : buttons.hidden;
  return (
    <>
      <section css={listing.mobileHeader}>
        <div css={listing.mobileFilters}>
          <div>
            <ProductBrand
              brand={products[0].commercial.brand}
              amount={products.length}
            />
          </div>

          <button
            type="button"
            css={listing.filtersIcon}
            onClick={toggleFilters}
          >
            <FiltersIcon className={[spacers.right.small]} />
            <p>
              <FormattedMessage id="products.filter" />
            </p>
          </button>
        </div>

        <div css={navStyles.menu(navState)}>
          <div css={navStyles.menuWrapper(navState)}>
            <div css={navStyles.menuContent(navState)}>
              <div css={listing.filtersMenuHeader}>
                <h4>
                  <FormattedMessage id="products.filter" />
                </h4>
                <button
                  type="button"
                  css={buttons.container}
                  onClick={toggleFilters}
                >
                  <TimesIcon />
                </button>
              </div>

              <div css={isButtonHidden}>
                <button
                  type="button"
                  css={[buttons.ghost.small, spacers.bottom.huge]}
                  onClick={handleClick}
                >
                  <FormattedMessage id="products.clear-filter" />
                </button>
              </div>
              <ModelFilters
                products={products}
                navCategory={navCategory}
                concepts={concepts}
                colors={colors}
                dimensions={dimensions}
                wallWidths={wallWidths}
                consumptions={consumptions}
                handleChange={handleChange}
                filtersState={filtersState}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );

  function toggleFilters() {
    setNavState(!navState);
  }
};
