import { ActionUnion, createAction } from 'modules/redux-store';

import { ClientRequest } from '../models';
import { RequestsTypes } from './types';

const AddActions = {
  addLoading: () => createAction(RequestsTypes.AddLoading),
  addClientRequests: (requests: ClientRequest[]) =>
    createAction(RequestsTypes.AddClientRequests, { requests }),
  addOwnRequests: (requests: ClientRequest[]) =>
    createAction(RequestsTypes.AddOwnRequests, { requests }),
  addError: (error: string) => createAction(RequestsTypes.AddError, { error }),
};

export const RequestsActions = {
  ...AddActions,
};

export type RequestsActions = ActionUnion<typeof RequestsActions>;
