import { SerializedStyles } from '@emotion/core';
import React from 'react';

interface Props {
  className?: SerializedStyles;
  onClick?: VoidFunction;
}

export const PasswordShow: React.FC<Props> = ({ className, onClick }) => (
  <svg
    width="16"
    height="48"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    css={className}
    onClick={onClick}
  >
    <path
      d="M0.5 6C0.5 6 3.5 0.5 8 0.5C12.5 0.5 15.5 6 15.5 6C15.5 6 12.5 11.5 8 11.5C3.5 11.5 0.5 6 0.5 6Z"
      stroke="#3C3737"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 8.5C9.38071 8.5 10.5 7.38071 10.5 6C10.5 4.61929 9.38071 3.5 8 3.5C6.61929 3.5 5.5 4.61929 5.5 6C5.5 7.38071 6.61929 8.5 8 8.5Z"
      stroke="#3C3737"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
