enum types {
  AddLoading = 'requests/ADD_LOADING',
  AddClientRequests = 'requests/ADD_CLIENT_REQUESTS',
  AddOwnRequests = 'requests/ADD_OWN_REQUESTS',
  AddError = 'requests/ADD_ERROR',
}

export const RequestsTypes = {
  ...types,
};
