import React from 'react';
import { select } from 'styles';

export const CountryOptions = [
  {
    value: 'croatia',
    label: (
      <div css={select.row}>
        <img src={require('../content/croatia.png')} alt="croatia" />
        Hrvatska
      </div>
    ),
  },
];

export function getCountryOptions(value: string) {
  return CountryOptions.find((item) => item.value === value);
}
