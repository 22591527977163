import { Link } from 'gatsby';
import Image from 'gatsby-image';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { ClientRequest } from 'modules/inbox';
import React from 'react';
import { inbox } from 'styles';
import { formatPrice, getProductRoute } from 'utils';

interface Props {
  currentRequest: ClientRequest;
}

export const InboxRequestTable: React.FC<Props> = ({
  currentRequest,
  children,
}) => {
  return (
    <div css={[inbox.offerDetailsDesktop, inbox.withLeftPadding]}>
      <table css={inbox.productsTableDesktop}>
        <thead>
          <tr>
            <th></th>
            <th>
              <FormattedMessage id="cart.home-page.product.name" />
            </th>
            <th>
              <FormattedMessage id="cart.home-page.product.amount" />
            </th>
            <th>
              <FormattedMessage id="cart.home-page.product.measure" />
            </th>
            <th>
              <FormattedMessage id="cart.home-page.product.recommended-price" />
            </th>
          </tr>
        </thead>

        <tbody>
          {currentRequest.productRequests?.table.map((request, index) => (
            <tr key={request.product.id || index}>
              <td>
                <Image
                  fixed={{
                    src:
                      request.product.commercial.assets.mainImg ||
                      request.product.commercial.assets.otherImgs[0],
                    srcSet: '',
                    width: 48,
                    height: 48,
                  }}
                />
              </td>
              <td>
                <Link to={getProductRoute(request.product.commercial)}>
                  {request.product.commercial.localizedName}
                </Link>
              </td>
              <td>{request.amount}</td>
              <td>{request.unit}</td>
              <td>{formatPrice(request.suggestedPrice)} €</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div css={inbox.offerSummary}>
        <div css={inbox.summaryRow}>
          <p>
            <FormattedMessage id="cart.home-page.product.price-without-vat" />
          </p>

          <p>
            {formatPrice(
              currentRequest.productRequests?.summary.totalWithoutTax,
            )}{' '}
            €
          </p>
        </div>

        <div css={inbox.summaryRow}>
          <p>PDV</p>
          <p>{formatPrice(currentRequest.productRequests?.summary.tax)} €</p>
        </div>
      </div>

      <div css={inbox.summaryRowTotal}>
        <span>
          <FormattedMessage id="cart.home-page.product.price-with-vat" />
        </span>
        <p>{formatPrice(currentRequest.productRequests?.summary.total)} €</p>
      </div>
      {children}
    </div>
  );
};
