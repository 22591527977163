import { SerializedStyles } from '@emotion/core';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { fields, inputs } from 'styles';

interface Props {
  name: string;
  text: string | JSX.Element;
  defaultChecked?: boolean;
  inputClassName?: SerializedStyles | SerializedStyles[];
  wrapperClassName?: SerializedStyles | SerializedStyles[];
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const Checkbox: React.FC<Props> = ({
  name,
  text,
  defaultChecked,
  inputClassName,
  wrapperClassName,
  onClick,
}) => {
  const { register, setValue, unregister } = useFormContext();

  useEffect(() => {
    register(name);

    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);

  return (
    <div css={wrapperClassName || fields.field} onClick={onClick}>
      <label css={inputClassName || inputs.inputCheckbox}>
        <input
          type="checkbox"
          name={name}
          onChange={handleChange}
          id={name}
          defaultChecked={defaultChecked}
          ref={register}
        />
        <span></span>
        {text}
      </label>
    </div>
  );

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { checked } = event.currentTarget;

    setValue(name, checked);
  }
};
