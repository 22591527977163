import { css } from '@emotion/core';

import { buttons } from './button.styles';
import { color } from './mixins.styles';
import { mqFrom } from './responsive.styles';
import { typescale } from './typescale.styles';
import { colors, globalRadial, unit } from './vars.styles';

const container = css`
  margin-left: 42px;
  position: relative;
  ${mqFrom.Med} {
    max-width: 60%;
  }
`;

const wrapper = css`
  height: 100%;
  max-height: 436px;
  background: ${color(colors.dark, 0.02)};
  padding: ${unit * 5}px;
  width: 90%;

  ${mqFrom.Med} {
    width: 100%;
  }
`;

const options = css`
  display: flex;
  position: absolute;
  top: 32px;
  right: 74px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: ${globalRadial}px;
  ${typescale.tiny}
  flex-direction: column;

  ${mqFrom.Med} {
    flex-direction: row;
    right: 43px;
  }
`;

const optionBtn = css`
  ${buttons.buttonBase}

  background: #F8F8F8;
  color: ${colors.text};
  padding: 8px 24px;
`;

const optionBtnActive = css`
  ${optionBtn}

  background: #FDEBED;
  color: ${colors.primary};
  border: 1px solid #b30000;
`;

const icon = css`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);

  &:hover {
    z-index: 1;
  }
`;

export const map = {
  container,
  wrapper,
  options,
  optionBtn,
  optionBtnActive,
  icon,
};
