import { OfferSummary } from './OfferSummary';
import { ProductOffer } from './ProductOffer';

export interface InternalOfferModel {
  productOffers: ProductOffer[];
  palete?: {
    amount: number;
    rebate: number;
    price: number;
  };
  shippingPrice: number;
  summary?: OfferSummary;
  distributorRemark: string;
  date: number;
}

export class InternalOfferModel {
  constructor(object: Partial<InternalOfferModel>) {
    this.productOffers = object.productOffers || [];
    this.palete = object.palete;
    this.shippingPrice = object.shippingPrice || 0;
    this.summary = object.summary;
    this.distributorRemark = object.distributorRemark || '';
    this.date = object.date || Date.now();
  }
}
