import { Product } from 'models';

import { ProductsActions } from './actions';
import { ProductsTypes } from './types';

export interface ProductsState {
  lastViewedProducts: Product[];
  error?: string;
}

const INITIAL_STATE: ProductsState = {
  error: undefined,
  lastViewedProducts: [],
};

export default (
  state: ProductsState = INITIAL_STATE,
  action: ProductsActions,
) => {
  switch (action.type) {
    case ProductsTypes.AddLastViewedProducts:
      return {
        ...state,
        error: undefined,
        lastViewedProducts: action.payload.products,
      };

    case ProductsTypes.AddError:
      return {
        ...state,
        error: action.payload.error,
      };

    default:
      return state || INITIAL_STATE;
  }
};
