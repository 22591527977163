import { Position } from 'modules/cart';
import { ActionUnion, createAction } from 'modules/redux-store';

import { MapTypes } from './types';

export const MapActions = {
  addLocation: (position: Position | null) =>
    createAction(MapTypes.AddLocation, { position }),
};

export type MapActions = ActionUnion<typeof MapActions>;
