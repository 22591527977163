import { Product } from 'models';

export function filterByWallWidth(
  selectedWidths: Record<string, boolean>,
  initialList: Product[],
) {
  const checkedWidths = Object.entries(selectedWidths).reduce(
    (acc: string[], [key, value]) => {
      if (value) return [...acc, key];
      return acc;
    },
    [],
  );

  const filteredWidthProducts = checkedWidths.length
    ? initialList.filter((product) =>
        checkedWidths.includes(
          product.sku?.dimensions?.width?.toString() || '',
        ),
      )
    : initialList;

  return filteredWidthProducts;
}
