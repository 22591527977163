import React, { CSSProperties } from 'react';

interface Props {
  style?: CSSProperties | undefined;
}

export const BasketIcon: React.FC<Props> = ({ style }) => (
  <svg
    width="46"
    height="30"
    viewBox="0 0 46 46"
    fill="none"
    style={style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.25 21.2497L16.5 9.69971" stroke="black" strokeMiterlimit="10" />
    <path
      d="M31.3496 21.2497L23.0996 9.69971"
      stroke="black"
      strokeMiterlimit="10"
    />
    <path
      d="M28.0498 41.0495H9.8998L3.2998 21.2495H36.2998L28.0498 41.0495Z"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <circle
      cx="30.8001"
      cy="15.2"
      r="14.2"
      fill="#B30000"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M30.7903 18.5319C30.2916 18.5319 29.837 18.4439 29.4263 18.2679C29.023 18.0846 28.7003 17.8096 28.4583 17.4429C28.2163 17.0762 28.088 16.6179 28.0733 16.0679H29.2283C29.243 16.4786 29.3823 16.8269 29.6463 17.1129C29.9176 17.3916 30.299 17.5309 30.7903 17.5309C31.2596 17.5309 31.619 17.4026 31.8683 17.1459C32.1176 16.8819 32.2423 16.5556 32.2423 16.1669C32.2423 15.6976 32.0736 15.3492 31.7363 15.1219C31.4063 14.8946 30.9993 14.7809 30.5153 14.7809H29.9433V13.8129H30.5263C30.959 13.8129 31.3073 13.7102 31.5713 13.5049C31.8426 13.2996 31.9783 13.0099 31.9783 12.6359C31.9783 12.3206 31.872 12.0639 31.6593 11.8659C31.454 11.6679 31.1606 11.5689 30.7793 11.5689C30.3833 11.5689 30.0716 11.6862 29.8443 11.9209C29.617 12.1482 29.4923 12.4342 29.4703 12.7789H28.3153C28.33 12.3316 28.44 11.9429 28.6453 11.6129C28.858 11.2829 29.1476 11.0262 29.5143 10.8429C29.881 10.6596 30.3026 10.5679 30.7793 10.5679C31.2926 10.5679 31.7216 10.6596 32.0663 10.8429C32.4183 11.0189 32.6823 11.2609 32.8583 11.5689C33.0416 11.8696 33.1333 12.1996 33.1333 12.5589C33.1333 12.9696 33.0196 13.3289 32.7923 13.6369C32.565 13.9449 32.2606 14.1502 31.8793 14.2529C32.3193 14.3482 32.6823 14.5646 32.9683 14.9019C33.2543 15.2319 33.3973 15.6609 33.3973 16.1889C33.3973 16.6142 33.2983 17.0066 33.1003 17.3659C32.9023 17.7179 32.609 18.0002 32.2203 18.2129C31.8316 18.4256 31.355 18.5319 30.7903 18.5319Z"
      fill="white"
    />
  </svg>
);
