export function sortAlphabetically<T>(data: T[]) {
  const dataSorted = data.sort(function (a: T, b: T) {
    if (a.commercial.name < b.commercial.name) {
      return -1;
    }
    if (a.commercial.name > b.commercial.name) {
      return 1;
    }
    return 0;
  });

  return dataSorted;
}
