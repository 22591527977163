import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';
import { inbox } from 'styles';

import { ClientRequest, MessageLocation } from '../models';

interface Props {
  activeMailCategory: MessageLocation;
  primaryClientRequests: ClientRequest[];
  secondaryClientRequests: ClientRequest[];
}

export const InboxEmptyState: React.FC<Props> = ({
  activeMailCategory,
  primaryClientRequests,
  secondaryClientRequests,
}) => {
  return (
    <>
      <div css={inbox.mailActions} />

      <div css={inbox.emptyState}>
        {primaryClientRequests.length > 0 && (
          <p>
            <FormattedMessage id="inbox.empty-state.show-messages" />
          </p>
        )}

        {!isSecondaryTab(activeMailCategory) &&
          primaryClientRequests.length === 0 && (
            <p>
              <FormattedMessage id="inbox.empty-state.no-messages" />
            </p>
          )}

        {isSecondaryTab(activeMailCategory) &&
          secondaryClientRequests.length === 0 && (
            <p>
              <FormattedMessage id="inbox.empty-state.no-messages" />
            </p>
          )}

        {isSecondaryTab(activeMailCategory) &&
          secondaryClientRequests.length > 0 && (
            <p>
              <FormattedMessage id="inbox.empty-state.show-messages" />
            </p>
          )}
      </div>
    </>
  );

  function isSecondaryTab(text: MessageLocation) {
    switch (text) {
      case 'requested':
      case 'sent':
        return true;

      default:
        return false;
    }
  }
};
