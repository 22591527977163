import { Product } from 'models';

export function filterByRoofPitch(
  selectedValues: Record<string, boolean>,
  initialList: Product[],
) {
  const checkedRoofPitchValues = Object.entries(selectedValues).reduce(
    (acc: string[], [key, value]) => {
      if (value) return [...acc, key];
      return acc;
    },
    [],
  );

  const filteredProductsByRoofPitch = checkedRoofPitchValues.length
    ? initialList.filter((product) => {
        const roofPitch =
          product.sku?.salesRelation?.roofPitchStraightBondWelded;

        let determinedGroup: string;
        if (!roofPitch) {
          return false;
        } else if (roofPitch <= 15) {
          determinedGroup = 'group1';
        } else if (roofPitch > 15 && roofPitch <= 20) {
          determinedGroup = 'group2';
        } else if (roofPitch > 20 && roofPitch <= 30) {
          determinedGroup = 'group3';
        } else {
          determinedGroup = 'group4';
        }

        return checkedRoofPitchValues.includes(determinedGroup);
      })
    : initialList;

  return filteredProductsByRoofPitch;
}
