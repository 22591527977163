import { SerializedStyles } from '@emotion/core';
import React from 'react';

interface Props {
  className?: SerializedStyles;
}

export const ArrowLeft: React.FC<Props> = ({ className }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    css={className}
  >
    <g>
      <path
        d="M23.5 16L8.5 16"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 21L8.5 16L13.5 11"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
