import { SerializedStyles } from '@emotion/core';
import React from 'react';

interface Props {
  className?: SerializedStyles | string;
  onClick?: VoidFunction;
}

export const RemoveIcon: React.FC<Props> = ({ className, onClick }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    css={className}
    onClick={onClick}
  >
    <path
      d="M16.75 9C16.75 13.2802 13.2802 16.75 9 16.75C4.71979 16.75 1.25 13.2802 1.25 9C1.25 4.71979 4.71979 1.25 9 1.25C13.2802 1.25 16.75 4.71979 16.75 9Z"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5358 5.46436L5.46436 12.5358"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M5.46436 5.46436L12.5358 12.5358"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
