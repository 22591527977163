import { SerializedStyles } from '@emotion/core';
import React, { useState } from 'react';

interface Props {
  className?: SerializedStyles;
}

export const MailIcon: React.FC<Props> = ({ className }) => {
  const [hover, setHover] = useState(false);

  return (
    <svg
      css={className}
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
    >
      <path
        d="M16.3125 14.8125H1.6875C1.34264 14.8125 1.0625 14.5324 1.0625 14.1875V1.8125C1.0625 1.46764 1.34264 1.1875 1.6875 1.1875H16.3125C16.6574 1.1875 16.9375 1.46764 16.9375 1.8125V14.1875C16.9375 14.5324 16.6574 14.8125 16.3125 14.8125Z"
        stroke={hover ? '#B30000' : 'black'}
        strokeMiterlimit="10"
      />
      <path
        d="M2.8125 4.0625L9 9.125L15.1875 4.0625"
        stroke={hover ? '#B30000' : 'black'}
        strokeMiterlimit="10"
      />
    </svg>
  );

  function handleEnter() {
    setHover(true);
  }

  function handleLeave() {
    setHover(false);
  }
};
