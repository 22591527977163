import css from '@emotion/css';
import {
  colors,
  fontFamily,
  fontSize,
  fontWeights,
  layout,
  lineHeight,
  mqFrom,
  mqTo,
  unit,
} from 'styles';

const homepage = css`
  ${layout.wrapper};

  display: grid;

  padding: 10px 10px;
  grid-template-columns: 1fr 1fr;

  ${mqFrom.Med} {
    padding: 20px 40px;
  }

  ${mqFrom.Xlrg} {
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
  }

  grid-gap: 80px 20px;
  align-items: center;

  margin: auto;
`;

const calculatorWrapper = css`
  grid-gap: ${unit}px ${unit * 5}px;

  min-width: 300px;

  ${mqFrom.Sml} {
    min-width: 580px;
  }

  ${mqFrom.Med} {
    min-width: 720px;
  }

  ${mqFrom.Lrg} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;

    min-width: 980px;
  }
`;

const calculatorConatiner = css`
  ${layout.wrapper};

  display: flex;
  flex-direction: column;
  margin: auto;

  padding: 20px 0;

  ${mqFrom.Med} {
    padding: 40px 0;
  }

  margin: auto;
  align-items: center;
  justify-content: space-between;
`;

const image = css`
  max-width: 180px;

  ${mqFrom.Sml} {
    max-width: 250px;
  }
  ${mqFrom.Med} {
    max-width: 300px;
  }

  ${mqFrom.Lrg} {
    max-width: 380px;
  }
  width: 100%;
  height: 100%;
  margin: 0 auto;
  margin: ${unit * 6}px 0;
`;

const title = css`
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightBold};
  font-size: ${fontSize.large}px;
  line-height: ${lineHeight.large}px;

  color: ${colors.dark};

  padding-bottom: ${unit * 3}px;
`;

const text = css`
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightRegular};
  font-size: ${fontSize.small}px;
  line-height: ${lineHeight.small}px;

  color: ${colors.textSecondary};
  opacity: 0.7;
`;

const titleWrapper = css`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${mqTo.Xlrg} {
    grid-column-start: span 2;
  }
`;

const titleCalculator = css`
  display: flex;
  align-items: center;
  padding-bottom: ${unit * 3}px;

  position: relative;
  width: 80%;
  justify-content: center;
`;

const backWrapper = css`
  width: 30px;
  height: 30px;
  margin-right: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  transition: all 0.3s;

  cursor: pointer;

  &:hover {
    svg {
      path {
        stroke: ${colors.primary};
      }
    }

    background-color: #e0e0e0;
  }

  position: absolute;
  top: 0;
  left: 0;
`;

export const calculatorStyles = {
  homepage,
  calculatorConatiner,
  calculatorWrapper,
  image,
  title,
  titleWrapper,
  titleCalculator,
  text,
  backWrapper,
};
