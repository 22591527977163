import { ChevronDown } from 'assets';
import { ControlledCheckbox } from 'components';
import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { listing } from 'styles';
import { FacadeListingState } from 'template/AllFacadeListing';

interface Props {
  models: string[];
  colors: string[];
  concepts: string[];
  dimensions: string[];
  handleChange: (name: string, value: boolean, section?: string) => void;
  filterState: FacadeListingState;
}

export const FacadeFilters: React.FC<Props> = ({
  models,
  colors,
  concepts,
  dimensions,
  handleChange,
  filterState,
}) => {
  return (
    <>
      <p css={listing.filterTitle}>
        <FormattedMessage id="products.category.paint" />
      </p>
      <ul css={listing.accordionItem}>
        {colors.map((color) => (
          <li key={color}>
            <ControlledCheckbox
              key={color}
              name={color}
              text={color}
              section="colors"
              onChange={handleChange}
              checked={filterState.colors[color]}
            />
          </li>
        ))}
      </ul>

      <p css={listing.filterTitle}>
        <FormattedMessage id="products.category.facade" />
      </p>
      <ul css={listing.accordionItem}>
        {concepts.map((concept) => (
          <li key={concept}>
            <ControlledCheckbox
              name={concept}
              text={concept}
              section="concepts"
              onChange={handleChange}
              checked={filterState.concepts[concept]}
            />
          </li>
        ))}
      </ul>

      <Accordion allowMultipleExpanded allowZeroExpanded>
        <AccordionItem css={listing.accordionItem}>
          <AccordionItemHeading>
            <AccordionItemButton css={listing.accordionTitle}>
              <p>
                <FormattedMessage id="products.category.models" />
              </p>
              <ChevronDown />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {models.map((model) => (
              <ControlledCheckbox
                key={model}
                name={model}
                text={model}
                section="models"
                onChange={handleChange}
                checked={filterState.models[model]}
              />
            ))}
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem css={listing.accordionItem}>
          <AccordionItemHeading>
            <AccordionItemButton css={listing.accordionTitle}>
              <p>
                <FormattedMessage id="products.category.dimensions" />
              </p>
              <ChevronDown />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {dimensions.map((dimension) => (
              <ControlledCheckbox
                key={dimension}
                name={dimension}
                text={dimension}
                section="dimensions"
                onChange={handleChange}
                checked={filterState.dimensions[dimension]}
              />
            ))}
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};
