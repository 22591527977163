import { Product } from 'models';

export function filterByConsumption(
  selectedValues: Record<string, boolean>,
  initialList: Product[],
) {
  const checkedValues = Object.entries(selectedValues).reduce(
    (acc: string[], [key, value]) => {
      if (value) return [...acc, key];
      return acc;
    },
    [],
  );

  const filteredConsumptionProducts = checkedValues.length
    ? initialList.filter((product) =>
        checkedValues.includes(
          product.sku?.salesRelation?.piecesDry?.toString() ||
            product.sku?.salesRelation?.piecesMortar?.toString() ||
            '',
        ),
      )
    : initialList;

  return filteredConsumptionProducts;
}
