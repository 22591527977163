import { ClientSummary, Position } from 'modules/cart';
import {
  ExternalOfferModel,
  InternalOfferModel,
  OfferType,
} from 'modules/inbox';

import { MessageLocation } from './MessageLocation';
import { ProductRequest } from './ProductRequest';

export interface ClientRequest {
  id?: string;
  clientMsgLocation: MessageLocation;
  distributorMsgLocation: MessageLocation;
  distributorId: string;
  clientId: string | null;
  clientRequestId: string;
  productRequests?: {
    table: ProductRequest[];
    summary: ClientSummary;
  };
  date: number;
  distributorRead: boolean;
  clientRead: boolean;
  isAnswered: boolean;
  contactConsent?: boolean;
  clientInformation?: {
    address: string;
    location: Position;
    postCode: string;
    city: string;
    country: string;
    specialRemark?: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    isClientAnon?: boolean;
    business?: {
      name?: string;
      oib?: string;
      address?: string;
      postCode?: string;
      city?: string;
      email: string;
      phone: string;
    };
  };
  offerType: OfferType;
  internalOffers: InternalOfferModel[];
  externalOffers: ExternalOfferModel[];
  fileDetails?: {
    fileReader: FileReader | null;
    fileName: string;
  };
}

export class ClientRequest {
  constructor(object: Partial<ClientRequest>) {
    if (object.id) {
      this.id = object.id;
    }

    this.clientId = object.clientId || null;
    this.clientInformation = object.clientInformation;
    this.productRequests = object.productRequests;
    this.clientRequestId = object.clientRequestId || '';
    this.distributorId = object.distributorId || '';
    this.distributorRead = object.distributorRead || false;
    this.clientRead = object.clientRead || false;
    this.clientMsgLocation = object.clientMsgLocation || 'requested';
    this.distributorMsgLocation = object.distributorMsgLocation || 'inbox';
    this.offerType = object.offerType || null;
    this.internalOffers = object.internalOffers || [];
    this.externalOffers = object.externalOffers || [];
    this.date = object.date || Date.now();
    this.isAnswered = object.isAnswered || false;
    this.fileDetails = object.fileDetails || undefined;
    this.contactConsent = object.contactConsent || false;
  }
}
