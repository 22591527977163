import React from 'react';

export const LinkIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4 10.6016C15.4 12.6016 15.4 15.7016 13.4 17.7016L10.6 20.5016C8.6 22.5016 5.5 22.5016 3.5 20.5016C1.5 18.5016 1.5 15.4016 3.5 13.4016L6 11.0016"
      stroke="#B30000"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M10.5996 13.4C8.59961 11.4 8.59961 8.3 10.5996 6.3L13.3996 3.5C15.3996 1.5 18.4996 1.5 20.4996 3.5C22.4996 5.5 22.4996 8.6 20.4996 10.6L17.9996 13"
      stroke="#B30000"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);
