import { useLocation, useNavigate } from '@reach/router';
import { Alert, FormSubmit, ValidatedInput } from 'components';
import { PasswordInput } from 'components/PasswordInput';
import { EmailRegExp } from 'consts';
import { Link } from 'gatsby';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { AppDispatch, ApplicationState } from 'modules/redux-store';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { auth, spacers } from 'styles';

import { AuthThunks } from '../redux';
import { FacebookAuth } from './FacebookAuth';
import { GoogleAuth } from './GoogleAuth';
import { OrDivider } from './OrDivider';

interface State {
  email: string;
  password: string;
}

interface Props {
  navigatePath: string;
  sendCartInteractions?: () => void;
}

export const LoginForm: React.FC<Props> = ({
  navigatePath,
  sendCartInteractions,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();

  const { authStateChanging, error, isLoggedIn, isUserAnon } = useSelector(
    (state: ApplicationState) => state.auth,
  );
  const methods = useForm<State>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const navigateTo = useNavigate();
  const state = useLocation().state as { email: string } | undefined;

  if (isLoggedIn) {
    navigateTo(navigatePath);
  }

  const onSubmit = async (data: State) => {
    const error = await dispatch(
      AuthThunks.loginAsync(data.email, data.password),
    );

    if (!error) {
      navigateTo(navigatePath);
      sendCartInteractions && sendCartInteractions();
    }
  };

  return (
    <div css={auth.form}>
      <div css={auth.formTitle}>
        <h2 css={[spacers.bottom.medium, auth.title]}>
          <FormattedMessage id="login.title" />
        </h2>

        <p css={auth.text.large}>
          <FormattedMessage id="registration.title" />?{' '}
          <Link to="/registracija" css={auth.underline}>
            <FormattedMessage id="login.create-account" />
          </Link>
        </p>
      </div>

      <FacebookAuth
        text={formatMessage({ id: 'login.facebook-authentication' })}
      />

      <GoogleAuth
        text={formatMessage({ id: 'login.facebook-authentication' })}
      />

      <OrDivider />

      {state?.email && (
        <Alert
          type="success"
          text={formatMessage({ id: 'login.verification-success' })}
        />
      )}

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <ValidatedInput
            name="email"
            label={formatMessage({ id: 'shared.input.email.label' })}
            placeholder={formatMessage({
              id: 'shared.input.email.placeholder',
            })}
            defaultValue={state?.email || ''}
            validationObject={{
              required: formatMessage({ id: 'shared.validation.required' }),
              pattern: {
                value: EmailRegExp,
                message: formatMessage({
                  id: 'shared.validation.email.format',
                }),
              },
            }}
          />

          <PasswordInput
            name="password"
            label={formatMessage({ id: 'shared.input.password.label' })}
            placeholder={formatMessage({
              id: 'shared.input.password.placeholder',
            })}
            validationObject={{
              required: formatMessage({ id: 'shared.validation.required' }),
              minLength: {
                value: 8,
                message: formatMessage({
                  id: 'shared.validation.password.length',
                }),
              },
            }}
            forgotPassword
          />

          {error && (
            <Alert
              type="error"
              text={typeof error === 'string' ? error : error.message}
            />
          )}

          <FormSubmit
            text={formatMessage({ id: 'registration.existing-user.link' })}
            stateIsChanging={!isUserAnon && authStateChanging}
            className={auth.registerBtn}
            isUserAnon={isUserAnon}
          />
        </form>
      </FormProvider>
    </div>
  );
};
