import { Container } from 'components';
import { Link } from 'gatsby';
import React from 'react';

export const OrderSummary: React.FC = () => {
  return (
    <Container>
      <h1>Order summary</h1>

      <Link to="/">Go back to homepage</Link>
    </Container>
  );
};
