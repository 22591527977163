import { SerializedStyles } from '@emotion/core';
import React, { useState } from 'react';

interface Props {
  className?: SerializedStyles;
}

export const AvatarIcon: React.FC<Props> = ({ className }) => {
  const [hover, setHover] = useState(false);

  return (
    <svg
      css={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill={'none'}
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
    >
      <g>
        <path
          d="M13.8125 14.8341V14.3219C13.8125 13.5815 13.4158 12.8981 12.773 12.531L10.5599 11.2646"
          stroke={hover ? '#B30000' : 'black'}
          strokeMiterlimit="10"
        />
        <path
          d="M7.43732 11.2632L5.227 12.531C4.58419 12.8981 4.1875 13.5815 4.1875 14.3219V14.8341"
          stroke={hover ? '#B30000' : 'black'}
          strokeMiterlimit="10"
        />
        <path
          d="M9 11.75C7.48131 11.75 6.25 10.5187 6.25 9V7.625C6.25 6.10631 7.48131 4.875 9 4.875C10.5187 4.875 11.75 6.10631 11.75 7.625V9C11.75 10.5187 10.5187 11.75 9 11.75Z"
          stroke={hover ? '#B30000' : 'black'}
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M9 16.5625C13.1767 16.5625 16.5625 13.1767 16.5625 9C16.5625 4.82335 13.1767 1.4375 9 1.4375C4.82335 1.4375 1.4375 4.82335 1.4375 9C1.4375 13.1767 4.82335 16.5625 9 16.5625Z"
          stroke={hover ? '#B30000' : 'black'}
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="16.5"
            height="16.5"
            fill={'white'}
            transform="translate(0.75 0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  function handleEnter() {
    setHover(true);
  }

  function handleLeave() {
    setHover(false);
  }
};
