import firebase from 'firebase/app';

export interface User extends firebase.UserInfo {
  isAnonymous: boolean;
  firstName: string;
  lastName: string;
  role: 'customer' | 'distributor' | 'admin' | 'sales';
  distributorId?: string | string[] | null;
  orders: string[];
  termsAccepted: boolean;
  subscription: boolean;
  emailVerified?: boolean;
}

export class User {
  constructor(object?: Partial<User>) {
    if (object?.uid) {
      this.uid = object.uid;
    }
    this.isAnonymous = object?.isAnonymous || false;
    this.email = object?.email || '';
    this.firstName = object?.firstName || '';
    this.lastName = object?.lastName || '';
    this.role = object?.role || 'customer';
    this.distributorId = object?.distributorId || null;
    this.orders = object?.orders || [];
    this.termsAccepted = object?.termsAccepted || false;
    this.subscription = object?.subscription || false;
    this.emailVerified = object?.emailVerified || false;
  }
}
