/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { AnonymousCartFlowProvider } from './src/modules/authentication';
import 'react-toastify/dist/ReactToastify.css';

import createStore from './src/modules/redux-store/helpers/configureStore';
import { Helmet } from 'react-helmet';

export const wrapRootElement = ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const { store, persistor } = createStore();
  return (
    <Provider store={store}>
      <Helmet>
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid={process.env.GATSBY_COOKIEBOT_ID}
          type="text/javascript"
          async
        />
      </Helmet>
      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>
    </Provider>
  );
};

export const wrapPageElement = ({ element }) => {
  return <AnonymousCartFlowProvider>{element}</AnonymousCartFlowProvider>;
};
