import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';
import { auth } from 'styles';

export const OrDivider: React.FC = () => (
  <div css={auth.orDivider}>
    <div />
    <span>
      <FormattedMessage id="shared.or" />
    </span>
    <div />
  </div>
);
