import { SerializedStyles } from '@emotion/core';
import React from 'react';
import { layout } from 'styles';

interface ContainerProps {
  className?: SerializedStyles;
}

export const Container: React.FC<ContainerProps> = ({
  children,
  className,
}) => {
  return <div css={className || layout.wrapper}>{children}</div>;
};
