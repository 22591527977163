export const mqTo = {
  Xxsml: `@media (max-width: 321px)`,
  XSml: `@media (max-width: 414px)`,
  Sml: `@media (max-width: 583px)`,
  Med: `@media (max-width: 767px)`,
  Lrg: `@media (max-width: 991px)`,
  Xlrg: `@media (max-width: 1199px)`,
  Xxlrg: `@media (max-width: 1439px)`,
};

export const mqFrom = {
  XSml: `@media (min-width: 415px)`,
  Sml: `@media (min-width: 584px)`,
  Med: `@media (min-width: 768px)`,
  Lrg: `@media (min-width: 992px)`,
  Xlrg: `@media (min-width: 1200px)`,
  Xxlrg: `@media (min-width: 1440px)`,
};
