import { ChevronDown } from 'assets';
import { ControlledCheckbox } from 'components';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { Product } from 'models';
import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { listing } from 'styles';
import { CategoryFiltersState } from 'template/CategoryPage';
import { resolveCategory } from 'utils';

interface Props {
  products: Product[];
  navCategory: 'Roof' | 'Wall' | 'Facade';
  models: string[];
  colors: string[];
  wallWidths: number[];
  consumptions: number[];
  handleChange: (name: string, value: boolean, section?: string) => void;
  filtersState: CategoryFiltersState;
}

export const CategoryFilters: React.FC<Props> = ({
  products,
  models,
  navCategory,
  colors,
  wallWidths,
  consumptions,
  handleChange,
  filtersState,
}) => {
  return (
    <>
      {products[0].commercial.category && (
        <>
          <p css={listing.filterTitle}>
            <FormattedMessage id="products.category.title" />{' '}
            {resolveCategory(products[0].commercial.navigationCategories.code)}
          </p>
          <p css={listing.accordionItem}>{products[0].commercial.category}</p>
        </>
      )}

      <p css={listing.filterTitle}>
        <FormattedMessage id="products.category.models" />
      </p>
      <ul css={listing.accordionItem}>
        {models.map((model) => (
          <li key={model}>
            <ControlledCheckbox
              name={model}
              text={model}
              section="models"
              onChange={handleChange}
              checked={filtersState.models[model]}
            />
          </li>
        ))}
      </ul>

      {navCategory === 'Roof' && (
        <Accordion allowMultipleExpanded allowZeroExpanded>
          <AccordionItem css={listing.accordionItem}>
            <AccordionItemHeading>
              <AccordionItemButton css={listing.accordionTitle}>
                <p>
                  <FormattedMessage id="products.category.pieces" />
                </p>
                <ChevronDown />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <ControlledCheckbox
                name="10"
                text="10+"
                section="piecesPerMsq"
                onChange={handleChange}
                checked={filtersState.piecesPerMsq['10']}
              />
              <ControlledCheckbox
                name="20"
                text="20+"
                section="piecesPerMsq"
                onChange={handleChange}
                checked={filtersState.piecesPerMsq['20']}
              />
              <ControlledCheckbox
                name="30"
                text="30+"
                section="piecesPerMsq"
                onChange={handleChange}
                checked={filtersState.piecesPerMsq['30']}
              />
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem css={listing.accordionItem}>
            <AccordionItemHeading>
              <AccordionItemButton css={listing.accordionTitle}>
                <p>
                  <FormattedMessage id="products.category.paint" />
                </p>
                <ChevronDown />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {colors.map((color) => (
                <ControlledCheckbox
                  key={color}
                  name={color}
                  text={color}
                  section="colors"
                  onChange={handleChange}
                  checked={filtersState.colors[color]}
                />
              ))}
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem css={listing.accordionItem}>
            <AccordionItemHeading>
              <AccordionItemButton css={listing.accordionTitle}>
                <p>
                  <FormattedMessage id="products.category.slope" />
                </p>
                <ChevronDown />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <ControlledCheckbox
                name="group1"
                text="do 15°"
                section="minRoofPitch"
                onChange={handleChange}
                checked={filtersState.minRoofPitch['group1']}
              />
              <ControlledCheckbox
                name="group2"
                text="od 15° do 20°"
                section="minRoofPitch"
                onChange={handleChange}
                checked={filtersState.minRoofPitch['group2']}
              />
              <ControlledCheckbox
                name="group3"
                text="od 20° do 30°"
                section="minRoofPitch"
                onChange={handleChange}
                checked={filtersState.minRoofPitch['group3']}
              />
              <ControlledCheckbox
                name="group4"
                text="preko 30°"
                section="minRoofPitch"
                onChange={handleChange}
                checked={filtersState.minRoofPitch['group4']}
              />
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      )}

      {navCategory === 'Wall' && (
        <Accordion allowMultipleExpanded allowZeroExpanded>
          <AccordionItem css={listing.accordionItem}>
            <AccordionItemHeading>
              <AccordionItemButton css={listing.accordionTitle}>
                <p>
                  <FormattedMessage id="products.category.wall-thickness" />
                </p>
                <ChevronDown />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {wallWidths.map((width) => (
                <ControlledCheckbox
                  key={width}
                  name={width.toString()}
                  text={`${width}mm`}
                  section="wallWidths"
                  onChange={handleChange}
                  checked={filtersState.wallWidths[width]}
                />
              ))}
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem css={listing.accordionItem}>
            <AccordionItemHeading>
              <AccordionItemButton css={listing.accordionTitle}>
                <p>
                  <FormattedMessage id="products.category.consumption-per-square" />
                </p>
                <ChevronDown />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {consumptions.map((consumption) => (
                <ControlledCheckbox
                  key={consumption}
                  name={consumption.toString()}
                  text={consumption.toString()}
                  section="consumptions"
                  onChange={handleChange}
                  checked={filtersState.consumptions[consumption]}
                />
              ))}
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      )}
    </>
  );
};
