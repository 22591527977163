import { FormattedMessage } from 'gatsby-plugin-intl';
import React, { useEffect, useState } from 'react';
import { buttons, listing, spacers } from 'styles';
import { FacadeListingState } from 'template/AllFacadeListing';
import { handleShowFilterButton } from 'utils';

import { FacadeFilters } from './FacadeFilters';
import { ProductBrand } from './ProductBrand';

interface Props {
  amount: number;
  models: string[];
  colors: string[];
  concepts: string[];
  dimensions: string[];
  handleChange: (name: string, value: boolean, section?: string) => void;
  clearFilters: VoidFunction;
  filterState: FacadeListingState;
}

export const DesktopFacadeFilters: React.FC<Props> = ({
  amount,
  models,
  colors,
  concepts,
  dimensions,
  handleChange,
  clearFilters,
  filterState,
}) => {
  const [isFilterPicked, setIsFilterPicked] = useState(false);

  useEffect(() => {
    setIsFilterPicked(handleShowFilterButton(filterState));
  }, [filterState]);

  const handleClick = () => {
    clearFilters();
    setIsFilterPicked(false);
  };

  const isButtonHidden = isFilterPicked ? '' : buttons.hidden;
  return (
    <section css={listing.filters}>
      <ProductBrand brand="Terca" amount={amount} />
      <div css={isButtonHidden}>
        <button
          type="button"
          css={[buttons.ghost.small, spacers.bottom.huge]}
          onClick={handleClick}
        >
          <FormattedMessage id="products.clear-filter" />
        </button>
      </div>
      <FacadeFilters
        models={models}
        concepts={concepts}
        colors={colors}
        dimensions={dimensions}
        handleChange={handleChange}
        filterState={filterState}
      />
    </section>
  );
};
