import { User } from 'modules/authentication';

export function isUser(object: unknown): object is User {
  return (
    Boolean(object) &&
    Boolean((object as User).firstName) &&
    Boolean((object as User).lastName) &&
    Boolean((object as User).email)
  );
}
