import { SerializedStyles } from '@emotion/core';
import React, { Fragment } from 'react';
import { loading } from 'styles';

interface Props {
  isLoading: boolean;
  fullPage?: boolean;
  className?: SerializedStyles | string[];
  color?: SerializedStyles | string[];
}

export const Loading: React.FC<Props> = ({
  isLoading,
  fullPage,
  children,
  className,
  color,
}) =>
  isLoading ? (
    <Fragment>
      {fullPage ? (
        <div css={loading.pageWrapper}>
          <div css={loading.content}>
            <img
              src={require('content/Wienerberger-new-logo.png')}
              alt="Wienerberger"
            />

            <div css={loading.spinner} />
          </div>
        </div>
      ) : (
        <div css={className || loading.spinnerWrapper}>
          <div css={[loading.spinner, color]} />
        </div>
      )}
    </Fragment>
  ) : (
    <Fragment>{children}</Fragment>
  );
