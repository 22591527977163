import { SerializedStyles } from '@emotion/core';
import React from 'react';

interface Props {
  className?: SerializedStyles;
  onClick?: VoidFunction;
}

export const PasswordHide: React.FC<Props> = ({ className, onClick }) => (
  <svg
    width="16"
    height="48"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    css={className}
    onClick={onClick}
  >
    <path
      d="M13.5001 5.73199C14.7301 6.89999 15.5001 7.99999 15.5001 7.99999C15.5001 7.99999 12.0001 13 8.00009 13C7.48809 13 6.98509 12.918 6.49609 12.775"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.275 11.725C2.048 10.243 0.5 8 0.5 8C0.5 8 4 3 8 3C9.326 3 10.605 3.553 11.712 4.288"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 15L15 1"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
