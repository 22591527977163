import { MapLocationIcon } from 'assets';
import { InboxRequestTable, InboxRequestTableMobile, Modal } from 'components';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { ClientRequest } from 'modules/inbox';
import React, { useState } from 'react';
import { buttons, inbox, spacers } from 'styles';

import { LocationModal } from './LocationModal';

interface Props {
  currentRequest: ClientRequest;
  toggleNewOfferOpened?: React.Dispatch<React.SetStateAction<boolean>>;
  setOfferType?: React.Dispatch<
    React.SetStateAction<'internal' | 'external' | null>
  >;
}

export const ClientProductRequestsAndSummary: React.FC<Props> = ({
  currentRequest,
  toggleNewOfferOpened,
  setOfferType,
}) => {
  const { formatMessage } = useIntl();
  const [modalOpen, setModalOpen] = useState(false);

  const offersExist =
    Boolean(currentRequest.internalOffers.length) ||
    Boolean(currentRequest.externalOffers.length);

  const currentOfferType = currentRequest.offerType;

  function toggleModal() {
    setModalOpen(!modalOpen);
  }

  function handleNewOfferClick() {
    if (!setOfferType || !toggleNewOfferOpened) return;

    setOfferType(currentOfferType || 'internal');
    toggleNewOfferOpened(true);
  }

  return (
    <>
      <InboxRequestTable currentRequest={currentRequest} />

      <InboxRequestTableMobile currentRequest={currentRequest} />

      <div css={inbox.offerFooter}>
        {currentRequest.clientInformation?.location && (
          <>
            <div css={inbox.footerSection}>
              <p css={[spacers.bottom.xLarge]}>
                <FormattedMessage id="cart.home-page.product.shipping" />
              </p>
              <button
                type="button"
                css={inbox.mapLocation}
                onClick={toggleModal}
              >
                <MapLocationIcon className={[spacers.right.medium]} />
                <FormattedMessage id="admin.client-request-summary.map-location" />
              </button>
            </div>

            <Modal
              isShowing={modalOpen}
              hide={toggleModal}
              onConfirm={toggleModal}
            >
              <LocationModal
                location={currentRequest.clientInformation?.location}
              />
            </Modal>
          </>
        )}

        <div css={inbox.footerSectionDesktop}>
          <div css={inbox.summaryRemark}>
            <span>
              <FormattedMessage id="shared.remark" />
            </span>
            {currentRequest.clientInformation?.specialRemark && (
              <p>{currentRequest.clientInformation.specialRemark}</p>
            )}
          </div>
        </div>
      </div>

      {toggleNewOfferOpened && (
        <div css={inbox.mobile}>
          <button
            type="button"
            css={[buttons.primary.small, spacers.left.xLarge]}
            onClick={handleNewOfferClick}
          >
            {offersExist
              ? formatMessage({ id: 'inbox.client-info.new-offer' })
              : formatMessage({ id: 'inbox.client-info.offer' })}
          </button>
        </div>
      )}
    </>
  );
};
