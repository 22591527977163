import React from 'react';

export const QuestionIcon: React.FC = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    opacity="0.5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M9 16.5625C13.1767 16.5625 16.5625 13.1767 16.5625 9C16.5625 4.82335 13.1767 1.4375 9 1.4375C4.82335 1.4375 1.4375 4.82335 1.4375 9C1.4375 13.1767 4.82335 16.5625 9 16.5625Z"
        stroke="#3C3737"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M9.1875 13.125C9.1875 13.2286 9.10355 13.3125 9 13.3125C8.89645 13.3125 8.8125 13.2286 8.8125 13.125C8.8125 13.0214 8.89645 12.9375 9 12.9375C9.10355 12.9375 9.1875 13.0214 9.1875 13.125Z"
        fill="#3C3737"
        stroke="#3C3737"
      />
      <path
        d="M7.52393 5.25984C8.88518 4.65828 10.6466 4.72978 11.2481 5.70328C11.8497 6.67678 11.4344 7.80909 10.4032 8.6829C9.37193 9.55671 8.99999 10.0311 8.99999 10.7186"
        stroke="#3C3737"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="16.5"
          height="16.5"
          fill="white"
          transform="translate(0.75 0.75)"
        />
      </clipPath>
    </defs>
  </svg>
);
