import { Link } from 'gatsby';
import Image from 'gatsby-image';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { SkuAccessoryProduct } from 'models';
import React from 'react';
import { product } from 'styles';
import { localizeNavCategory, renameModels, slugify } from 'utils';

interface Props {
  skuAccessories: SkuAccessoryProduct[];
  title?: string;
}

export const SystemSolutions: React.FC<Props> = ({ skuAccessories, title }) => {
  function getCategoryRoute(navCategory: string, category: string) {
    if (navCategory === 'RoofWindows') return 'tondach';
    if (category) return category;
    return 'terca';
  }

  return (
    <section css={product.horizontalListWrapper}>
      <h4 css={product.subTitle}>
        {title || <FormattedMessage id="products.category.system-solutions" />}
      </h4>

      <div css={product.horizontalListScroll}>
        <div css={product.horizontalList}>
          {skuAccessories.map((acc, index) => (
            <Link
              css={product.horizontalListItem}
              key={index}
              to={`/${slugify(localizeNavCategory(acc.navCategory))}/${slugify(
                slugify(getCategoryRoute(acc.navCategory, acc.category)),
              )}/${slugify(renameModels(acc.model))}/${slugify(acc.name)}`}
            >
              <Image
                fixed={{
                  src: acc.image,
                  srcSet: '',
                  width: 140,
                  height: 140,
                }}
              />

              <p>{acc.localizedName}</p>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};
