import { Font, StyleSheet, Text, View } from '@react-pdf/renderer';
import font from 'assets/fonts/DMSans-Regular.ttf';
import { CartProduct } from 'modules/cart';
import React from 'react';
import { formatPrice } from 'utils';

Font.register({
  family: 'DMSans',
  src: font,
});

interface Props {
  products: CartProduct[];
}

export const Request: React.FC<Props> = ({ products }) => {
  return (
    <View style={styles.font}>
      <View style={styles.container}>
        <View style={styles.headerContent}>
          <View style={styles.textWrapper}>
            <Text style={styles.smallText}>Naziv proizvoda</Text>
          </View>
          <View style={styles.textWrapper}>
            <Text style={[styles.smallText, styles.textCenter]}>Količina</Text>
          </View>
          <View style={styles.textWrapper}>
            <Text style={[styles.smallText, styles.textCenter]}>Mjera</Text>
          </View>
          <View style={styles.textWrapper}>
            <Text style={[styles.smallText, styles.textCenter]}>
              Preporučena cijena
            </Text>
          </View>
        </View>

        {products.map((item, index) => (
          <View style={styles.content} key={index}>
            <View style={styles.textWrapper}>
              <Text style={styles.text}>{item.title}</Text>
            </View>
            <View style={[styles.textWrapper, styles.textCenter]}>
              <Text style={[styles.text, styles.brighterText]}>
                {item.quantity.quantity}
              </Text>
            </View>
            <View style={[styles.textWrapper, styles.textCenter]}>
              <Text style={[styles.text, styles.brighterText]}>
                {item.quantity.type}
              </Text>
            </View>
            <View style={[styles.textWrapper, styles.textCenter]}>
              <Text style={styles.text}>{formatPrice(item.price)} €</Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  font: {
    fontFamily: 'DMSans',
  },
  text: {
    fontSize: 10,
    letterSpacing: 1,
  },
  smallText: {
    fontSize: 7,
    letterSpacing: 1,
  },
  textWrapper: {
    flex: 1,
    alignSelf: 'stretch',
    paddingHorizontal: 5,
    marginTop: 5,
    marginBottom: 10,
  },
  headerContent: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    borderBottomColor: 'rgb(0, 0, 0, 0.1)',
    borderBottomWidth: 0.5,
    marginTop: 5,
    marginBottom: 10,
    height: 15,
  },
  textCenter: {
    textAlign: 'center',
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    flex: 1,
    alignSelf: 'stretch',
    flexDirection: 'row',
    borderBottomColor: 'rgb(0, 0, 0, 0.1)',
    borderBottomWidth: 0.5,
    height: 90,
  },
  offerSummary: {
    marginBottom: 10,
  },
  summaryBorder: {
    borderBottomColor: 'rgba(0,0,0,0.1)',
    borderBottomWidth: 0.5,
    marginLeft: 200,
    width: 300,
  },
  summaryRow: {
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: 5,
    paddingLeft: 200,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  brighterText: {
    opacity: 0.5,
  },
});
