import { css } from '@emotion/core';

import { color } from './mixins.styles';
import { mqFrom } from './responsive.styles';
import { spacers } from './spacers.styles';
import { typescale } from './typescale.styles';
import { colors, unit } from './vars.styles';

const root = css`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const section = css`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${mqFrom.Xlrg} {
    padding: ${unit * 4}px 0;
    flex-direction: row;
  }
`;

const footerWnbgWorld = css`
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  strong {
    ${typescale.small}
    ${spacers.right.large}
  }
`;

const products = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 28px;

  img {
    height: 24px;
    image-rendering: -webkit-optimize-contrast;
  }

  ${mqFrom.Xlrg} {
    margin-bottom: 0;
    align-items: center;
  }
`;

const social = css`
  display: flex;
  padding: ${unit * 2}px 0;
  margin-bottom: 32px;

  a {
    margin-right: 28px;

    ${mqFrom.Xlrg} {
      margin-right: 12px;
      padding: 4px;
    }

    img {
      width: 20px;
    }
  }
`;

const links = css`
  border-top: 1px solid ${color(colors.dark, 0.1)};
  border-bottom: 1px solid ${color(colors.dark, 0.1)};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${typescale.tiny}
  padding: 20px 0;
  margin-bottom: 32px;

  ${mqFrom.Xlrg} {
    border: none;
    align-items: center;
    margin-bottom: 0;
  }

  a {
    color: #3c3737;
    opacity: 0.6;
    width: 50%;

    &:hover {
      color: ${colors.primary};
    }

    &:first-of-type {
      margin-bottom: 20px;
    }

    ${mqFrom.Sml} {
      width: auto;

      &:first-of-type {
        margin-bottom: 0;
      }
    }

    ${mqFrom.Xlrg} {
      margin-left: 20px;
    }
  }
`;

const copy = css`
  color: #3c3737;
  opacity: 0.6;
  font-size: 12px;
  line-height: 16px;
  padding: ${unit}px 0;

  ${mqFrom.Xlrg} {
    padding: 0;
    margin-left: 50px;
  }
`;

const mobileVersion = css`
  flex-direction: column-reverse;

  ${mqFrom.Xlrg} {
    flex-direction: row;
    flex: 1;
  }
`;

const bottomSection = css`
  ${mqFrom.Xlrg} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const footerStyles = {
  root,
  section,
  footerWnbgWorld,
  products,
  social,
  links,
  mobileVersion,
  copy,
  bottomSection,
};
