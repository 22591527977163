import { Link } from 'gatsby';
import Image from 'gatsby-image';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { InternalOfferModel } from 'modules/inbox';
import React from 'react';
import { inbox, spacers } from 'styles';
import { formatPrice, getProductRoute } from 'utils';

interface Props {
  offer: InternalOfferModel;
}

export const InboxOfferTableMobile: React.FC<Props> = ({ offer }) => {
  return (
    <div css={inbox.productsAccordionMobile}>
      <ul css={inbox.productsListMobile}>
        {offer.productOffers.map((item, index) => (
          <li key={index} css={inbox.productsListItemMobile}>
            <div css={inbox.productsListItemTitle}>
              <Image
                fixed={{
                  src:
                    item.product.commercial.assets.mainImg ||
                    item.product.commercial.assets.otherImgs[0],
                  srcSet: '',
                  width: 48,
                  height: 48,
                }}
              />

              <Link
                to={getProductRoute(item.product.commercial)}
                css={[spacers.left.medium]}
              >
                {item.product.commercial.localizedName}
              </Link>
            </div>

            <div css={inbox.productsListItemInfo}>
              <div>
                <p>{item.amount}</p>
                <span>
                  <FormattedMessage id="cart.home-page.product.amount" />
                </span>
              </div>

              <div>
                <p>{item.unit}</p>
                <span>
                  <FormattedMessage id="cart.home-page.product.measure" />
                </span>
              </div>

              <div>
                <p>{item.price} €</p>
                <span>
                  <FormattedMessage id="cart.home-page.product.recommended-price" />
                </span>
              </div>
            </div>
          </li>
        ))}
      </ul>

      <div css={inbox.offerSummary}>
        <div css={inbox.summaryRow}>
          <span>
            <FormattedMessage id="admin.answered-requests.price-without-vat-and-rebate" />
          </span>
          <p>{formatPrice(offer.summary?.totalWithoutTax)} €</p>
        </div>

        <div css={inbox.summaryRow}>
          <span>
            <FormattedMessage id="cart.home-page.product.rebate" />
          </span>
          <p>{formatPrice(offer.summary?.rebate)} €</p>
        </div>

        <div css={inbox.summaryRow}>
          <span>
            <FormattedMessage id="cart.home-page.product.shipping" />
          </span>
          <p>{formatPrice(offer.shippingPrice)} €</p>
        </div>

        <div css={inbox.summaryRow}>
          <span>PDV</span>
          <p>{formatPrice(offer.summary?.tax)} €</p>
        </div>
      </div>

      <div css={inbox.summaryTotalMobile}>
        <p>
          <span>
            <FormattedMessage id="shared.total" />
          </span>{' '}
          (PDV uračunat)
        </p>
        <p>{formatPrice(offer.summary?.total)} €</p>
      </div>

      <div css={[inbox.summaryRemark, spacers.left.xLarge]}>
        <span css={[spacers.bottom.small]}>
          <FormattedMessage id="shared.remark" />
        </span>
        <p>{offer.distributorRemark}</p>
      </div>
    </div>
  );
};
