import { Collection } from 'consts';
import firebase from 'firebase/app';
import { AppDispatch, ApplicationState } from 'modules/redux-store';
import { isUser } from 'utils';

import {
  AuthService,
  FirebaseFunctionsService,
  FirestoreListenerActions,
  FirestoreService,
} from '../../firebase';
import { RegistrationForm, User } from '../models';
import { AuthActions } from './actions';

const authService = AuthService();
const firestoreService = FirestoreService(Collection.Users);
const funcService = FirebaseFunctionsService();

const anonymousLoginAsync = () => async (dispatch: AppDispatch) => {
  dispatch(AuthActions.authStateChange(true));
  const anonUser = await authService.anonymousLoginAsync();
  return anonUser;
};

const loginAsync =
  (email: string, password: string) => async (dispatch: AppDispatch) => {
    dispatch(AuthActions.authStateChange(false));

    const error = await authService.loginWithEmailAndPasswordAsync(
      email,
      password,
    );

    if (error) {
      dispatch(AuthActions.loginError(error));
    }

    return error;
  };

const loginWithPersistedUser =
  () => (dispatch: AppDispatch, getState: () => ApplicationState) => {
    dispatch(AuthActions.authStateChange());

    const authListeners = authService.initAuthStateListener(
      async (user: firebase.UserInfo) => {
        const fbUser = await firestoreService?.find(user.uid);

        dispatch(
          AuthActions.loginSuccess({ ...new User({ ...user }), ...fbUser }),
        );
      },
      (error?: firebase.FirebaseError) =>
        dispatch(AuthActions.loginError(error)),
    );

    if (!getState().listeners.authStateListener && authListeners) {
      dispatch(
        FirestoreListenerActions.SetListener(
          'authStateListener',
          authListeners,
        ),
      );
    }
  };

const logout = () => async (dispatch: AppDispatch) => {
  dispatch(AuthActions.authStateChange());

  await authService.logoutAsync();
  dispatch(AuthActions.logout());
};

const register = (data: RegistrationForm) => async (dispatch: AppDispatch) => {
  dispatch(AuthActions.authStateChange());

  const response = await (await funcService).registerNewUser(data);

  if (response === 'Korisnik s datom email adresom već postoji') {
    dispatch(
      AuthActions.loginError({
        name: '',
        code: 'auth/alredy-exists',
        message: response,
      }),
    );
    return response;
  }

  dispatch(AuthActions.loginError());
  return response;
};

const signInWithFacebookAccount =
  () => async (dispatch: AppDispatch, getState: () => ApplicationState) => {
    dispatch(AuthActions.authStateChange());

    const response = await authService.signInWithFacebook();

    if (typeof response === 'string') {
      dispatch(AuthActions.loginError(response));
      return response;
    }

    const currentUserData = getState().auth.user;
    if (isUser(response)) {
      dispatch(AuthActions.loginSuccess({ ...currentUserData, ...response }));
      return;
    }

    if (currentUserData) {
      dispatch(AuthActions.loginSuccess(currentUserData));
    }

    return;
  };

const signInWithGoogleAccount =
  () => async (dispatch: AppDispatch, getState: () => ApplicationState) => {
    dispatch(AuthActions.authStateChange());

    const response = await authService.signInWithGoogle();
    if (typeof response === 'string') {
      dispatch(AuthActions.loginError(response));
      return response;
    }

    const currentUserData = getState().auth.user;
    if (isUser(response)) {
      dispatch(AuthActions.loginSuccess({ ...currentUserData, ...response }));
      return;
    }

    if (currentUserData) {
      dispatch(AuthActions.loginSuccess(currentUserData));
    }

    return;
  };

const sendPasswordResetLink = (email: string) => async () => {
  const error = await authService
    .sendPasswordResetEmail(email)
    .then(() => null)
    .catch((error) => {
      return error.message;
    });

  return error;
};

const verifyPasswordResetCode = (code: string) => async () => {
  const error = await authService.verifyPasswordResetCode(code);

  return error;
};

const verifyEmailValidCode = (code: string) => async () => {
  const response = await authService.verifyEmailValidCode(code);

  if (!response) return 'Email link više nije validan';

  const confirmation = await (
    await funcService
  ).confirmEmailVerificationLink(response.email);
  return confirmation.error;
};

const confirmPasswordReset =
  (code: string, newPassword: string) => async () => {
    const error = await authService.confirmPasswordReset(code, newPassword);

    return error;
  };

export const AuthThunks = {
  anonymousLoginAsync,
  loginAsync,
  loginWithPersistedUser,
  logout,
  register,
  sendPasswordResetLink,
  verifyPasswordResetCode,
  verifyEmailValidCode,
  confirmPasswordReset,
  signInWithFacebookAccount,
  signInWithGoogleAccount,
};
