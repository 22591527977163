import { FormattedMessage } from 'gatsby-plugin-intl';
import { OrDivider } from 'modules/authentication';
import React, { useState } from 'react';
import { buttons, inbox, inputs, spacers } from 'styles';

import { OfferState } from '../hooks';

interface Props {
  offerState: OfferState;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  setOfferType: (
    value: React.SetStateAction<'internal' | 'external' | null>,
  ) => void;
  createNewOffer: VoidFunction;
  toggleNewOfferOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OfferFooter: React.FC<Props> = ({
  offerState,
  handleChange,
  setOfferType,
  createNewOffer,
  toggleNewOfferOpened,
}) => {
  const [isNewOfferCreated, setIsNewOfferCreated] = useState(false);
  const handleOfferCreation = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsNewOfferCreated(true);
    createNewOffer();
  };

  const disabledButton = isNewOfferCreated && buttons.disabled;
  return (
    <div css={inbox.offerFooterWrapper}>
      <div css={inbox.offerFooterTextarea}>
        <p css={[spacers.bottom.medium]}>
          <FormattedMessage id="shared.remark" />
        </p>
        <textarea
          name="distributorRemark"
          value={offerState.distributorRemark}
          css={inputs.inputTextarea}
          onChange={handleChange}
        />
      </div>

      <div css={inbox.offerFooterBtnWrapper}>
        <button
          type="button"
          css={buttons.primary.medium}
          onClick={handleOfferCreation}
        >
          <FormattedMessage id="shared.send-offer" />
        </button>

        <OrDivider />
        <button
          type="button"
          css={[
            buttons.container,
            buttons.ghost.medium,
            inbox.offerCancelButton,
            disabledButton,
          ]}
          onClick={cancelOffer}
        >
          <FormattedMessage id="cart.data.give-up" />
        </button>
      </div>
    </div>
  );

  function cancelOffer() {
    setOfferType(null);
    toggleNewOfferOpened(false);
  }
};
