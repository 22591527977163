import { AvatarIcon, CartIcon, MailIcon, SearchIcon } from 'assets';
import { Link } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { AuthThunks } from 'modules/authentication';
import { CartPopup } from 'modules/cart';
import { AppDispatch, ApplicationState } from 'modules/redux-store';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { buttons, layout } from 'styles';

import { ManagementState } from './Header';

interface Props {
  onEnter: VoidFunction;
  onSearchClick: VoidFunction;
  handleOpen: (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => void;
  state: ManagementState;
}

export const HeaderManagement: React.FC<Props> = ({
  onEnter,
  onSearchClick,
  handleOpen,
  state,
}) => {
  const { isLoggedIn, user } = useSelector(
    (state: ApplicationState) => state.auth,
  );
  const { products } = useSelector((state: ApplicationState) => state.cart);
  const { isActive } = useSelector((state: ApplicationState) => state.search);

  const dispatch = useDispatch<AppDispatch>();

  const popupArrow = state.profileOpen && layout.navUserWrapperAfter;

  return (
    <div
      css={[layout.navUser, isActive ? layout.hidden : null]}
      onMouseEnter={onEnter}
    >
      <div css={layout.navUserWrapper}>
        <button
          onClick={onSearchClick}
          css={[buttons.container, layout.navUserBtn]}
        >
          <SearchIcon />
        </button>
      </div>

      <div css={[layout.navUserWrapper, popupArrow]}>
        <button
          type="button"
          data-id="profileOpen"
          onClick={handleOpen}
          css={[buttons.container, layout.navUserBtn]}
        >
          <AvatarIcon />
        </button>

        {state.profileOpen && (
          <div css={layout.popup}>
            {isLoggedIn && (
              <div css={layout.popupItemWrapper}>
                {user?.role === 'admin' && (
                  <Link to="/admin" css={layout.popupBtn}>
                    <FormattedMessage id="navigation.header.admin" />
                  </Link>
                )}
                {!user?.isAnonymous && (
                  <Link to="/profil" css={layout.popupBtn}>
                    <FormattedMessage id="navigation.header.profile" />
                  </Link>
                )}
                {!user?.isAnonymous && (
                  <Link to="/inbox" css={layout.popupBtn}>
                    <FormattedMessage id="navigation.header.orders" />
                  </Link>
                )}
                <Link to="/faq" css={layout.popupBtn}>
                  <FormattedMessage id="navigation.header.faq" />
                </Link>
                <button
                  type="button"
                  css={[buttons.container, layout.popupBtn]}
                  onClick={handleLogout}
                >
                  <FormattedMessage id="navigation.header.logout" />
                </button>
              </div>
            )}
            {!isLoggedIn && (
              <div css={layout.popupItemWrapper}>
                <Link to="/prijava" css={layout.popupBtn}>
                  <FormattedMessage id="navigation.header.login" />
                </Link>
                <Link to="/registracija" css={layout.popupBtn}>
                  <FormattedMessage id="navigation.header.registration" />
                </Link>
                <Link to="/faq" css={layout.popupBtn}>
                  <FormattedMessage id="navigation.header.faq" />
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
      {!user?.isAnonymous && (
        <div css={layout.navUserWrapper}>
          <Link
            to={
              user?.role === 'customer' ||
              user?.role === 'distributor' ||
              user?.role === 'admin' ||
              user?.role === 'sales'
                ? '/inbox'
                : '/prijava'
            }
            css={[buttons.container, layout.navUserBtn]}
          >
            <MailIcon />
          </Link>
        </div>
      )}

      <div css={layout.navUserWrapper}>
        <button
          type="button"
          data-id="cartOpen"
          onClick={handleOpen}
          css={[buttons.container, layout.navUserBtn, layout.cartDesktop]}
        >
          <CartIcon />
          {products.length !== 0 && (
            <div css={layout.cartAmountWrapper}>
              <p css={layout.cartAmount}>
                {products.length > 99 ? '99+' : products.length}
              </p>
            </div>
          )}
        </button>
        {state.cartOpen && <CartPopup />}
      </div>
      <Link
        to="/kosarica"
        css={[buttons.container, layout.navUserBtn, layout.cartMobile]}
      >
        <CartIcon />
        {products.length !== 0 && (
          <div css={layout.cartAmountWrapper}>
            <p css={layout.cartAmount}>
              {products.length > 99 ? '99+' : products.length}
            </p>
          </div>
        )}
      </Link>
    </div>
  );

  function handleLogout() {
    dispatch(AuthThunks.logout());
  }
};
