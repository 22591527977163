import { css } from '@emotion/core';
import { CSSProperties } from 'react';

import { mqFrom } from './responsive.styles';
import { typescale } from './typescale.styles';
import { colors, unit } from './vars.styles';

const wrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  flex-direction: column;

  padding: ${unit * 4}px 0;

  ${mqFrom.Med} {
    flex-direction: row;
  }
`;

const pages = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  color: ${colors.dark};
  cursor: pointer;
`;

const number = css`
  ${typescale.tiny}

  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  display: flex;
`;

const numberActive = css`
  ${number}

  color: ${colors.bg};
  background-color: ${colors.primary};
`;

const chevronLeft = css`
  margin-right: 10px;
`;

const chevronRight = css`
  margin-left: 10px;
`;

const itemsWrapper = css`
  display: flex;
  align-items: center;
  padding-top: ${unit * 3}px;
  min-width: 150px;
  margin-left: 20px;

  ${mqFrom.Med} {
    padding-top: 0;
  }
`;

const chevronWrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${unit * 2}px;
`;

export const itemsPerPageSelectStyles = {
  container: (styles: CSSProperties) => ({
    ...styles,
    height: '32px',
    width: '63px',
  }),
  indicatorSeparator: (styles: CSSProperties) => ({
    ...styles,
    display: 'none',
  }),
  dropdownIndicator: (styles: CSSProperties) => ({
    ...styles,
    paddingRight: 16,
  }),
  control: (styles: CSSProperties) => ({
    ...styles,
    height: '32px',
    minHeight: '32px',
    border: `1px solid rgba(0, 0, 0, 0.2)`,
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 0.6)',
      cursor: 'pointer',
    },
  }),
  valueContainer: (styles: CSSProperties) => ({
    ...styles,
    height: '32px',
  }),
  option: (styles: CSSProperties) => ({
    ...styles,
    height: '32px',
  }),
};

export const pagination = {
  wrapper,
  pages,
  number,
  numberActive,
  chevronLeft,
  chevronRight,
  itemsWrapper,
  chevronWrapper,
};
