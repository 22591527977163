import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Distributor } from 'modules/distributors';
import { ClientRequest } from 'modules/inbox';
import React from 'react';

interface Props {
  distributor?: Distributor;
  currentRequest?: ClientRequest;
}

export const DistributorInfo: React.FC<Props> = ({
  distributor,
  currentRequest,
}) => {
  return (
    <View style={styles.wrapper}>
      <View style={styles.detailsRow}>
        <View>
          <View>
            <Text style={styles.distributorTitle}>{distributor?.name}</Text>
          </View>

          <Text style={styles.textSecondary}>
            #WBSHOP_{currentRequest?.clientRequestId}
          </Text>
        </View>
      </View>

      {distributor?.logoFull && (
        <Image src={distributor?.logoFull} style={styles.logo} />
      )}

      <View style={styles.detailsRow}>
        <View>
          <Text style={styles.textSecondary}>{distributor?.address}</Text>
          <Text style={styles.textSecondary}>
            {distributor?.postCode} {distributor?.city}, {distributor?.country}
          </Text>
        </View>

        {distributor?.email && (
          <View>
            <Text style={styles.text}>{distributor?.email}</Text>
            <Text style={styles.textSecondary}>EMAIL</Text>
          </View>
        )}
      </View>

      <View style={styles.detailsRow}>
        {distributor?.phone && (
          <View>
            <Text style={styles.text}>{distributor?.phone}</Text>
            <Text style={styles.textSecondary}>KONTAKT</Text>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    borderBottomColor: 'rgba(0,0,0,0.1)',
    borderBottomWidth: 0.5,
    marginBottom: 30,
  },

  detailsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: 'black',
    paddingBottom: 10,
  },
  distributorTitle: {
    fontWeight: 'bold',
  },
  textSecondary: {
    fontSize: 10,
    letterSpacing: 1,
    opacity: 0.4,
  },
  text: {
    fontSize: 10,
    letterSpacing: 1,
  },
  logo: {
    resizeMethod: 'auto',
    height: 35,
    maxWidth: 150,
    marginBottom: 5,
  },
});
