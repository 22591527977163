import { ActionUnion, createAction } from 'modules/redux-store';

import { CartProduct, ClientInformation, ClientSummary } from '../models';
import { CartTypes } from './types';

export const CartActions = {
  addError: (error?: string) => createAction(CartTypes.AddError, { error }),
  updateProducts: (products: CartProduct[]) =>
    createAction(CartTypes.UpdateProducts, { products }),
  addClientInformation: (client: ClientInformation) =>
    createAction(CartTypes.AddClientInfo, { client }),
  setClientRequestId: (id: string) =>
    createAction(CartTypes.SetRequestId, { id }),
  setClientSummary: (prices: ClientSummary) =>
    createAction(CartTypes.SetClientSummary, { prices }),
};

export type CartActions = ActionUnion<typeof CartActions>;
