import { Link } from 'gatsby';
import React from 'react';
import { auth } from 'styles';

export const AuthLogo: React.FC = () => (
  <Link to="/" css={auth.logo}>
    <img
      src={require('content/Wienerberger-new-logo.png')}
      alt="Wienerberger"
    />
  </Link>
);
