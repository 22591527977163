import { Link } from 'gatsby';
import Image from 'gatsby-image';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { ProductRequest } from 'modules/inbox';
import React from 'react';
import { inbox, spacers } from 'styles';
import { getProductRoute } from 'utils';

import { OfferState } from '../hooks';

interface Props {
  products: ProductRequest[];
  offerState: OfferState;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  handleProductOffersChange: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
}

export const InternalOfferTable: React.FC<Props> = ({
  products,
  offerState,
  handleChange,
  handleProductOffersChange,
}) => {
  return (
    <>
      <table css={inbox.productsTableDesktop}>
        <thead>
          <tr>
            <th></th>
            <th>
              <FormattedMessage id="cart.home-page.product.name" />
            </th>
            <th>
              <FormattedMessage id="cart.home-page.product.amount" />
            </th>
            <th>
              <FormattedMessage id="cart.home-page.product.rebate" />%
            </th>
            <th>
              <FormattedMessage id="cart.home-page.product.measure" />
            </th>
            <th>
              <FormattedMessage id="cart.popup.price" />
            </th>
          </tr>
        </thead>

        <tbody>
          {products.map((product, index) => (
            <tr key={index}>
              <td>
                <Image
                  fixed={{
                    src:
                      product.product.commercial.assets.mainImg ||
                      product.product.commercial.assets.otherImgs[0].imgUrl,
                    srcSet: '',
                    width: 48,
                    height: 48,
                  }}
                />
              </td>
              <td css={inbox.internalOfferTd}>
                <p>
                  <Link to={getProductRoute(product.product.commercial)}>
                    {product.product.commercial.localizedName}
                  </Link>
                </p>
                <p>{product.product.sku?.pcsPerPallet} kom/paleta</p>
              </td>
              <td>
                <input
                  type="number"
                  name="amount"
                  min="0"
                  value={offerState.productOffers[index]?.amount}
                  data-index={index}
                  onChange={handleProductOffersChange}
                  css={inbox.internalOfferInput}
                />
              </td>
              <td>{product.unit}</td>
              <td>
                <input
                  type="number"
                  name="rebate"
                  min="0"
                  value={offerState.productOffers[index]?.rebate}
                  data-index={index}
                  onChange={handleProductOffersChange}
                  css={inbox.internalOfferInput}
                />
              </td>
              <td>
                <input
                  type="number"
                  name="price"
                  min="0"
                  value={parseFloat(
                    String(offerState.productOffers[index]?.price?.toFixed(2)),
                  )}
                  data-index={index}
                  onChange={handleProductOffersChange}
                  css={inbox.internalOfferPriceInput}
                />
              </td>
            </tr>
          ))}

          <tr>
            <td>
              <span css={inbox.paleteImg} />
            </td>
            <td css={inbox.internalOfferTd}>
              <p>
                <FormattedMessage id="admin.answered-requests.palette-price" />
              </p>
              <p>5,77 €/kom</p>
            </td>
            <td>
              <input
                type="number"
                name="paleteAmount"
                min="0"
                value={offerState.paleteAmount}
                onChange={handleChange}
                css={inbox.internalOfferInput}
              />
            </td>
            <td>
              <FormattedMessage id="admin.answered-requests.palette" />
            </td>
            <td>
              <input
                type="number"
                name="paleteRebate"
                min="0"
                value={offerState.paleteRebate}
                onChange={handleChange}
                css={inbox.internalOfferInput}
              />
            </td>
            <td>
              <input
                type="number"
                name="paletePrice"
                min="0"
                value={offerState.paletePrice}
                onChange={handleChange}
                css={inbox.internalOfferPriceInput}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <div css={inbox.mobile}>
        <ul css={inbox.productsListMobile}>
          {products.map((item, index) => (
            <li key={index} css={inbox.productsListItemMobile}>
              <div css={inbox.productsListItemTitle}>
                <Image
                  fixed={{
                    src:
                      item.product.commercial.assets.mainImg ||
                      item.product.commercial.assets.otherImgs[0],
                    srcSet: '',
                    width: 48,
                    height: 48,
                  }}
                />

                <Link
                  to={getProductRoute(item.product.commercial)}
                  css={[spacers.left.medium]}
                >
                  {item.product.commercial.localizedName}
                </Link>
              </div>

              <div css={inbox.productsListItemInfo}>
                <div>
                  <input
                    type="number"
                    name="amount"
                    min="0"
                    value={offerState.productOffers[index]?.amount}
                    data-index={index}
                    onChange={handleProductOffersChange}
                    css={inbox.internalOfferInput}
                  />
                  <span>
                    <FormattedMessage id="cart.home-page.product.amount" />
                  </span>
                </div>

                <div>
                  <input
                    type="number"
                    name="rebate"
                    min="0"
                    value={offerState.productOffers[index]?.rebate}
                    data-index={index}
                    onChange={handleProductOffersChange}
                    css={inbox.internalOfferInput}
                  />
                  <span>
                    <FormattedMessage id="cart.home-page.product.rebate" />
                  </span>
                </div>

                <div>
                  <input
                    type="number"
                    name="price"
                    min="0"
                    value={offerState.productOffers[index]?.price}
                    data-index={index}
                    onChange={handleProductOffersChange}
                    css={inbox.internalOfferPriceInput}
                  />
                  <span>
                    <FormattedMessage id="cart.popup.price" />
                  </span>
                </div>
              </div>
            </li>
          ))}

          <li css={inbox.productsListItemMobile}>
            <div css={inbox.productsListItemTitle}>
              <span css={inbox.paleteImg} />

              <div css={[spacers.left.medium]}>
                <p>
                  <FormattedMessage id="admin.answered-requests.palette-price" />
                </p>
                <p>5,77 €/kom</p>
              </div>
            </div>

            <div css={inbox.productsListItemInfo}>
              <div>
                <input
                  type="number"
                  name="paleteAmount"
                  min="0"
                  value={offerState.paleteAmount}
                  onChange={handleChange}
                  css={inbox.internalOfferInput}
                />
                <span>
                  <FormattedMessage id="cart.home-page.product.amount" />
                </span>
              </div>
              <div>
                <input
                  type="number"
                  name="paleteRebate"
                  min="0"
                  value={offerState.paleteRebate}
                  onChange={handleChange}
                  css={inbox.internalOfferInput}
                />
                <span>
                  <FormattedMessage id="cart.home-page.product.rebate" />
                </span>
              </div>
              <div>
                <input
                  type="number"
                  name="paletePrice"
                  min="0"
                  value={offerState.paletePrice}
                  onChange={handleChange}
                  css={inbox.internalOfferPriceInput}
                />
                <span>
                  <FormattedMessage id="cart.popup.price" />
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};
