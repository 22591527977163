export const technicalPanels = [
  {
    id: 5,
    title: 'Koliko je jamstvo?',
    description:
      'Jamstvo za krovne prozore je 10 godina. Na Tondach crijep jamstvo je 33 godine. ',
  },
  {
    id: 6,
    title: 'Postavljate li vi krovne prozore?',
    description: 'Ne, mi ne postavljamo krovne prozore.',
  },
  {
    id: 7,
    title: 'Gdje mogu zatražiti izračun materijala?',
    description:
      'Izračun materijala možete saznati putem našeg kalkulatora ili putem forme koja se nalazi na sljedećoj poveznici za zid: <a href="https://www.wienerberger.hr/alati-i-preuzimanja/alati-za-zid/narucite-besplatni-izracun-materijala.html" target="_blank">Naručite besplatan izračun materijala (wienerberger.hr)</a> ili za krov: <a href="https://www.wienerberger.hr/alati-i-preuzimanja/alati-za-krov/narucite-besplatni-izracun-materijala.html" target="_blank">Naručite besplatan izračun materijala (wienerberger.hr)</a>',
  },
  {
    id: 8,
    title: 'Gdje mogu saznati tehničke podatke za pojedine artikle?',
    description:
      'Ovdje možete saznati tehničke podatke za većinu artikala. Morate ući u stranicu pojedinog proizvoda i ispod cijene su navedeni tehnički podaci. Ako želite malo opširnije opise i uputstva možete putem sljedeće poveznice: <a href="https://www.wienerberger.hr/proizvodi.html" target="_blank">Wienerberger proizvodi - inovativni građevinski materijali</a>. Također možete i preuzeti tehničke listove proizvoda: <a href="https://www.wienerberger.hr/alati-i-preuzimanja/preuzimanja/tehnicki-listovi.html" target="_blank">Tehnički listovi (wienerberger.hr)</a>',
  },
  {
    id: 9,
    title: 'Može li vaš crijep izdržati sve vremenske neprilike?',
    description:
      'Tondach glineni crijep sastoji se od visokokvalitetne gline koja je pečena na vrlo visokim temperaturama. To ga čini posebno stabilnim i otpornim na toplinu i smrzavanje kao i na prljavštinu  i kemikalije. Oplemenjena gornja  površina  osim dugotrajne  estetike pruža potpunu postojanost na UV zračenje, kiseline i kemikalije. Dokazani vijek njegovog trajanja je preko 100 godina.',
  },
  {
    id: 10,
    title:
      'Koga mogu kontaktirati vezano za gradnju s vašim proizvodima i ostala tehnička pitanja?',
    description:
      'Možete kontaktirati naše kolege, prema području gdje živite, a kontakti su na ovoj poveznici: <a href="https://www.wienerberger.hr/o-nama/kontakt/prodajna-podrucja.html" target="_blank">Prodajna područja (wienerberger.hr)</a>. Možete i putem forme: <a href="https://www.wienerberger.hr/o-nama/kontakt.html" target="_blank">Kontakt (wienerberger.hr)</a>',
  },
  {
    id: 11,
    title: 'Ulazi li u cijenu krovnog prozora opšav i toplinska izolacija?',
    description: 'Ne, opšav i toplinska izolacija kupuju se posebno.',
  },
  {
    id: 12,
    title: 'Od kojeg su materijala krovni prozori?',
    description:
      'Krovnu prozori su Proizvedeni od bora, impregnirani vakuumom i presvučeni akrilnim lakom.',
  },
  {
    id: 13,
    title: 'Koliko crijepa treba po metru kvadratnom?',
    description:
      'Na stranici svakog crijepa je navedeno kolika je potrošnja pojedinog crijepa po m2',
  },
  {
    id: 14,
    title: 'Šta znači engoba?',
    description:
      'Engoba je mješavina fino mljevene gline i kisika koja se prije pečenja nanosi ravnomjerno na površinu suhog crijepa i zajedno se s crijepom peče na visokim temperaturama, što osigurava trajnost nijanse jednaku trajnosti crijepa. Na ovaj način, bez imalo štetnih kemijskih dodataka, ostvaruje se širok spektar Nature Color boja. Gornjoj površini crijepa daje ekološki zaštitni sloj u različitim nijansama.',
  },
  {
    id: 15,
    title: 'Gdje mogu saznati minimalni nagib krova?',
    description:
      'Na stranici svakog crijepa navedeno je koliki je minimalni nagib krova.',
  },
  {
    id: 16,
    title:
      'Moram li sam/sama dodati dryfix extra ljepilo uz IZO Profi i Porotherm Profi opeku?',
    description:
      'Ne, automatski će vam dodati dryfix extra ljepilo koje dolazi gratis uz svu IZO Profi i Porotherm Profi opeku. Dodati će vam količinu ljepila koja vam je potrebna za količinu Profi opeke u Vašoj košarici.',
  },
  {
    id: 17,
    title: 'Koliko komada crijepa ima na jednoj paleti?',
    description:
      'Ovu informaciju možete također naći na stranici svakog crijepa pod tehničkim podacima (ispod cijene). Svaki crijep je druge dimenzije, pa je tako i broj crijepova na paleti drugačiji za svaki.',
  },
  {
    id: 18,
    title: 'Mogu li naručiti besplatni uzorak crijepa?',
    description:
      'Da, možete putem ove poveznice: <a href="https://www.wienerberger.hr/alati-i-preuzimanja/alati-za-krov/narucite-besplatni-uzorak-crijepa.html" target="_blank">Naručite besplatan uzorak crijepa (wienerberger.hr)</a>',
  },
  {
    id: 19,
    title:
      'Gdje mogu saznati više informacija o Terca opeci za fasade i zidove?',
    description:
      'Možete na sljedećoj poveznici: <a href="https://www.wienerberger.hr/informacije/uputstva-za-gradnju/fasada/terca-klinker-za-fasade-i-zidove.html" target="_blank">Terca klinker za fasade i zidove (wienerberger.hr)</a>',
  },
];
