export function localizeNavCategory(text: string) {
  switch (text) {
    case 'Roof':
      return 'Krov';
    case 'RoofAcc':
      return 'Krovni dodaci';
    case 'Roof Acc':
      return 'Krovni dodaci';
    case 'Wall':
      return 'Zid';
    case 'WallAcc':
      return 'Zidni dodaci';
    case 'Wall_Special_Nadvoji':
      return 'Zidni dodaci';
    case 'Facade':
      return 'Fasada';
    case 'RoofWindows':
      return 'Krovni prozori';
    case 'RoofWindowsAccessories':
      return 'Krovni prozori oprema';

    default:
      return text;
  }
}
