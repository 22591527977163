import firebase from 'firebase/app';
import { ActionUnion, createAction } from 'modules/redux-store';

import { User } from '../models';
import { AuthTypes } from './types';

export const AuthActions = {
  authStateChange: (isUserAnon?: boolean) =>
    createAction(AuthTypes.AuthStateChange, { isUserAnon }),
  loginSuccess: (user: User) => createAction(AuthTypes.LoginSuccess, { user }),
  loginError: (error?: firebase.FirebaseError | string) =>
    createAction(AuthTypes.LoginError, { error }),
  logout: () => createAction(AuthTypes.Logout),
};

export type AuthActions = ActionUnion<typeof AuthActions>;
