import { SerializedStyles } from '@emotion/core';
import React from 'react';

interface Props {
  className?: SerializedStyles;
}

export const ArrowRight: React.FC<Props> = ({ className }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    css={className}
  >
    <g>
      <path d="M8.5 16H23.5" stroke="black" strokeMiterlimit="10" />
      <path d="M18.5 11L23.5 16L18.5 21" stroke="black" strokeMiterlimit="10" />
    </g>
  </svg>
);
