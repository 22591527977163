import { Product } from 'models';

export function filterByModel(
  selectedModels: Record<string, boolean>,
  initialList: Product[],
) {
  const checkedModels = Object.entries(selectedModels).reduce(
    (acc: string[], [key, value]) => {
      if (value) return [...acc, key];
      return acc;
    },
    [],
  );

  const filteredModelsProducts = checkedModels.length
    ? initialList.filter((product) =>
        checkedModels.includes(product.commercial.model),
      )
    : initialList;

  filteredModelsProducts.sort((a, b) => {
    if (a.commercial.model === 'Krovni prozori') return -1;
    else if (b.commercial.model !== 'Krovni prozori') return 1;
    else return 1;
  });

  return filteredModelsProducts;
}
