import { navigate } from 'gatsby-link';

interface FilterState {
  products?: {
    [key: string]: boolean;
  };
  categories?: {
    [key: string]: boolean;
  };
  models?: {
    [key: string]: boolean;
  };
  piecesPerSqMeter?: {
    [key: string]: boolean;
  };
  colors?: {
    [key: string]: boolean;
  };
  minRoofPitch?: {
    [key: string]: boolean;
  };
  dimensions?: {
    [key: string]: boolean;
  };
  concepts?: {
    [key: string]: boolean;
  };
  wallWidths?: {
    [key: string]: boolean;
  };
  piecesPerMsq?: {
    [key: string]: boolean;
  };
  consumptions?: {
    [key: string]: boolean;
  };
}

export const setFitlerUrl = (filterState: FilterState, currentPage: number) => {
  let href = '?';

  if (filterState.categories) {
    const categories = Object.keys(filterState.categories);

    if (href.slice(-1) !== '?' && categories.length > 0) href = href + '&';

    const filteredCategories = categories.filter((key) => {
      if (filterState.categories) return filterState.categories[key];
      return;
    });

    filteredCategories.forEach((category: string, index: number) => {
      if (index === 0) href = href + `kategorija=${category}`;
      else href = href + `${category}`;
      if (index < filteredCategories.length - 1) href = href + `-`;
    });
  }

  if (filterState.products) {
    const products = Object.keys(filterState.products);

    if (href.slice(-1) !== '?' && products.length > 0) href = href + '&';

    const filteredProducts = products.filter((key) => {
      if (filterState.products) return filterState.products[key];
      return;
    });

    filteredProducts.forEach((product: string, index: number) => {
      if (index === 0) href = href + `proizvod=${product}`;
      else href = href + `${product}`;
      if (index < filteredProducts.length - 1) href = href + `-`;
    });
  }

  if (filterState.colors) {
    const colors = Object.keys(filterState.colors);

    if (href.slice(-1) !== '?' && colors.length > 0) href = href + '&';

    const filteredColors = colors.filter((key) => {
      if (filterState.colors) return filterState.colors[key];
      return;
    });

    filteredColors.forEach((color: string, index: number) => {
      if (index === 0) href = href + `boja=${color}`;
      else href = href + `${color}`;
      if (index < filteredColors.length - 1) href = href + `-`;
    });
  }

  if (filterState.minRoofPitch) {
    const minRoofPitch = Object.keys(filterState.minRoofPitch);

    if (href.slice(-1) !== '?' && minRoofPitch.length > 0) href = href + '&';

    const filteredMinRoofPitch = minRoofPitch.filter((key) => {
      if (filterState.minRoofPitch) return filterState.minRoofPitch[key];
      return;
    });

    filteredMinRoofPitch.forEach((pitch: string, index: number) => {
      if (index === 0) href = href + `nagib=${pitch}`;
      else href = href + `${pitch}`;
      if (index < filteredMinRoofPitch.length - 1) href = href + `-`;
    });
  }

  if (filterState.models) {
    const models = Object.keys(filterState.models);

    if (href.slice(-1) !== '?' && models.length > 0) href = href + '&';

    const filteredModels = models.filter((key) => {
      if (filterState.models) return filterState.models[key];
      return;
    });

    filteredModels.forEach((model: string, index: number) => {
      if (index === 0) href = href + `model=${model}`;
      else href = href + `${model}`;
      if (index < filteredModels.length - 1) href = href + `-`;
    });
  }

  if (filterState.piecesPerSqMeter) {
    const meters = Object.keys(filterState.piecesPerSqMeter);

    if (href.slice(-1) !== '?' && meters.length > 0) href = href + '&';

    const filteredMeters = meters.filter((key) => {
      if (filterState.piecesPerSqMeter)
        return filterState.piecesPerSqMeter[key];
      return;
    });

    filteredMeters.forEach((meter: string, index: number) => {
      if (index === 0) href = href + `m2=${meter}`;
      else href = href + `${meter}`;
      if (index < filteredMeters.length - 1) href = href + `-`;
    });
  }

  if (filterState.dimensions) {
    const dimensions = Object.keys(filterState.dimensions);

    if (href.slice(-1) !== '?' && dimensions.length > 0) href = href + '&';

    const filteredDimensions = dimensions.filter((key) => {
      if (filterState.dimensions) return filterState.dimensions[key];
      return;
    });

    filteredDimensions.forEach((dimension: string, index: number) => {
      if (index === 0) href = href + `dimenzija=${dimension}`;
      else href = href + `${dimension}`;
      if (index < filteredDimensions.length - 1) href = href + `-`;
    });
  }

  if (filterState.concepts) {
    const concepts = Object.keys(filterState.concepts);

    if (href.slice(-1) !== '?' && concepts.length > 0) href = href + '&';

    const filteredConcepts = concepts.filter((key) => {
      if (filterState.concepts) return filterState.concepts[key];
      return;
    });

    filteredConcepts.forEach((concept: string, index: number) => {
      if (index === 0) href = href + `koncept=${concept}`;
      else href = href + `${concept}`;
      if (index < filteredConcepts.length - 1) href = href + `-`;
    });
  }

  if (filterState.piecesPerMsq) {
    const meters = Object.keys(filterState.piecesPerMsq);

    if (href.slice(-1) !== '?' && meters.length > 0) href = href + '&';

    const filteredMeters = meters.filter((key) => {
      if (filterState.piecesPerMsq) return filterState.piecesPerMsq[key];
      return;
    });

    filteredMeters.forEach((meter: string, index: number) => {
      if (index === 0) href = href + `m2=${meter}`;
      else href = href + `${meter}`;
      if (index < filteredMeters.length - 1) href = href + `-`;
    });
  }

  if (filterState.wallWidths) {
    const widths = Object.keys(filterState.wallWidths);

    if (href.slice(-1) !== '?' && widths.length > 0) href = href + '&';

    const filteredWidths = widths.filter((key) => {
      if (filterState.wallWidths) return filterState.wallWidths[key];
      return;
    });

    filteredWidths.forEach((width: string, index: number) => {
      if (index === 0) href = href + `debljinaZida=${width}`;
      else href = href + `${width}`;
      if (index < filteredWidths.length - 1) href = href + `-`;
    });
  }

  if (filterState.consumptions) {
    const consumptions = Object.keys(filterState.consumptions);

    if (href.slice(-1) !== '?' && consumptions.length > 0) href = href + '&';

    const filteredConsumptions = consumptions.filter((key) => {
      if (filterState.consumptions) return filterState.consumptions[key];
      return;
    });

    filteredConsumptions.forEach((consumption: string, index: number) => {
      if (index === 0) href = href + `utrosak=${consumption}`;
      else href = href + `${consumption}`;
      if (index < filteredConsumptions.length - 1) href = href + `-`;
    });
  }

  if (currentPage !== 1) {
    if (href.slice(-1) !== '?') href = href + '&';

    href = href + `p=${currentPage}`;
  }

  navigate(href);
};
