enum types {
  AddError = 'cart/ADD_ERROR',
  UpdateProducts = 'cart/UPDATE_PRODUCTS',
  AddClientInfo = 'cart/ADD_CLIENT_INFO',
  SetRequestId = 'cart/SET_REQUEST_ID',
  SetClientSummary = 'cart/SET_CLIENT_SUMMARY',
}

export const CartTypes = {
  ...types,
};
