import { DistributorThunks } from 'modules/distributors/redux/thunks';
import { ApplicationState } from 'modules/redux-store';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AuthThunks } from '../redux';

export const AnonymousCartFlowProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ApplicationState) => state.auth);
  const { activeDistributors } = useSelector(
    (state: ApplicationState) => state.distributors,
  );
  const location = window.location.href;

  useEffect(() => {
    if (user?.isAnonymous && !location.includes('kosarica')) {
      dispatch(AuthThunks.logout());
    }
  }, [dispatch, location, user]);

  useEffect(() => {
    if (!activeDistributors.length)
      dispatch(DistributorThunks.getActiveDistributors());
  }, [activeDistributors, activeDistributors.length, dispatch]);

  return <>{children}</>;
};
