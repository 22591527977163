import { FiltersIcon, TimesIcon } from 'assets';
import { FormattedMessage } from 'gatsby-plugin-intl';
import React, { useEffect, useState } from 'react';
import { buttons, listing, navStyles, spacers } from 'styles';
import { WallListingState } from 'template/AllWallListing';
import { disableBodyScroll, handleShowFilterButton } from 'utils';

import { WallFilters } from './WallFilters';

interface Props {
  amount: number;
  categories: string[];
  models: string[];
  wallWidths: string[];
  piecesPerMsq: string[];
  filterState: WallListingState;
  handleChange: (name: string, value: boolean, section?: string) => void;
  clearFilters: VoidFunction;
}

export const MobileWallFilters: React.FC<Props> = ({
  amount,
  categories,
  models,
  wallWidths,
  piecesPerMsq,
  filterState,
  handleChange,
  clearFilters,
}) => {
  const [navState, setNavState] = useState(false);
  disableBodyScroll(navState);

  const [isFilterPicked, setIsFilterPicked] = useState(false);

  useEffect(() => {
    setIsFilterPicked(handleShowFilterButton(filterState));
  }, [filterState]);

  const handleClick = () => {
    clearFilters();
    setIsFilterPicked(false);
  };

  const isButtonHidden = isFilterPicked ? '' : buttons.hidden;
  return (
    <section css={listing.mobileHeader}>
      <div css={listing.mobileFilters}>
        <div>
          <h1 css={listing.categoryTitle}>Porotherm</h1>
          <p css={listing.categoryAmount}>
            <span>{amount}</span>{' '}
            <FormattedMessage id="products.category.count" />
          </p>
        </div>

        <button type="button" css={listing.filtersIcon} onClick={toggleFilters}>
          <FiltersIcon className={[spacers.right.small]} />
          <p>
            <FormattedMessage id="products.filter" />
          </p>
        </button>
      </div>

      <div css={navStyles.menu(navState)}>
        <div css={navStyles.menuWrapper(navState)}>
          <div css={navStyles.menuContent(navState)}>
            <div css={listing.filtersMenuHeader}>
              <h4>
                <FormattedMessage id="products.filter" />
              </h4>
              <button
                type="button"
                css={buttons.container}
                onClick={toggleFilters}
              >
                <TimesIcon />
              </button>
            </div>

            <div css={isButtonHidden}>
              <button
                type="button"
                css={[buttons.ghost.small, spacers.bottom.huge]}
                onClick={handleClick}
              >
                <FormattedMessage id="products.clear-filter" />
              </button>
            </div>
            <WallFilters
              categories={categories}
              models={models}
              wallWidths={wallWidths}
              piecesPerMsq={piecesPerMsq}
              handleChange={handleChange}
              filterState={filterState}
            />
          </div>
        </div>
      </div>
    </section>
  );

  function toggleFilters() {
    setNavState(!navState);
  }
};
