import { SerializedStyles } from '@emotion/core';
import React from 'react';

interface Props {
  className?: SerializedStyles | string[];
  stroke?: string;
}

export const DeleteIcon: React.FC<Props> = ({ className, stroke }) => {
  return (
    <svg
      css={className}
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.8125 5.9375V13.1562C2.8125 14.2958 3.73547 15.2188 4.875 15.2188H13.125C14.2645 15.2188 15.1875 14.2958 15.1875 13.1562V5.9375"
        stroke={stroke || 'black'}
        strokeMiterlimit="16"
      />
      <path
        d="M0.75 3.875L17.25 3.875"
        stroke={stroke || 'black'}
        strokeMiterlimit="10"
      />
      <mask id="path-3-inside-1" fill="white">
        <path d="M5.90625 3.875V0.78125H12.0938V3.875" />
      </mask>
      <path
        d="M4.90625 3.875C4.90625 4.42728 5.35397 4.875 5.90625 4.875C6.45853 4.875 6.90625 4.42728 6.90625 3.875H4.90625ZM5.90625 0.78125V-0.21875C5.35397 -0.21875 4.90625 0.228965 4.90625 0.78125L5.90625 0.78125ZM12.0938 0.78125H13.0938C13.0938 0.228965 12.646 -0.21875 12.0938 -0.21875V0.78125ZM11.0938 3.875C11.0938 4.42728 11.5415 4.875 12.0938 4.875C12.646 4.875 13.0938 4.42728 13.0938 3.875H11.0938ZM6.90625 3.875V0.78125H4.90625V3.875H6.90625ZM5.90625 1.78125H12.0938V-0.21875H5.90625V1.78125ZM11.0938 0.78125V3.875H13.0938V0.78125H11.0938Z"
        fill={stroke || 'black'}
        mask="url(#path-3-inside-1)"
      />
      <path
        d="M9 6.96875V12.125"
        stroke={stroke || 'black'}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M12.0938 6.96875V12.125"
        stroke={stroke || 'black'}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M5.90625 6.96875V12.125"
        stroke={stroke || 'black'}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  );
};
