import { SerializedStyles } from '@emotion/core';
import React from 'react';

interface Props {
  className?: SerializedStyles | string[];
}

export const NotepadIcon: React.FC<Props> = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    css={className}
  >
    <path
      d="M12.5 1.5H13.5C14.052 1.5 14.5 1.948 14.5 2.5V14.5C14.5 15.052 14.052 15.5 13.5 15.5H2.5C1.948 15.5 1.5 15.052 1.5 14.5V2.5C1.5 1.948 1.948 1.5 2.5 1.5H3.5"
      stroke="#B30000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 0.5H5.5V2.5H10.5V0.5Z"
      stroke="#B30000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 6.5H11.5"
      stroke="#B30000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 10.5H11.5"
      stroke="#B30000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
