import { useLocation } from '@reach/router';
import { WorldIcon } from 'assets';
import { Link } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';
import { footerStyles, layout } from 'styles';
import { isFullPageRoute } from 'utils';

export const Footer: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <footer css={isFullPageRoute(pathname) ? layout.footerNone : layout.footer}>
      <div css={[layout.wrapper, footerStyles.root]}>
        <div css={footerStyles.section}>
          <p css={footerStyles.footerWnbgWorld}>
            <strong>we are wienerberger</strong>
            <WorldIcon />
          </p>

          <div css={footerStyles.social}>
            <a
              href="https://www.facebook.com/wienerbergerhrvatska/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require('content/facebook-icon.svg')} alt="Facebook" />
            </a>
            <a
              href="https://www.instagram.com/wienerberger_croatia/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require('content/instagram-icon.svg')}
                alt="Instagram"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UC1ZhsEKNbP3QO2eaIkr6fxQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require('content/youtube-icon.svg')} alt="Youtube" />
            </a>
            <a
              href="https://www.linkedin.com/company/wienerberger-hrvatska/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require('content/linkedin-icon.svg')} alt="Linkedin" />
            </a>
          </div>
        </div>

        <div css={footerStyles.bottomSection}>
          <div css={[footerStyles.section, footerStyles.mobileVersion]}>
            <div css={footerStyles.products}>
              <img
                src={require('content/logo-porotherm.svg')}
                alt="Porotherm"
              />
              <img src={require('content/logo-terca.svg')} alt="Terca" />
              {/* <img src={require('content/argeton.png')} alt="Argeton" /> */}
              <img src={require('content/logo-tondach.svg')} alt="Tondach" />
            </div>

            <div css={footerStyles.links}>
              <Link to="/pravila-privatnosti">
                <FormattedMessage id="navigation.footer.privacy-policy" />
              </Link>
              <Link to="/uvjeti-koristenja">
                <FormattedMessage id="navigation.footer.terms-conditions" />
              </Link>
              <Link to="/kolacici">
                <FormattedMessage id="navigation.footer.cookies" />
              </Link>
              <Link to="/impresum">
                <FormattedMessage id="shared.impressum" />
              </Link>
            </div>
          </div>

          <span css={footerStyles.copy}>
            &copy; <FormattedMessage id="shared.copyright" />
          </span>
        </div>
      </div>
    </footer>
  );
};
