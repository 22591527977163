import { Link } from 'gatsby';
import Image from 'gatsby-image';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { InternalOfferModel } from 'modules/inbox';
import React from 'react';
import { inbox, spacers } from 'styles';
import { formatPrice, getProductRoute } from 'utils';

interface Props {
  offer: InternalOfferModel;
}

export const InboxOfferTable: React.FC<Props> = ({ offer, children }) => {
  return (
    <div css={inbox.productsAccordionDesktop}>
      <table css={inbox.productsTableDesktop}>
        <thead>
          <tr>
            <th></th>
            <th>
              <FormattedMessage id="cart.home-page.product.name" />
            </th>
            <th>
              <FormattedMessage id="cart.home-page.product.amount" />
            </th>
            <th>
              <FormattedMessage id="cart.home-page.product.rebate" />
            </th>
            <th>
              <FormattedMessage id="cart.home-page.product.measure" />
            </th>
            <th>
              <FormattedMessage id="cart.home-page.product.recommended-price" />
            </th>
          </tr>
        </thead>

        <tbody>
          {offer.productOffers.map((item, index) => (
            <tr key={index}>
              <td>
                <Image
                  fixed={{
                    src:
                      item.product.commercial.assets.mainImg ||
                      item.product.commercial.assets.otherImgs[0],
                    srcSet: '',
                    width: 48,
                    height: 48,
                  }}
                />
              </td>
              <td>
                <Link to={getProductRoute(item.product.commercial)}>
                  {item.product.commercial.localizedName}
                </Link>
              </td>
              <td>{item.amount}</td>
              <td>{item.rebate} %</td>
              <td>{item.unit}</td>
              <td>{item.price?.toFixed(2)} €</td>
            </tr>
          ))}

          <tr>
            <td>
              <span css={inbox.paleteImg} />
            </td>
            <td css={inbox.internalOfferTd}>
              <p>
                <FormattedMessage id="admin.answered-requests.palette-price" />
              </p>
              <p>5,77 €/kom</p>
            </td>
            <td>{offer.palete?.amount}</td>
            <td>
              <FormattedMessage id="admin.answered-requests.palette" />
            </td>
            <td>{offer.palete?.rebate}</td>
            <td>{formatPrice(offer.palete?.price)}</td>
          </tr>
        </tbody>
      </table>

      <div css={inbox.offerSummary}>
        <div css={inbox.summaryRow}>
          <p>
            <FormattedMessage id="admin.answered-requests.price-without-vat-and-rebate" />
          </p>
          <p>{formatPrice(offer.summary?.totalWithoutTax)} €</p>
        </div>

        <div css={inbox.summaryRow}>
          <p>
            <FormattedMessage id="cart.home-page.product.rebate" />
          </p>
          <p>{formatPrice(offer.summary?.rebate)} €</p>
        </div>

        <div css={inbox.summaryRow}>
          <p>
            <FormattedMessage id="cart.home-page.product.shipping" />
          </p>
          <p>{formatPrice(offer.shippingPrice)} €</p>
        </div>

        <div css={inbox.summaryRow}>
          <p>PDV</p>
          <p>{formatPrice(offer.summary?.tax)} €</p>
        </div>
      </div>

      <div css={inbox.summaryRowTotal}>
        <p>
          <span>
            <FormattedMessage id="shared.total" />
          </span>{' '}
          (PDV uračunat)
        </p>
        <p>{formatPrice(offer.summary?.total)} €</p>
      </div>

      <div css={[inbox.summaryRemark, spacers.left.xLarge]}>
        <span css={[spacers.bottom.small]}>
          <FormattedMessage id="shared.remark" />
        </span>
        <p>{offer.distributorRemark}</p>
      </div>

      {children}
    </div>
  );
};
