import { ErrorMessage } from '@hookform/error-message';
import { TooltipIcon } from 'assets';
import { Link } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext, ValidationRules } from 'react-hook-form';
import { fields, inputs } from 'styles';

interface Props {
  name: string;
  label: string;
  size?: 'xsmall' | 'small' | 'normal';
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  tooltip?: string;
  validationObject?: ValidationRules;
  forgotPassword?: boolean;
  showPasswordScore?: boolean;
}

export const PasswordInput: React.FC<Props> = ({
  name,
  label,
  size = window.innerWidth > 584 ? 'normal' : 'small',
  value,
  placeholder,
  disabled,
  tooltip,
  validationObject,
  forgotPassword,
  showPasswordScore,
}) => {
  const { register, errors, trigger, watch } = useFormContext();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [passwordScore, setPasswordScore] = useState(0);

  const passwordValue = watch(name);

  const calculatePasswordScore = useCallback((passwordValue: string) => {
    let score = 0;
    if (!passwordValue) {
      setPasswordScore(score);
      return;
    }

    if (passwordValue.length >= 8) score++;
    if (/[a-z]/.test(passwordValue)) score++;
    if (/[A-Z]/.test(passwordValue)) score++;
    if (/[0-9]/.test(passwordValue)) score++;
    if (/[!@/?=#"$'%^&*]/.test(passwordValue)) score++;

    setPasswordScore(score);
  }, []);

  useEffect(() => {
    calculatePasswordScore(passwordValue);
  }, [passwordValue, calculatePasswordScore]);

  const delayDebounceFnPW = useRef<NodeJS.Timeout | number | null>();

  return (
    <div css={fields.field}>
      <label
        css={errors && errors[name] ? fields.labelError : fields.label}
        htmlFor={name}
      >
        {label}
      </label>

      <div css={inputs.passwordWrapper}>
        <input
          type="password"
          id={name}
          name={name}
          value={value}
          ref={validationObject ? register(validationObject) : register}
          css={
            errors && errors[name]
              ? inputs.inputError[size || 'normal']
              : inputs.input[size || 'normal']
          }
          placeholder={placeholder}
          disabled={disabled}
          onChange={handleInputChange}
        />

        {showPasswordScore && (
          <div css={inputs.progressBar}>
            <span
              css={
                passwordScore > 0
                  ? inputs.progressBarStepOn
                  : inputs.progressBarStepOff
              }
            />
            <span
              css={
                passwordScore > 1
                  ? inputs.progressBarStepOn
                  : inputs.progressBarStepOff
              }
            />
            <span
              css={
                passwordScore > 2
                  ? inputs.progressBarStepOn
                  : inputs.progressBarStepOff
              }
            />
            <span
              css={
                passwordScore > 3
                  ? inputs.progressBarStepOn
                  : inputs.progressBarStepOff
              }
            />
            <span
              css={
                passwordScore > 4
                  ? inputs.progressBarStepOn
                  : inputs.progressBarStepOff
              }
            />
          </div>
        )}
      </div>

      {/* {hidden ? (
        <PasswordShow
          className={inputs.passwordEye}
          onClick={togglePasswordVisibility}
        />
      ) : (
        <PasswordHide
          className={inputs.passwordEye}
          onClick={togglePasswordVisibility}
        />
      )} */}

      {tooltip && (
        <TooltipIcon
          className={inputs.tooltipIcon}
          onMouseEnter={handleTooltipEnter}
          onMouseLeave={handleTooltipLeave}
        />
      )}

      {tooltipOpen && (
        <div css={inputs.tooltipBox}>
          <p>{tooltip}</p>
        </div>
      )}

      <ErrorMessage
        errors={errors}
        name={name}
        as={<p css={fields.errorMsg} />}
      >
        {({
          messages,
        }: {
          messages: Array<{ type: string; message: string }>;
        }) =>
          messages &&
          Object.entries(messages).map(([type, message]) => (
            <p key={type}>{message}</p>
          ))
        }
      </ErrorMessage>

      {forgotPassword && (
        <Link css={inputs.forgotPassword} to="/zaboravljena-lozinka">
          <FormattedMessage id="forgotten-password.title" />
        </Link>
      )}
    </div>
  );

  function handleInputChange() {
    clearTimeout(delayDebounceFnPW.current as number);

    if (errors[name]) {
      trigger(name);
    }

    delayDebounceFnPW.current = setTimeout(() => {
      trigger(name);
    }, 1200);
  }

  function handleTooltipEnter() {
    setTooltipOpen(true);
  }

  function handleTooltipLeave() {
    setTooltipOpen(false);
  }
};
