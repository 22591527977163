import { Font, StyleSheet, Text, View } from '@react-pdf/renderer';
import font from 'assets/fonts/DMSans-Regular.ttf';
import { InternalOfferModel } from 'modules/inbox';
import React from 'react';
import { formatPrice } from 'utils';

Font.register({
  family: 'DMSans',
  src: font,
});

interface Props {
  offer: InternalOfferModel;
}

export const Offer: React.FC<Props> = ({ offer }) => {
  return (
    <View style={styles.font}>
      <View style={styles.container}>
        <View style={styles.headerContent}>
          <View style={styles.textWrapper}>
            <Text style={styles.smallText}>Naziv proizvoda</Text>
          </View>
          <View style={styles.textWrapper}>
            <Text style={[styles.smallText, styles.textCenter]}>Količina</Text>
          </View>
          <View style={styles.textWrapper}>
            <Text style={[styles.smallText, styles.textCenter]}>Mjera</Text>
          </View>
          <View style={styles.textWrapper}>
            <Text style={[styles.smallText, styles.textCenter]}>Rabat</Text>
          </View>
          <View style={styles.textWrapper}>
            <Text style={[styles.smallText, styles.textCenter]}>
              Preporučena cijena
            </Text>
          </View>
        </View>

        {offer.productOffers.map((item, index) => (
          <View style={styles.content} key={index}>
            <View style={styles.textWrapper}>
              <Text style={styles.text}>
                {item.product.commercial.localizedName}
              </Text>
            </View>
            <View style={[styles.textWrapper, styles.textCenter]}>
              <Text style={[styles.text, styles.brighterText]}>
                {item.amount}
              </Text>
            </View>
            <View style={[styles.textWrapper, styles.textCenter]}>
              <Text style={[styles.text, styles.brighterText]}>
                {item.unit}
              </Text>
            </View>
            <View style={[styles.textWrapper, styles.textCenter]}>
              <Text style={[styles.text, styles.brighterText]}>
                {item.rebate} %
              </Text>
            </View>
            <View style={[styles.textWrapper, styles.textCenter]}>
              <Text style={styles.text}>{item.price?.toFixed(2)} €</Text>
            </View>
          </View>
        ))}

        <View style={styles.content}>
          <View style={styles.textWrapper}>
            <Text style={styles.text}>Akontacija za paletu</Text>
            <Text style={styles.text}>5,77 €/kom</Text>
          </View>
          <View style={[styles.textWrapper, styles.textCenter]}>
            <Text style={[styles.text, styles.brighterText]}>
              {offer.palete?.amount}
            </Text>
          </View>
          <View style={[styles.textWrapper, styles.textCenter]}>
            <Text style={[styles.text, styles.brighterText]}>paleta</Text>
          </View>
          <View style={[styles.textWrapper, styles.textCenter]}>
            <Text style={[styles.text, styles.brighterText]}>
              {offer.palete?.rebate} %
            </Text>
          </View>
          <View style={[styles.textWrapper, styles.textCenter]}>
            <Text style={styles.text}>
              {formatPrice(offer.palete?.price)} €
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.offerSummary}>
        <View style={styles.summaryRow}>
          <Text style={styles.text}>Ukupno (bez PDV i rabata)</Text>
          <Text style={styles.text}>
            {formatPrice(offer.summary?.totalWithoutTax)} €
          </Text>
        </View>

        <View style={styles.summaryRow}>
          <Text style={styles.text}>Rabat</Text>
          <Text style={styles.text}>
            {formatPrice(offer.summary?.rebate)} €
          </Text>
        </View>

        <View style={styles.summaryRow}>
          <Text style={styles.text}>Dostava</Text>
          <Text style={styles.text}>{formatPrice(offer.shippingPrice)} €</Text>
        </View>

        <View style={styles.summaryRow}>
          <Text style={styles.text}>PDV</Text>
          <Text style={styles.text}>{formatPrice(offer.summary?.tax)} €</Text>
        </View>
      </View>

      <View style={styles.summaryBorder} />

      <View style={styles.summaryRow}>
        <Text style={styles.text}>UKUPNO (PDV uračunat)</Text>
        <Text style={styles.text}>{formatPrice(offer.summary?.total)} €</Text>
      </View>

      <View style={styles.summaryRow}>
        <Text style={styles.text}>Napomena</Text>
        <Text style={styles.text}>{offer.distributorRemark}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  font: {
    fontFamily: 'DMSans',
  },
  text: {
    fontSize: 10,
    letterSpacing: 1,
  },
  smallText: {
    fontSize: 7,
    letterSpacing: 1,
  },
  textWrapper: {
    flex: 1,
    alignSelf: 'stretch',
    paddingHorizontal: 5,
    marginTop: 5,
    marginBottom: 10,
  },
  headerContent: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    borderBottomColor: 'rgb(0, 0, 0, 0.1)',
    borderBottomWidth: 0.5,
    marginTop: 5,
    marginBottom: 10,
    height: 15,
  },
  textCenter: {
    textAlign: 'center',
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    flex: 1,
    alignSelf: 'stretch',
    flexDirection: 'row',
    borderBottomColor: 'rgb(0, 0, 0, 0.1)',
    borderBottomWidth: 0.5,
    height: 90,
  },
  offerSummary: {
    marginBottom: 10,
  },
  summaryBorder: {
    borderBottomColor: 'rgba(0,0,0,0.1)',
    borderBottomWidth: 0.5,
    marginLeft: 200,
    width: 300,
  },
  summaryRow: {
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: 5,
    paddingLeft: 200,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  brighterText: {
    opacity: 0.5,
  },
});
