import { ErrorMessage } from '@hookform/error-message';
import { PasswordCheckmark, PasswordHide, PasswordShow } from 'assets';
import { useIntl } from 'gatsby-plugin-intl';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { fields, inputs } from 'styles';

interface Props {
  name: string;
  label: string;
  followName: string;
  size?: 'xsmall' | 'small' | 'normal';
  placeholder?: string;
  disabled?: boolean;
}

export const PasswordRepeatInput: React.FC<Props> = ({
  name,
  label,
  size = window.innerWidth > 584 ? 'normal' : 'small',
  followName,
  placeholder,
  disabled,
}) => {
  const { formatMessage } = useIntl();
  const { register, errors, watch, formState, trigger } = useFormContext();
  const [hidden, setHidden] = useState(true);

  const isSameValue =
    formState.touched[followName] &&
    !errors[followName] &&
    watch(followName) === watch(name);

  return (
    <div css={fields.field}>
      <label
        css={errors && errors[name] ? fields.labelError : fields.label}
        htmlFor={name}
      >
        {label}
      </label>

      <PasswordCheckmark
        className={inputs.passwordCheck}
        isValid={isSameValue}
      />

      <input
        type={hidden ? 'password' : 'text'}
        id={name}
        name={name}
        ref={register({
          validate: () => {
            return (
              isSameValue ||
              formatMessage({ id: 'password.validation.not-the-same' })
            );
          },
        })}
        css={inputs.inputRepeatPw[size || 'normal']}
        placeholder={placeholder}
        onChange={handleInputChange}
        disabled={disabled}
      />

      {hidden ? (
        <PasswordShow
          className={inputs.passwordEye}
          onClick={togglePasswordVisibility}
        />
      ) : (
        <PasswordHide
          className={inputs.passwordEye}
          onClick={togglePasswordVisibility}
        />
      )}

      <ErrorMessage
        errors={errors}
        name={name}
        as={<p css={fields.errorMsg} />}
      >
        {({
          messages,
        }: {
          messages: Array<{ type: string; message: string }>;
        }) =>
          messages &&
          Object.entries(messages).map(([type, message]) => (
            <p key={type}>{message}</p>
          ))
        }
      </ErrorMessage>
    </div>
  );

  function togglePasswordVisibility() {
    setHidden(!hidden);
  }

  function handleInputChange() {
    if (errors[name]) {
      trigger(name);
    }
  }
};
