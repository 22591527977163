import { authenticationReducer } from 'modules/authentication';
import { cartReducer } from 'modules/cart';
import { copyReducer } from 'modules/copy';
import { distributorReducer } from 'modules/distributors';
import { firestoreListenerReducer } from 'modules/firebase';
import { requestsReducer } from 'modules/inbox';
import { mapReducer } from 'modules/map';
import { productsReducer } from 'modules/products';
import { searchReducer } from 'modules/search';
import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  createStore as reduxCreateStore,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk, { ThunkDispatch } from 'redux-thunk';

import { ApplicationState } from '../models';

const composeEnhancers = composeWithDevTools({});

const setPersistorConfig = (
  key: string,
  whitelist?: string[],
  blacklist?: string[],
) => ({
  key,
  storage,
  whitelist,
  blacklist,
});

const persistedRootReducer = {
  auth: authenticationReducer,
  map: mapReducer,
  clientRequests: requestsReducer,
  listeners: firestoreListenerReducer,
  cart: persistReducer(
    setPersistorConfig('cart', [
      'products',
      'clientInfo',
      'clientSummary',
      'requestId',
    ]),
    cartReducer,
  ),
  products: persistReducer(
    setPersistorConfig('products', ['lastViewedProducts']),
    productsReducer,
  ),
  search: searchReducer,
  distributors: distributorReducer,
  copy: persistReducer(setPersistorConfig('showCopy'), copyReducer),
};

const createStore = () => {
  const store = reduxCreateStore(
    combineReducers(persistedRootReducer),
    {},
    composeEnhancers(applyMiddleware(thunk)),
  );

  const persistor = persistStore(store);
  return { store, persistor };
};

export default createStore;
export type AppDispatch = ThunkDispatch<ApplicationState, unknown, AnyAction>;
