import { SerializedStyles } from '@emotion/core';
import React from 'react';

interface Props {
  className?: SerializedStyles | string[];
}

export const InboxTabsIcon: React.FC<Props> = ({ className }) => (
  <svg
    css={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.25 2.5H18.75"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1.25 10H10"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1.25 17.5H18.75"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
