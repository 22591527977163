import { Document, Page, StyleSheet } from '@react-pdf/renderer';
import { CartProduct, ClientInformation } from 'modules/cart';
import React from 'react';

import { ClientInfo } from './ClientInfo';
import { Request } from './Request';

interface Props {
  clientInfo: ClientInformation;
  products: CartProduct[];
}

export const RequestPdf: React.FC<Props> = ({ clientInfo, products }) => {
  return (
    <Document>
      <Page style={styles.body}>
        <ClientInfo clientInformation={clientInfo} />
        <Request products={products} />
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  body: {
    paddingTop: 45,
    paddingBottom: 75,
    paddingHorizontal: 50,
  },
});
