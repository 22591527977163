export function isFullPageRoute(pathname: string) {
  switch (pathname) {
    case '/prijava':
    case '/prijava/':
    case '/registracija':
    case '/registracija/':
    case '/kosarica/prijava':
    case '/kosarica/prijava/':
    case '/zaboravljena-lozinka':
    case '/zaboravljena-lozinka/':
    case '/auth-check':
    case '/auth-check/':
      return true;

    default:
      return false;
  }
}
