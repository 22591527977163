import { Distributor } from 'modules/distributors';
import { ClientRequest, MessageLocation, RequestsThunks } from 'modules/inbox';
import { AppDispatch, ApplicationState } from 'modules/redux-store';
import { useEffect, useState } from 'react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isMessageLocationType } from 'utils';

import { SuccessToast } from '../components';

export const useInbox = (userId: string, distributors: Distributor[]) => {
  const dispatch = useDispatch<AppDispatch>();
  const { ownRequests, requestsAreChanging, error } = useSelector(
    (state: ApplicationState) => state.clientRequests,
  );
  const [currentRequest, setCurrentRequest] =
    useState<
      ClientRequest & {
        index: number;
        distributor: Distributor | undefined;
      }
    >();
  const [activeMailCategory, setActiveMailCategory] =
    useState<MessageLocation>('inbox');
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    dispatch(RequestsThunks.getOwnRequests(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (currentRequest && currentRequest.id && !currentRequest.clientRead) {
      dispatch(RequestsThunks.setIsRead(currentRequest.id, 'clientRead'));
    }
  }, [currentRequest, dispatch]);

  useEffect(() => {
    resetCurrentRequest();
  }, [activeMailCategory]);

  const requestsWithDistributor = ownRequests.map((request) => ({
    ...request,
    distributor: distributors.find((dist) => dist.id === request.distributorId),
  }));

  const mailCategoryRequests = requestsWithDistributor.filter(
    (request) => request.clientMsgLocation === activeMailCategory,
  );

  const filteredRequestsWithOffers = (
    searchText.length >= 2
      ? mailCategoryRequests
          .filter((request) =>
            request.distributor?.name
              .toLowerCase()
              .includes(searchText.toLowerCase()),
          )
          .filter((request) => request.isAnswered)
          .map((request, index) => ({ ...request, index }))
      : mailCategoryRequests
          .filter((request) => request.isAnswered)
          .map((request, index) => ({
            ...request,
            index,
          }))
  ).sort((a, b) => b.date - a.date);

  const allClientRequests = (
    searchText.length >= 2
      ? requestsWithDistributor
          .filter((request) =>
            request.distributor?.name
              .toLowerCase()
              .includes(searchText.toLowerCase()),
          )
          .map((request, index) => ({ ...request, index }))
      : requestsWithDistributor.map((request, index) => ({
          ...request,
          index,
        }))
  ).sort((a, b) => b.date - a.date);

  function handleRequestMailClick(
    message: ClientRequest & {
      index: number;
      distributor: Distributor | undefined;
    },
  ) {
    setCurrentRequest(message);
  }

  function handleMailCategoryChange(
    event: React.MouseEvent<HTMLButtonElement>,
  ) {
    const { category } = event.currentTarget.dataset;
    if (!category || !isMessageLocationType(category)) return;

    setActiveMailCategory(category);
  }

  function handleRequestForwardClick() {
    if (!currentRequest) return;

    const currentRequestGroup =
      activeMailCategory === 'inbox' ||
      activeMailCategory === 'archive' ||
      activeMailCategory === 'trash'
        ? filteredRequestsWithOffers
        : allClientRequests;

    const currentIndex = currentRequest.index;
    const newMessage = currentRequestGroup.find(
      (request) => request.index === currentIndex + 1,
    );
    if (!newMessage) return;

    setCurrentRequest(newMessage);
  }

  function handleRequestBackwardClick() {
    if (!currentRequest) return;

    const currentRequestGroup =
      activeMailCategory === 'inbox' ||
      activeMailCategory === 'archive' ||
      activeMailCategory === 'trash'
        ? filteredRequestsWithOffers
        : allClientRequests;

    const currentIndex = currentRequest.index;
    const newMessage = currentRequestGroup.find(
      (request) => request.index === currentIndex - 1,
    );
    if (!newMessage) return;

    setCurrentRequest(newMessage);
  }

  function moveRequestToArchive() {
    if (!currentRequest) return;

    dispatch(RequestsThunks.moveToArchive(currentRequest.id || '', 'customer'));
    setCurrentRequest(undefined);
    toast.success(
      <SuccessToast
        title=" "
        text="Uspješno ste premjestili zahtjev u arhivu"
      />,
    );
  }

  function moveRequestToTrash() {
    if (!currentRequest) return;

    dispatch(RequestsThunks.moveToTrash(currentRequest.id || '', 'customer'));
    setCurrentRequest(undefined);

    toast.success(
      <SuccessToast
        title=" "
        text="Uspješno ste premjestili zahtjev u smeće"
      />,
    );
  }

  function resetCurrentRequest() {
    setCurrentRequest(undefined);
  }

  return {
    error,
    filteredRequestsWithOffers,
    allClientRequests,
    requestsAreChanging,
    currentRequest,
    activeMailCategory,
    searchText,
    setSearchText,
    handleRequestMailClick,
    handleMailCategoryChange,
    handleRequestForwardClick,
    handleRequestBackwardClick,
    moveRequestToArchive,
    moveRequestToTrash,
    resetCurrentRequest,
  };
};
