import { SerializedStyles } from '@emotion/core';
import React from 'react';

interface Props {
  className?: SerializedStyles;
}

export const FacebookIcon: React.FC<Props> = ({ className }) => (
  <svg
    css={className}
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M18.5 9C18.5 4.02943 14.4706 0 9.5 0C4.52943 0 0.5 4.02943 0.5 9C0.5 13.4922 3.79117 17.2155 8.09375 17.8907V11.6016H5.80859V9H8.09375V7.01719C8.09375 4.76156 9.43739 3.51562 11.4932 3.51562C12.4779 3.51562 13.5078 3.69141 13.5078 3.69141V5.90625H12.3729C11.2549 5.90625 10.9062 6.60001 10.9062 7.31174V9H13.4023L13.0033 11.6016H10.9062V17.8907C15.2088 17.2155 18.5 13.4922 18.5 9Z"
        fill="#1877F2"
      />
      <path
        d="M13.0033 11.6016L13.4023 9H10.9062V7.31174C10.9062 6.60001 11.2549 5.90625 12.3729 5.90625H13.5078V3.69141C13.5078 3.69141 12.4779 3.51562 11.4932 3.51562C9.43739 3.51562 8.09375 4.76156 8.09375 7.01719V9H5.80859V11.6016H8.09375V17.8907C8.55196 17.9626 9.02159 18 9.5 18C9.97841 18 10.448 17.9626 10.9062 17.8907V11.6016H13.0033Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="18" height="18" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
