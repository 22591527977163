import { ChevronDown } from 'assets';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { CommercialProduct, SkuProduct } from 'models';
import React, { lazy, Suspense } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { listing, product, spacers } from 'styles';
import { exists } from 'utils';

const RoofCalculators = lazy(() => import('./RoofCalculators'));
const WallCalculators = lazy(() => import('./WallCalculators'));

interface Props {
  commercial: CommercialProduct;
  sku: SkuProduct;
}

export const MobileDetailsAccordion: React.FC<Props> = ({
  commercial,
  sku,
}) => {
  const isRoof = commercial.navigationCategories.code === 'Roof';
  const isWall = commercial.navigationCategories.code === 'Wall';

  return (
    <div css={product.mobileTechnicalDetails}>
      <Accordion allowMultipleExpanded allowZeroExpanded>
        {sku && (
          <AccordionItem css={listing.accordionItem}>
            <AccordionItemHeading>
              <AccordionItemButton css={listing.accordionTitle}>
                <p css={[spacers.left.xLarge]}>
                  <FormattedMessage id="products.category.characteristics" />
                </p>
                <ChevronDown className={[spacers.right.medium]} />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div css={product.characteristicsTable}>
                {sku.coveringPattern && (
                  <p>
                    <span>
                      <FormattedMessage id="products.mobile-accordion.covering-method" />
                    </span>
                    <span>{sku.coveringPattern}</span>
                  </p>
                )}
                {exists(sku.salesRelation.roofPitchStraightBondWelded) && (
                  <p>
                    <span>
                      <FormattedMessage id="products.mobile-accordion.roof-foil" />
                    </span>
                    <span>
                      {sku.salesRelation.roofPitchStraightBondWelded}
                      {'°'}
                    </span>
                  </p>
                )}
                {exists(sku.salesRelation.roofPitchStraightBondFleecePremium)}
                {sku.dimensions &&
                  (sku.dimensions.length ||
                    sku.dimensions.width ||
                    sku.dimensions.height) && (
                    <p>
                      <span>
                        <FormattedMessage id="products.mobile-accordion.dimensions" />
                        (
                        {`${sku.dimensions.length ? 'D' : ''}${
                          sku.dimensions.width ? 'xŠ' : ''
                        }${sku.dimensions.height ? 'xV' : ''}`}
                        ):
                      </span>
                      <span>
                        {sku.dimensions.length && `${sku.dimensions.length}`}{' '}
                        {sku.dimensions.width && `x ${sku.dimensions.width}`}{' '}
                        {sku.dimensions.height && `x ${sku.dimensions.height}`}{' '}
                        mm
                      </span>
                    </p>
                  )}
                {exists(sku.salesRelation.piecesMin) &&
                  exists(sku.salesRelation.piecesMax) && (
                    <p>
                      <span>
                        <FormattedMessage id="products.mobile-accordion.piece-by-square" />
                      </span>
                      <span>
                        {sku.salesRelation.piecesMin} -{' '}
                        {sku.salesRelation.piecesMax} kom/m2
                      </span>
                    </p>
                  )}
                {exists(sku.salesRelation.netWeightm2) && (
                  <p>
                    <span>
                      <FormattedMessage id="products.mobile-accordion.weight-by-square" />
                    </span>
                    <span>{sku.salesRelation.netWeightm2} kg</span>
                  </p>
                )}
                {exists(sku.minBattenDistance) &&
                  exists(sku.maxBattenDistance) && (
                    <p>
                      <span>
                        <FormattedMessage id="products.mobile-accordion.roof-lathing" />
                      </span>
                      <span>
                        {sku.maxBattenDistance} - {sku.minBattenDistance} mm
                      </span>
                    </p>
                  )}
                {(exists(sku.salesRelation.piecesDry) ||
                  exists(sku.salesRelation.piecesMortar)) && (
                  <p>
                    <span>
                      <FormattedMessage id="products.mobile-accordion.brick-consumption" />
                    </span>
                    <span>
                      {sku.salesRelation.piecesDry ||
                        sku.salesRelation.piecesMortar}{' '}
                      kom/m2
                    </span>
                  </p>
                )}
                {exists(sku.thermalConductivity) && (
                  <p>
                    <span>
                      <FormattedMessage id="products.mobile-accordion.thermal-conductivity" />
                    </span>
                    <span>{sku.thermalConductivity} W/mK</span>
                  </p>
                )}
                {exists(sku.verticalCompressiveStrength) && (
                  <p>
                    <span>
                      <FormattedMessage id="products.mobile-accordion.vertical-compressive-strength" />
                    </span>
                    <span>{sku.verticalCompressiveStrength} N/mm2</span>
                  </p>
                )}
                {exists(sku.weightPerPiece) && (
                  <p>
                    <span>
                      <FormattedMessage id="products.mobile-accordion.weight" />
                    </span>
                    <span>{sku.weightPerPiece} kg</span>
                  </p>
                )}
                {exists(sku.pcsPerPallet) && (
                  <p>
                    <span>
                      <FormattedMessage id="products.mobile-accordion.piece-by-palette" />
                    </span>
                    <span>{sku.pcsPerPallet} kom/pal</span>
                  </p>
                )}
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        )}
        {(isRoof || isWall) && (
          <AccordionItem css={listing.accordionItem}>
            <AccordionItemHeading>
              <AccordionItemButton css={listing.accordionTitle}>
                <p css={[spacers.left.xLarge]}>
                  <FormattedMessage id="products.category.calculators" />
                </p>
                <ChevronDown className={[spacers.right.medium]} />
              </AccordionItemButton>
            </AccordionItemHeading>

            <AccordionItemPanel>
              {isRoof ? (
                <Suspense fallback={null}>
                  <RoofCalculators titleHidden />
                </Suspense>
              ) : (
                <Suspense fallback={null}>
                  <WallCalculators titleHidden />
                </Suspense>
              )}
            </AccordionItemPanel>
          </AccordionItem>
        )}
      </Accordion>
    </div>
  );
};
