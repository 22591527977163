import React, { useState } from 'react';

interface Props {
  className?: string;
}

export const CartIcon: React.FC<Props> = ({ className }) => {
  const [hover, setHover] = useState(false);

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={className}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
    >
      <path
        d="M3.75 6.75L7.5 1.5"
        stroke={hover ? '#B30000' : 'black'}
        strokeMiterlimit="10"
      />
      <path
        d="M14.25 6.75L10.5 1.5"
        stroke={hover ? '#B30000' : 'black'}
        strokeMiterlimit="10"
      />
      <path
        d="M12.75 15.75H4.5L1.5 6.75H16.5L12.75 15.75Z"
        stroke={hover ? '#B30000' : 'black'}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );

  function handleEnter() {
    setHover(true);
  }

  function handleLeave() {
    setHover(false);
  }
};
