import { SerializedStyles } from '@emotion/core';
import React from 'react';

interface Props {
  className?: string[] | SerializedStyles;
}

export const ChevronDown: React.FC<Props> = ({ className }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    css={className}
  >
    <path d="M12 14L16 18L20 14" stroke="black" />
  </svg>
);
