import { Product } from 'models';

export function filterByCategory(
  selectedCategories: Record<string, boolean>,
  initialList: Product[],
) {
  const checkedCategories = Object.entries(selectedCategories).reduce(
    (acc: string[], [key, value]) => {
      if (value) return [...acc, key];
      return acc;
    },
    [],
  );

  const filteredCategoryProducts = checkedCategories.length
    ? initialList.filter((product) =>
        checkedCategories.includes(product.commercial.category),
      )
    : initialList;

  return filteredCategoryProducts;
}
