import { SerializedStyles } from '@emotion/utils';
import { ChevronLeft, ChevronRight } from 'assets';
import { ItemsPerPageOptions } from 'consts';
import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';
import { pagination, spacers } from 'styles';

import { ControlledSelect } from './ControlledSelect';
import { SelectOption } from './ValidatedSelect';

interface Props {
  currentPage: number;
  next: VoidFunction;
  prev: VoidFunction;
  jump: (index: number) => void;
  maxPage: number;
  itemsPerPage: number;
  setItemsPerPage: React.Dispatch<React.SetStateAction<number>>;
  showItemsPerPage?: boolean;
  wrapperStyles?: SerializedStyles;
}

export const Pagination: React.FC<Props> = ({
  currentPage,
  next,
  prev,
  jump,
  maxPage,
  itemsPerPage,
  setItemsPerPage,
  showItemsPerPage = true,
  wrapperStyles,
}) => {
  return (
    <section css={wrapperStyles || pagination.wrapper}>
      <div css={pagination.pages}>
        <span
          onClick={prev}
          css={[pagination.chevronWrapper, spacers.right.medium]}
        >
          <ChevronLeft className={pagination.chevronLeft} />
          <FormattedMessage id="pagination.previous" />
        </span>

        {Array.from(Array(maxPage + 1)).map((_, i) => {
          if (!i) return null;

          return (
            <span
              key={i}
              css={[
                pagination.number,
                currentPage === i && pagination.numberActive,
              ]}
              onClick={jump.bind(null, i)}
            >
              {i}
            </span>
          );
        })}

        <span
          onClick={next}
          css={[pagination.chevronWrapper, spacers.left.medium]}
        >
          <FormattedMessage id="pagination.next" />
          <ChevronRight className={pagination.chevronRight} />
        </span>
      </div>

      {showItemsPerPage && (
        <div css={pagination.itemsWrapper}>
          <p css={[spacers.right.small]}>
            <FormattedMessage id="pagination.items-per-page" />
          </p>

          <ControlledSelect
            name="itemsPerPage"
            value={{
              value: itemsPerPage.toString(),
              label: itemsPerPage.toString(),
            }}
            options={ItemsPerPageOptions}
            onChange={handleItemsPerPageChange}
          />
        </div>
      )}
    </section>
  );

  function handleItemsPerPageChange(value: SelectOption<string>) {
    if (!value.value) return;

    jump(1);
    setItemsPerPage(parseInt(value.value));
  }
};
