import { Product } from 'models';

export function filterByDimensions(
  selectedDimensions: Record<string, boolean>,
  initialList: Product[],
) {
  const checkedDimensions = Object.entries(selectedDimensions).reduce(
    (acc: string[], [key, value]) => {
      if (value) return [...acc, key];
      return acc;
    },
    [],
  );
  const filteredDimensionProducts = checkedDimensions.length
    ? initialList.filter((product) => {
        const { length, width, height } = product.sku?.dimensions;

        return checkedDimensions.includes(
          `${length}mm x ${width}mm x ${height}mm`,
        );
      })
    : initialList;

  return filteredDimensionProducts;
}
