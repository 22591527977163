import React from 'react';
import { product } from 'styles';

interface Props {
  links: string[];
}
export const VideoMaterials: React.FC<Props> = ({ links }) => {
  return (
    <section css={product.horizontalListWrapper}>
      <h4 css={product.subTitle}>Video materijali</h4>
      <div css={product.horizontalListScroll}>
        <div css={product.horizontalList}>
          {links.map((link) => (
            <iframe
              key={link}
              css={product.iframe}
              src={link}
              frameBorder="0"
              loading="lazy"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ))}
        </div>
      </div>
    </section>
  );
};
