import { CopyActions } from './actions';
import { CopyTypes } from './types';

export interface CopyState {
  showCopy: boolean;
}

const INITIAL_STATE: CopyState = {
  showCopy: true,
};

export default (state: CopyState = INITIAL_STATE, action: CopyActions) => {
  switch (action.type) {
    case CopyTypes.removeCopy:
      return {
        ...state,
        showCopy: false,
      };

    default:
      return state || INITIAL_STATE;
  }
};
