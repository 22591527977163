import { css } from '@emotion/core';

import { typescale } from './typescale.styles';
import { colors, unit } from './vars.styles';

const title = css`
  ${typescale.xxxlarge}

  color: ${colors.dark};
  margin-bottom: ${unit * 10};
`;

const subtitle = css`
  ${typescale.medium}

  color: ${colors.text};
  margin-bottom: ${unit * 25};
`;

const categoryRow = css`
  display: flex;
  margin-bottom: 148px;

  & > img {
    max-width: 510px;
  }
`;

export const home = {
  title,
  subtitle,
  categoryRow,
};
