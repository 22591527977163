import React from 'react';

export const TimesIcon: React.FC = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.7142 6.28577L6.28564 15.7143"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M6.28564 6.28577L15.7142 15.7143"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
