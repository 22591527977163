import { InboxTabsIcon, SearchIcon } from 'assets';
import { ControlledInput } from 'components';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import React, { useState } from 'react';
import { buttons, inbox, navStyles, spacers } from 'styles';

import { MessageLocation } from '../models';

interface Props {
  userType: 'customer' | 'distributor' | 'admin' | 'sales';
  activeMailCategory: MessageLocation;
  handleMailCategoryChange: (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => void;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
}

export const MobileMailHeader: React.FC<Props> = ({
  userType,
  activeMailCategory,
  handleMailCategoryChange,
  searchText,
  setSearchText,
}) => {
  const { formatMessage } = useIntl();
  const [tabsOpen, setTabsOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  return (
    <>
      {!searchOpen && (
        <div css={inbox.mobileHeader}>
          <div>
            <button
              type="button"
              css={inbox.mobileHeaderTabs}
              onClick={toggleTabsOpen}
            >
              <InboxTabsIcon className={[spacers.right.xLarge]} />
              <span>{resolveNamingOfActiveCategory()}</span>
            </button>
          </div>

          <div>
            <button
              type="button"
              css={buttons.container}
              onClick={toggleSearchOpen}
            >
              <SearchIcon />
            </button>
          </div>
        </div>
      )}

      {searchOpen && (
        <ControlledInput
          name="search"
          value={searchText}
          onChange={setSearchText}
          onClose={toggleSearchOpen}
          placeholder={formatMessage({ id: 'shared.search' })}
          className={inbox.mobileSearchInput}
          fieldClassName={inbox.mobileSearchField}
        />
      )}

      <div css={navStyles.menu(tabsOpen)}>
        <div css={navStyles.menuWrapper(tabsOpen)}>
          <div css={navStyles.menuContent(tabsOpen)}>
            <div css={inbox.mobileTabsModal}>
              <button
                type="button"
                css={inbox.mobileTabBtn}
                onClick={handleCategoryClick}
                data-category="inbox"
              >
                <FormattedMessage id="inbox.mail-category-tabs.inbox" />
              </button>

              {userType === 'customer' && (
                <button
                  type="button"
                  css={inbox.mobileTabBtn}
                  onClick={handleCategoryClick}
                  data-category="requested"
                >
                  <FormattedMessage id="inbox.mail-category-tabs.requested" />
                </button>
              )}

              {userType === 'distributor' && (
                <button
                  type="button"
                  css={inbox.mobileTabBtn}
                  onClick={handleCategoryClick}
                  data-category="sent"
                >
                  <FormattedMessage id="inbox.mail-category-tabs.sent" />
                </button>
              )}

              <button
                type="button"
                css={inbox.mobileTabBtn}
                onClick={handleCategoryClick}
                data-category="archive"
              >
                <FormattedMessage id="inbox.mail-category-tabs.archive" />
              </button>

              <button
                type="button"
                css={inbox.mobileTabBtn}
                onClick={handleCategoryClick}
                data-category="trash"
              >
                <FormattedMessage id="inbox.mail-category-tabs.trash" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  function resolveNamingOfActiveCategory() {
    switch (activeMailCategory) {
      case 'inbox':
        return formatMessage({ id: 'inbox.mail-category-tabs.inbox' });
      case 'requested':
        return formatMessage({ id: 'inbox.mail-category-tabs.requested' });
      case 'sent':
        return formatMessage({ id: 'inbox.mail-category-tabs.sent' });
      case 'archive':
        return formatMessage({ id: 'inbox.mail-category-tabs.archive' });
      case 'trash':
        return formatMessage({ id: 'inbox.mail-category-tabs.trash' });

      default:
        return formatMessage({ id: 'inbox.mail-category-tabs.inbox' });
    }
  }

  function toggleTabsOpen() {
    setTabsOpen(!tabsOpen);
  }

  function handleCategoryClick(event: React.MouseEvent<HTMLButtonElement>) {
    setTabsOpen(false);
    handleMailCategoryChange(event);
  }

  function toggleSearchOpen() {
    setSearchOpen(!searchOpen);
  }
};
