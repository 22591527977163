import 'firebase/auth';

import { Collection } from 'consts';
import firebase from 'firebase/app';
import { User } from 'modules/authentication';
import { BusinessInformation, ClientInformation } from 'modules/cart';

import { FirebaseService } from './FirebaseService';
import { FirestoreService } from './FirestoreService';

enum AuthPersistence {
  Local = 'local',
  Session = 'session',
  None = 'none',
}

export function AuthService() {
  const auth = FirebaseService.Instance()?.instance.auth();
  const providers = FirebaseService.Instance()?.providers;

  function initAuthStateListener(
    onSuccess: (user: firebase.UserInfo) => void,
    onError: (error?: firebase.FirebaseError) => void,
  ) {
    return auth?.onAuthStateChanged((user) => {
      if (!user) {
        return onError();
      }

      return onSuccess(user);
    });
  }

  async function anonymousLoginAsync() {
    return auth
      ?.setPersistence(AuthPersistence.Local)
      .then(() => auth.signInAnonymously());
  }

  async function loginWithEmailAndPasswordAsync(
    email: string,
    password: string,
  ) {
    return auth?.setPersistence(AuthPersistence.Local).then(() =>
      auth
        .signInWithEmailAndPassword(email, password)
        .then(() => undefined)
        .catch((error: firebase.FirebaseError) => error),
    );
  }

  async function logoutAsync() {
    return auth
      ?.signOut()
      .then(() => undefined)
      .catch((error: firebase.FirebaseError) => error);
  }

  async function sendPasswordResetEmail(email: string) {
    await auth?.sendPasswordResetEmail(email);
  }

  async function verifyPasswordResetCode(code: string) {
    return auth
      ?.verifyPasswordResetCode(code)
      .then(() => undefined)
      .catch((error) => error.message as string);
  }

  async function verifyEmailValidCode(code: string) {
    const response = await auth?.checkActionCode(code);

    if (response?.operation === 'VERIFY_EMAIL' && response.data.email) {
      return { email: response.data.email };
    }

    return false;
  }

  async function confirmPasswordReset(code: string, newPassword: string) {
    return auth
      ?.confirmPasswordReset(code, newPassword)
      .then(() => undefined)
      .catch((error) => error.message as string);
  }

  function getUserUid() {
    const user = auth?.currentUser;

    if (user) return user.uid;
    return null;
  }

  function getUserData() {
    const userUid = auth?.currentUser?.uid;

    const users = FirestoreService(Collection.Users);

    if (users && userUid) return users?.find(userUid);

    return null;
  }

  async function signInWithFacebook() {
    if (!providers) return;
    const users = FirestoreService<User>(Collection.Users);

    return auth
      ?.signInWithPopup(providers.fb)
      .then(async (result) => {
        if (
          result.additionalUserInfo?.isNewUser &&
          result.additionalUserInfo.profile
        ) {
          return await users?.update(
            {
              ...new User({
                firstName: result.additionalUserInfo.profile['first_name'],
                lastName: result.additionalUserInfo.profile['last_name'],
                email: result.additionalUserInfo.profile['email'],
                emailVerified: true,
              }),
            },
            result.user?.uid,
          );
        }

        return;
      })
      .catch((error: { code: string; message: string }) => {
        if (error.code === 'auth/account-exists-with-different-credential') {
          return 'Korisnik s email adresom je već registriran, ali s drugom vjerodajnicom. Molimo Vas da koristite odabranog davatelja usluge.';
        }

        return error.message;
      });
  }

  async function signInWithGoogle() {
    if (!providers) return;
    const users = FirestoreService<User>(Collection.Users);

    return auth
      ?.signInWithPopup(providers.google)
      .then(async (result) => {
        if (
          result.additionalUserInfo?.isNewUser &&
          result.additionalUserInfo.profile
        ) {
          return await users?.update(
            {
              ...new User({
                firstName: result.additionalUserInfo.profile['given_name'],
                lastName: result.additionalUserInfo.profile['family_name'],
                email: result.additionalUserInfo.profile['email'],
                emailVerified: true,
              }),
            },
            result.user?.uid,
          );
        }

        return;
      })
      .catch((error: { code: string; message: string }) => {
        if (error.code === 'auth/account-exists-with-different-credential') {
          return 'Korisnik s email adresom je već registriran, ali s drugom vjerodajnicom. Molimo Vas da koristite odabranog davatelja usluge.';
        }

        return error.message;
      });
  }
  function setUserData(data: ClientInformation) {
    const user = auth?.currentUser;

    const users = FirestoreService(Collection.Users);

    delete data.oldPassword;
    delete data.newPassword;

    const dataToUpdate = data.country
      ? { ...data, country: data.country.value }
      : data;

    if (users && user?.uid) users?.update(dataToUpdate, user.uid);

    return;
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    if (!providers) return;
    const user = auth?.currentUser;
    let credential;

    if (user?.email) {
      credential = providers.email(user.email, oldPassword);

      return user
        .reauthenticateWithCredential(credential)
        .then(() => {
          user.updatePassword(newPassword);
          return true;
        })
        .catch(() => false);
    }

    return false;
  }

  function setBusinessData(data: BusinessInformation) {
    const userUid = auth?.currentUser?.uid;

    const users = FirestoreService(Collection.Users);

    if (users && userUid) users?.update({ business: data }, userUid);

    return;
  }

  function subscribeUser() {
    const userUid = auth?.currentUser?.uid;

    const users = FirestoreService(Collection.Users);

    if (users && userUid) users.update({ subscription: true }, userUid);
  }

  return {
    initAuthStateListener,
    anonymousLoginAsync,
    loginWithEmailAndPasswordAsync,
    logoutAsync,
    sendPasswordResetEmail,
    verifyPasswordResetCode,
    verifyEmailValidCode,
    confirmPasswordReset,
    getUserUid,
    getUserData,
    signInWithFacebook,
    signInWithGoogle,
    setUserData,
    setBusinessData,
    changePassword,
    subscribeUser,
  };
}
