import { useLocation, useNavigate } from '@reach/router';
import { GoogleIcon } from 'assets';
import { AppDispatch } from 'modules/redux-store';
import React from 'react';
import { useDispatch } from 'react-redux';
import { auth } from 'styles';

import { AuthThunks } from '../redux';

interface Props {
  text: string;
}

export const GoogleAuth: React.FC<Props> = ({ text }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const navigateTo = useNavigate();

  return (
    <button type="button" css={auth.socialWrapper} onClick={handleGoogleSignIn}>
      <GoogleIcon />
      <p>{text}</p>
    </button>
  );

  async function handleGoogleSignIn() {
    const error = await dispatch(AuthThunks.signInWithGoogleAccount());
    if (!error && pathname.includes('kosarica')) {
      navigateTo('/kosarica');
    } else if (!error) {
      navigateTo('/');
    }
  }
};
