import { FormattedMessage } from 'gatsby-plugin-intl';
import { Product } from 'models';
import React, { useEffect, useState } from 'react';
import { buttons, listing, spacers } from 'styles';
import { CategoryFiltersState } from 'template/CategoryPage';
import { handleShowFilterButton } from 'utils';

import { CategoryFilters } from './CategoryFilters';
import { ProductBrand } from './ProductBrand';

interface Props {
  products: Product[];
  navCategory: 'Roof' | 'Wall' | 'Facade';
  models: string[];
  colors: string[];
  wallWidths: number[];
  consumptions: number[];
  handleChange: (name: string, value: boolean, section?: string) => void;
  clearFilters: VoidFunction;
  filtersState: CategoryFiltersState;
}

export const DesktopCategoryPageFilters: React.FC<Props> = ({
  products,
  models,
  navCategory,
  colors,
  wallWidths,
  consumptions,
  handleChange,
  filtersState,
  clearFilters,
}) => {
  const [isFilterPicked, setIsFilterPicked] = useState(false);

  useEffect(() => {
    setIsFilterPicked(handleShowFilterButton(filtersState));
  }, [filtersState]);

  const handleClick = () => {
    clearFilters();
    setIsFilterPicked(false);
  };

  const isButtonHidden = isFilterPicked ? '' : buttons.hidden;
  return (
    <section css={listing.filters}>
      <ProductBrand
        brand={products[0].commercial.brand}
        amount={products.length}
      />
      <div css={isButtonHidden}>
        <button
          type="button"
          css={[buttons.ghost.small, spacers.bottom.huge]}
          onClick={handleClick}
        >
          <FormattedMessage id="products.clear-filter" />
        </button>
      </div>
      <CategoryFilters
        products={products}
        models={models}
        navCategory={navCategory}
        colors={colors}
        wallWidths={wallWidths}
        consumptions={consumptions}
        handleChange={handleChange}
        filtersState={filtersState}
      />
    </section>
  );
};
