import 'firebase/firestore';

import { Collection } from 'consts';
import firebase from 'firebase/app';
import {
  handleErrorReporting,
  isFirestoreOrderingParam,
  stripMetaProperties,
  stripUndefinedProperties,
} from 'utils';

import { FirestoreOrderingParam, FirestoreQueryParam } from '../models';
import { FirebaseService } from './FirebaseService';

/**
 * Service function that manages documents within a collection.
 * Depends on document model/interface and collection.
 * @example
 * const profileService = FirestoreService<Profile>(Collection.Profiles);
 */
export function FirestoreService<T extends Record<string, any>>(
  collection: Collection,
) {
  const firestore = FirebaseService.Instance()?.instance.firestore();
  if (!firestore) return;

  /** Document reference object */
  const documentRef = document;

  /** Collection reference object */
  const collectionRef = firestore.collection(collection);

  /** Add a new document to the collection.
   * Result will return either an object with the saved properties or an error message.
   *
   * @example
   * service.add(item).then(result => {...})
   */
  async function add(data: T): Promise<T | string> {
    data = stripMetaProperties(data);

    return collectionRef
      .add({ ...data })
      .then((doc) => ({
        ...data,
        id: doc.id,
      }))
      .catch((error) => error.message);
  }
  /** Add multiple documents to the collection. Will return an array of results coupled with their document ids.
   * Note: returned results may include false positive results - ie document was not created on Firestore.
   *
   * @example
   * service.addMultiple(items);
   */
  function addMultiple(data: T[]): T[] {
    const result: T[] = [];
    data.forEach((item) => {
      const id = collectionRef?.doc().id;
      collectionRef?.doc(id).set({ ...item }, { merge: true });
      result.push({ ...item, id });
    });
    return result;
  }
  /** Get firestore document by id.
   * Will return the document data object or an error string
   *
   * @example
   * service.find(id).then(result => {...})
   */
  async function find(
    id: string,
  ): Promise<(T & { id: string }) | null | undefined> {
    return collectionRef
      .doc(id)
      .get()
      .then((snap) =>
        snap.exists ? { ...(snap.data() as T), id: snap.id } : null,
      )
      .catch((error) => {
        console.log(error);
        return null;
      });
  }
  /**
   * Open up a listener for firestore document.
   * Will return a listener function that should be stored as a reference in order to be able to unsubscribe from it if necessary.
   *
   * It returns document if it exists or the id of the deleted document
   * @example
   * const listener = service.findAndListen(id, doc => {...}, error => {...});
   */
  function findAndListen(
    id: string,
    onSuccess: (data: T | string) => void,
    onError: (error: string) => void,
  ): () => void {
    return collectionRef.doc(id).onSnapshot(
      (snap) =>
        snap.exists
          ? onSuccess({ ...snap.data(), id: snap.id } as unknown as T)
          : onSuccess(snap.id),
      (error) => {
        onError(error.message);
      },
    );
  }

  async function findAll(): Promise<T[] | null> {
    return collectionRef
      .get()
      .then((snap) =>
        snap.docs.map((doc) => ({ ...(doc.data() as T), id: doc.id })),
      )
      .catch((error) => {
        handleErrorReporting(error);
        return null;
      });
  }

  async function findFirst(): Promise<T | null> {
    return collectionRef
      .limit(1)
      .get()
      .then(
        (snap) =>
          snap.docs.map((doc) => ({ ...(doc.data() as T), id: doc.id }))[0],
      )
      .catch((error) => {
        handleErrorReporting(error);
        return null;
      });
  }

  function findFirstAndListen(
    onSuccess: (data?: T) => void,
    onError: (error: string) => void,
  ): () => void {
    return collectionRef.limit(1).onSnapshot(
      (snap) =>
        snap.docs.length
          ? onSuccess({
              ...snap.docs[0].data(),
              id: snap.docs[0].id,
            } as unknown as T)
          : undefined,
      (error) => {
        onError(error.message);
      },
    );
  }

  /** Filter collection items by passed parameters.
   * To get entire collection, pass no query parameters.
   * If specific ordering is required, pass the orderBy prop into the filter objects, but be careful with them, they can break the query.
   * Returns array of objects or error message.
   *
   * @example
   * service.filter(undefined, new FirestoreQueryParam("category", "==", "Fish"), new FirestoreOrderingParam("name")).then(result => {...})
   */
  async function filter(
    ...params: (FirestoreQueryParam | FirestoreOrderingParam)[]
  ): Promise<T[] | string> {
    let query: firebase.firestore.Query = collectionRef;

    params.forEach((param) => {
      if (!isFirestoreOrderingParam(param)) {
        query = query.where(param.field, param.operator, param.value);
      }
    });
    params.forEach((param) => {
      if (isFirestoreOrderingParam(param)) {
        query = query.orderBy(param.orderBy, param.direction || 'asc');
      }
    });

    return query
      .get()
      .then((snap) => snap.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      .catch((error) => error.message);
  }

  /** Filter collection items by passed parameters and listen.
   * Will return a listener function that should be stored as a reference in order to be able to unsubscribe from it if necessary.
   *
   * @example
   * const listener = service.filterAndListen(data => {...}, error => {...}, undefined, new FirestoreQueryParam("category", "==", "Fish"), new FirestoreOrderingParam("name"));
   */
  function filterAndListen<S extends any>(
    onSuccess: (data: S[]) => void,
    onError: (error: string) => void,
    ...params: (FirestoreQueryParam | FirestoreOrderingParam)[]
  ): () => void {
    let query: firebase.firestore.Query = collectionRef;

    params.forEach((param) => {
      if (!isFirestoreOrderingParam(param)) {
        query = query.where(param.field, param.operator, param.value);
      }
    });
    params.forEach((param) => {
      if (isFirestoreOrderingParam(param)) {
        query = query.orderBy(param.orderBy, param.direction || 'asc');
      }
    });

    return query.onSnapshot(
      (snap) =>
        onSuccess(
          snap.docs.map(
            (doc) =>
              ({
                ...doc.data(),
                id: doc.id,
              } as unknown as S),
          ),
        ),
      (error) => {
        onError(error.message);
      },
    );
  }

  /** Update document with new data.
   * If data object does not contain the id, pass it explicitly as the second parameter.
   * If response is undefined, update succeeded; if response exists the update failed.
   *
   * @example
   * service.update(item, id).then((error: string | undefined) => {...})
   */
  async function update(
    data: Partial<T>,
    id?: string,
  ): Promise<undefined | firebase.FirebaseError> {
    const refId = data.id || data.uid || id;
    data = stripMetaProperties(stripUndefinedProperties(data));
    // Can't update object because there is no reference to get the document by.
    if (!refId) {
      const error = {
        name: '',
        code: 'firestore/id-missing',
        message:
          'Reference id is missing. If the data property does not contain an id, pass it explicitly as the second parameter.',
      };
      return error;
    }
    return collectionRef
      .doc(refId)
      .set({ ...data }, { merge: true })
      .then(() => undefined)
      .catch((error) => error);
  }
  /** Update multiple items at once in a batch. Only items with id props set will be updated, rest will be left as-is.
   * Returns undefined if successful, string error message if failed.
   *
   * @example
   * service.updateMultiple(items).then((error: string | undefined) => {...})
   */
  async function updateMultiple(data: T[]) {
    const batch = firestore?.batch();
    data
      .filter((item) => item.id)
      .forEach((item) => {
        const id = item.id;
        const strippedItem = stripMetaProperties(
          stripUndefinedProperties(item),
        );
        batch?.update(collectionRef.doc(id), { ...strippedItem });
      });
    return batch
      ?.commit()
      .then(() => undefined)
      .catch((error) => {
        handleErrorReporting(error);
        return 'Update operation failed';
      });
  }
  /** Delete document.
   * If response is undefined, delete succeeded; if response exists deletion failed.
   *
   * @example
   * service.remove(id).then((error: string | undefined) => {...})
   */
  async function remove(id: string): Promise<undefined | string> {
    return collectionRef
      .doc(id)
      .delete()
      .then(() => undefined)
      .catch((error) => error.message);
  }

  /** Delete multiple documents.
   * Returns undefined if successful, error string if operation failed.
   *
   * @example
   * service.removeMultiple(ids).then((error: string | undefined) => {...})
   */
  async function removeMultiple(ids: string[]): Promise<undefined | string> {
    const batch = firestore?.batch();
    ids.forEach((id) => {
      batch?.delete(collectionRef.doc(id));
    });
    return batch
      ?.commit()
      .then(() => undefined)
      .catch((error) => {
        handleErrorReporting(error);
        return 'Delete operation failed';
      });
  }

  /** Set mail location to archive
   *
   * @example
   * service.archive(id).then((error: string | undefined) => {...})
   */
  async function archive(
    id: string,
    userRole: 'customer' | 'distributor' | 'admin' | 'sales',
  ): Promise<undefined | firebase.FirebaseError> {
    // Can't update object because there is no reference to get the document by.
    if (!id) {
      const error = {
        name: '',
        code: 'firestore/id-missing',
        message:
          'Reference id is missing. If the data property does not contain an id, pass it explicitly as the second parameter.',
      };
      return error;
    }
    return collectionRef
      .doc(id)
      .set(
        userRole !== 'distributor'
          ? { clientMsgLocation: 'archive' }
          : { distributorMsgLocation: 'archive' },
        { merge: true },
      )
      .then(() => undefined)
      .catch((error) => error);
  }

  /** Set mail location to trash
   *
   * @example
   * service.moveToTrash(id).then((error: string | undefined) => {...})
   */
  async function moveToTrash(
    id: string,
    userRole: 'customer' | 'distributor' | 'sales' | 'admin',
  ): Promise<undefined | firebase.FirebaseError> {
    // Can't update object because there is no reference to get the document by.
    if (!id) {
      const error = {
        name: '',
        code: 'firestore/id-missing',
        message:
          'Reference id is missing. If the data property does not contain an id, pass it explicitly as the second parameter.',
      };
      return error;
    }
    return collectionRef
      .doc(id)
      .set(
        userRole !== 'distributor'
          ? { clientMsgLocation: 'trash' }
          : { distributorMsgLocation: 'trash' },
        { merge: true },
      )
      .then(() => undefined)
      .catch((error) => error);
  }

  /** Sets mail to status { isRead: true }
   *
   * @example
   * service.setIsRead(id).then((error: string | undefined) => {...})
   */
  async function setIsRead(
    id: string,
    propName: string,
  ): Promise<undefined | firebase.FirebaseError> {
    // Can't update object because there is no reference to get the document by.
    if (!id) {
      const error = {
        name: '',
        code: 'firestore/id-missing',
        message:
          'Reference id is missing. If the data property does not contain an id, pass it explicitly as the second parameter.',
      };
      return error;
    }
    return collectionRef
      .doc(id)
      .set({ [propName]: true }, { merge: true })
      .then(() => undefined)
      .catch((error) => error);
  }

  /**
   * Unsubscribe all active listeners.
   */
  function unsubscribeListeners(listeners: { [listener: string]: () => void }) {
    Object.values(listeners).forEach((listener) => {
      if (listener) {
        listener();
      }
    });
  }

  return {
    documentRef,
    collectionRef,
    add,
    addMultiple,
    find,
    findAndListen,
    findAll,
    findFirst,
    findFirstAndListen,
    filter,
    filterAndListen,
    update,
    updateMultiple,
    remove,
    removeMultiple,
    archive,
    moveToTrash,
    setIsRead,
    unsubscribeListeners,
  };
}
