import { DropdownIndicator, SelectOption } from 'components';
import { DeliveryOption } from 'consts';
import React from 'react';
import Select from 'react-select';
import { fields, itemsPerPageSelectStyles } from 'styles';

interface Props {
  name: string;
  label?: string | JSX.Element;
  sublabel?: string;
  value?: SelectOption<string>;
  options: SelectOption<string>[];
  disabled?: boolean;
  placeholder?: string;
  styles?: Partial<Styles>;
  labelStyles?: Partial<Styles>;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  onChange:
    | ((value: SelectOption<string>) => void)
    | ((value: SelectOption<DeliveryOption>) => void);
}

export const ControlledSelect: React.FC<Props> = ({
  name,
  label,
  value,
  options,
  disabled,
  placeholder,
  menuPlacement,
  styles,
  labelStyles,
  onChange,
}) => {
  return (
    <div css={labelStyles}>
      {label && (
        <label css={fields.label} htmlFor={name}>
          {label}
        </label>
      )}

      <Select
        name={name}
        value={value}
        options={options}
        placeholder={placeholder}
        components={{ DropdownIndicator }}
        disabled={disabled}
        onChange={onChange as keyof typeof onChange}
        styles={styles || itemsPerPageSelectStyles}
        menuPlacement={menuPlacement || 'top'}
      />
    </div>
  );
};
