import { format } from 'date-fns';

import {
  CommunicationMedium,
  ContactIdOrigin,
  InteractionType,
} from '../models';

export const getTimestamp = () => format(new Date(), 'yyyyMMddhhmmss');

export const getCommunicationMedium: () => CommunicationMedium = () => 'WEB';

export const getContactIdOrigin: () => ContactIdOrigin = () =>
  'SEE_QUOTE_PLATFORM';

export const getInteractionType: () => InteractionType = () => 'SEE_QUOTE_TOOL';
