import { ChevronDown } from 'assets';
import { ControlledCheckbox } from 'components';
import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { listing } from 'styles';
import { WallListingState } from 'template/AllWallListing';
import { notEmpty } from 'utils';

interface Props {
  categories: string[];
  models: string[];
  wallWidths: string[];
  piecesPerMsq: string[];
  filterState: WallListingState;
  handleChange: (name: string, value: boolean, section?: string) => void;
}

export const WallFilters: React.FC<Props> = ({
  categories,
  models,
  wallWidths,
  piecesPerMsq,
  filterState,
  handleChange,
}) => {
  const sortedWallCategories = [
    categories[2],
    categories[0],
    categories[4],
    categories[1],
    categories[3],
  ];

  return (
    <>
      <p css={listing.filterTitle}>
        <FormattedMessage id="products.category.wall-categories" />
      </p>
      <ul css={listing.accordionItem}>
        {sortedWallCategories.filter(notEmpty).map((category) => (
          <li key={category}>
            <ControlledCheckbox
              name={category}
              text={category}
              section="categories"
              onChange={handleChange}
              checked={filterState.categories[category]}
            />
          </li>
        ))}
      </ul>

      <p css={listing.filterTitle}>
        <FormattedMessage id="products.category.models" />
      </p>
      <ul css={listing.accordionItem}>
        {models.map((model) => (
          <li key={model}>
            <ControlledCheckbox
              name={model}
              text={model}
              section="models"
              onChange={handleChange}
              checked={filterState.models[model]}
            />
          </li>
        ))}
      </ul>

      <Accordion allowMultipleExpanded allowZeroExpanded>
        <AccordionItem css={listing.accordionItem}>
          <AccordionItemHeading>
            <AccordionItemButton css={listing.accordionTitle}>
              <p>
                <FormattedMessage id="products.category.wall-thickness" />
              </p>
              <ChevronDown />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {wallWidths.filter(notEmpty).map((width) => (
              <ControlledCheckbox
                key={width}
                name={width}
                text={`${width}mm`}
                section="wallWidths"
                onChange={handleChange}
                checked={filterState.wallWidths[width]}
              />
            ))}
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem css={listing.accordionItem}>
          <AccordionItemHeading>
            <AccordionItemButton css={listing.accordionTitle}>
              <p>
                <FormattedMessage id="products.category.consumption-per-square" />
              </p>
              <ChevronDown />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {piecesPerMsq.filter(notEmpty).map((piece) => (
              <ControlledCheckbox
                key={piece}
                name={piece.toString()}
                text={piece.toString()}
                section="piecesPerMsq"
                onChange={handleChange}
                checked={filterState.piecesPerMsq[piece]}
              />
            ))}
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};
