import { Font, StyleSheet, Text, View } from '@react-pdf/renderer';
import font from 'assets/fonts/DMSans-Regular.ttf';
import { ClientInformation } from 'modules/cart';
import React from 'react';

interface Props {
  clientInformation?: ClientInformation;
}

Font.register({
  family: 'DMSans',
  src: font,
});

export const ClientInfo: React.FC<Props> = ({ clientInformation }) => {
  return (
    <View style={styles.wrapper}>
      <View style={styles.detailsRow}>
        <View>
          <View>
            <Text style={styles.distributorTitle}>
              {clientInformation?.firstName} {clientInformation?.lastName}
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.detailsRow}>
        <View>
          <Text style={styles.textSecondary}>{clientInformation?.address}</Text>
          <Text style={styles.textSecondary}>
            {clientInformation?.postCode} {clientInformation?.city},{' '}
            {clientInformation?.country.value}
          </Text>
        </View>

        {clientInformation?.email && (
          <View>
            <Text style={styles.text}>{clientInformation?.email}</Text>
            <Text style={styles.textSecondary}>EMAIL</Text>
          </View>
        )}
      </View>

      <View style={styles.detailsRow}>
        {clientInformation?.phone && (
          <View>
            <Text style={styles.text}>{clientInformation?.phone}</Text>
            <Text style={styles.textSecondary}>KONTAKT</Text>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    borderBottomColor: 'rgba(0,0,0,0.1)',
    borderBottomWidth: 0.5,
    marginBottom: 30,
    fontFamily: 'DMSans',
  },

  detailsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: 'black',
    paddingBottom: 10,
  },
  distributorTitle: {
    fontWeight: 'bold',
  },
  textSecondary: {
    fontSize: 10,
    letterSpacing: 1,
    opacity: 0.4,
  },
  text: {
    fontSize: 10,
    letterSpacing: 1,
  },
  logo: {
    resizeMethod: 'auto',
    height: 35,
    maxWidth: 150,
    marginBottom: 5,
  },
});
