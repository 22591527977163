import { Product } from 'models';
import { AppDispatch, ApplicationState } from 'modules/redux-store';

import { ProductsActions } from './actions';

const updateLastViewedProducts =
  (newProduct: Product) =>
  (dispatch: AppDispatch, getState: () => ApplicationState) => {
    const currentProducts = [...getState().products.lastViewedProducts];

    const existingProductIndex = currentProducts.findIndex(
      (product) => product.commercial.name === newProduct.commercial.name,
    );
    if (existingProductIndex !== -1) {
      currentProducts.splice(existingProductIndex, 1);
    }

    const updatedProducts = [newProduct, ...currentProducts];

    if (updatedProducts.length > 10) {
      updatedProducts.pop();
    }

    dispatch(ProductsActions.addLastViewedProducts(updatedProducts));
  };

export const ProductsThunks = {
  updateLastViewedProducts,
};
