import { unit } from './vars.styles';

/**
 * Sizes
 */
const tiny = unit;
const small = unit * 2;
const medium = unit * 3;
const large = unit * 4;
const xLarge = unit * 5;
const huge = unit * 8;
const extraHuge = unit * 27;

/**
 * Size helper
 */
type marginPosition =
  | 'margin-top'
  | 'margin-right'
  | 'margin-bottom'
  | 'margin-left';

const createSizeMap = (prop: marginPosition) => ({
  tiny: `${prop}: ${tiny}px`,
  small: `${prop}: ${small}px`,
  medium: `${prop}: ${medium}px`,
  large: `${prop}: ${large}px`,
  xLarge: `${prop}: ${xLarge}px`,
  huge: `${prop}: ${huge}px`,
  extraHuge: `${prop}: ${extraHuge}px`,
});

/**
 * Export all available spacers with all combinations
 */
export const spacers = {
  top: createSizeMap('margin-top'),
  right: createSizeMap('margin-right'),
  bottom: createSizeMap('margin-bottom'),
  left: createSizeMap('margin-left'),
};
