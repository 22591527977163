import firebase from 'firebase/app';

export interface FirestoreQueryParam {
  field: string;
  operator: firebase.firestore.WhereFilterOp;
  value: any;
}

/** Class object used for filtering firestore documents
 * @example
 * service.filter(new FirestoreQueryParam("field", "==", value));
 */
export class FirestoreQueryParam {
  constructor(
    field: string,
    operator: firebase.firestore.WhereFilterOp,
    value: any,
  ) {
    this.field = field;
    this.operator = operator;
    this.value = value;
  }
}
