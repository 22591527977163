import { Link } from 'gatsby';
import Image from 'gatsby-image';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { ClientRequest } from 'modules/inbox';
import React from 'react';
import { inbox, spacers } from 'styles';
import { formatPrice, getProductRoute } from 'utils';

interface Props {
  currentRequest: ClientRequest;
}

export const InboxRequestTableMobile: React.FC<Props> = ({
  currentRequest,
  children,
}) => {
  return (
    <div css={inbox.productsAccordionMobile}>
      <ul css={inbox.productsListMobile}>
        {currentRequest.productRequests?.table.map((item, index) => (
          <li key={index} css={inbox.productsListItemMobile}>
            <div css={inbox.productsListItemTitle}>
              <Image
                fixed={{
                  src:
                    item.product.commercial.assets.mainImg ||
                    item.product.commercial.assets.otherImgs[0],
                  srcSet: '',
                  width: 48,
                  height: 48,
                }}
              />

              <Link
                to={getProductRoute(item.product.commercial)}
                css={[spacers.left.medium]}
              >
                {item.product.commercial.localizedName}
              </Link>
            </div>

            <div css={inbox.productsListItemInfo}>
              <div>
                <p>{item.amount}</p>
                <span>
                  <FormattedMessage id="cart.home-page.product.amount" />
                </span>
              </div>

              <div>
                <p>{item.unit}</p>
                <span>
                  <FormattedMessage id="cart.home-page.product.measure" />
                </span>
              </div>

              <div>
                <p>{item.suggestedPrice} €</p>
                <span>
                  <FormattedMessage id="cart.home-page.product.recommended-price" />
                </span>
              </div>
            </div>
          </li>
        ))}
      </ul>

      <div css={inbox.offerSummary}>
        <div css={inbox.summaryRow}>
          <span>
            <FormattedMessage id="cart.home-page.product.price-without-vat" />
          </span>
          <p>
            {formatPrice(
              currentRequest.productRequests?.summary.totalWithoutTax,
            )}{' '}
            €
          </p>
        </div>

        <div css={inbox.summaryRow}>
          <span>PDV</span>
          <p>{formatPrice(currentRequest.productRequests?.summary.tax)} €</p>
        </div>
      </div>

      <div css={inbox.summaryTotalMobile}>
        <p>
          <span>UKUPNO</span> (PDV uračunat)
        </p>
        <p>{formatPrice(currentRequest.productRequests?.summary.total)} €</p>
      </div>

      <div css={[inbox.summaryRemark, spacers.left.xLarge]}>
        <span css={[spacers.bottom.small]}>
          <FormattedMessage id="shared.remark" />
        </span>
        <p>{currentRequest.clientInformation?.specialRemark}</p>
      </div>
      {children}
    </div>
  );
};
