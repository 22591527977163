import { Product } from 'models';
import createCachedSelector from 're-reselect';
import { applySearchParams } from 'utils';

export const selectProductsByQuery = createCachedSelector(
  (state: { allProducts: Product[]; query: string }) => state.allProducts,
  (state: { allProducts: Product[]; query: string }) => state.query,
  (allProducts, query) => {
    if (query.length >= 2)
      return allProducts.filter((item) =>
        applySearchParams([item.commercial.localizedName], query),
      );
    else return [];
  },
)(({ query }) => query);
