export function renameModels(text: string) {
  switch (text) {
    case 'Roof windows - Blinds':
      return 'Vanjska tenda i sjenila za krovne prozore';
    case 'Roof windows - Thermal insulation':
      return 'Toplinska izolacija za krovne prozore';
    case 'Roof windows - Flashing':
      return 'Opsav za krovne prozore';
    case 'Roof windows':
      return 'Krovni prozori';
    case 'Barok HIDDEN':
      return 'Barok';
    case '120x65':
      return 'Porotherm nadvoji 120x65';
    case '100x65':
      return 'Porotherm nadvoji 100x65';
    case '65x238':
      return 'Porotherm nadvoji 65x238';
    default:
      return text;
  }
}
