import { Global } from '@emotion/core';
import { useLocation } from '@reach/router';
import { AccessControlList } from 'modules/authentication';
import { Footer, Header } from 'modules/navigation';
import { AppDispatch, ApplicationState } from 'modules/redux-store';
import { Search, SearchThunks } from 'modules/search';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { globalStyles, layout } from 'styles';
import { isFixedHeightRoute, usePrevious } from 'utils';

const IndexLayout: React.FC = ({ children }) => {
  const { pathname } = useLocation();

  const dispatch = useDispatch<AppDispatch>();

  const prevPathRef = usePrevious<string>(pathname);

  const { isActive, searchInput } = useSelector(
    (state: ApplicationState) => state.search,
  );

  const { user } = useSelector((state: ApplicationState) => state.auth);
  const mainLayout =
    user && !user.emailVerified
      ? layout.main
      : layout.mainNoEmailVerificationMessage;

  useEffect(() => {
    if (prevPathRef !== pathname && isActive)
      dispatch(SearchThunks.setIsActive(false));
  }, [pathname, prevPathRef, isActive, dispatch]);

  return (
    <section css={layout.pageWrapper}>
      <Global styles={globalStyles} />
      <AccessControlList>
        <Header />
        {isActive ? (
          searchInput.length > 1 ? (
            <Search />
          ) : (
            <div css={layout.searchOverlay} onClick={closeSearch}>
              <div css={layout.activeSearchLayout}>{children}</div>
            </div>
          )
        ) : (
          <div css={!isFixedHeightRoute(pathname) && mainLayout}>
            {children}
          </div>
        )}
        <Footer />
      </AccessControlList>
    </section>
  );

  function closeSearch() {
    dispatch(SearchThunks.setIsActive(false));
  }
};

export default IndexLayout;
