import { Product } from 'models';

export function filterByPcsPerSqMeter(
  selectedValues: Record<string, boolean>,
  initialList: Product[],
) {
  const checkedValues = Object.entries(selectedValues).reduce(
    (acc: string[], [key, value]) => {
      if (value) return [...acc, key];
      return acc;
    },
    [],
  );
  const minValueSelected = Math.min(
    ...checkedValues.map((value) => Number(value)),
  );

  const filteredProductsByPcsPerSqMeter = checkedValues.length
    ? initialList.filter(
        (product) =>
          (product.sku?.salesRelation?.piecesMax || 0) > minValueSelected,
      )
    : initialList;

  return filteredProductsByPcsPerSqMeter;
}
