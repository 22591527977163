import { css, keyframes } from '@emotion/core';

import { color } from './mixins.styles';
import { mqFrom } from './responsive.styles';
import { typescale } from './typescale.styles';
import { colors, fontFamily } from './vars.styles';

const spinnerAnimation = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

const spinnerWrapper = css`
  width: 100%;
  height: calc(100vh - 72px - 416px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const spinner = css`
  position: relative;
  font-size: 10px;
  text-indent: -9999em;
  border-top: 2px solid ${colors.secondary};
  border-right: 2px solid ${colors.secondary};
  border-bottom: 2px solid transparent;
  border-left: 2px solid transparent;
  transform: translateZ(0);
  margin: 4px 0;
  padding: 12px 0;
  animation: ${spinnerAnimation} 0.5s infinite linear;

  &,
  &::after {
    border-radius: 50%;
    width: 24px;
    height: 24px;
  }
`;

const anonSpinner = css`
  ${spinner};
  border-top: 2px solid ${colors.primary};
  border-right: 2px solid ${colors.primary};
`;

const pageWrapper = css`
  height: 100vh;
  background: ${colors.bg};
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  z-index: 2;
`;

const content = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 140px;
  }

  p {
    ${typescale.large}
    font-family: ${fontFamily.serif};
    color: ${color(colors.primary, 0.9)};

    ${mqFrom.Sml} {
      ${typescale.huge}
    }
  }
`;

export const loading = {
  spinnerWrapper,
  spinner,
  pageWrapper,
  content,
  anonSpinner,
};
