import { css } from '@emotion/core';

import { layout } from './layout.styles';
import { mqFrom } from './responsive.styles';
import { typescale } from './typescale.styles';
import { colors, fontFamily, fontWeights, unit } from './vars.styles';

const root = css`
  ${layout.wrapper};

  ${mqFrom.Lrg} {
    padding-top: ${unit * 10}px;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
`;

const textWrapper = css`
  padding: ${unit * 5}px 0;
`;

const titleWrapper = css`
  padding: ${unit * 2}px 0;
`;

const boldTitle = css`
  ${titleWrapper};
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightBold};

  ${typescale.large};

  color: ${colors.textSecondary};
`;

const title = css`
  ${titleWrapper};
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightRegular};

  ${typescale.xlarge};

  color: ${colors.textSecondary};
`;

const subtitle = css`
  ${title};
  ${typescale.large}
`;

const text = css`
  ${textWrapper};
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightRegular};

  ${typescale.small};

  color: ${colors.textSecondary};
`;

const boldText = css`
  ${text};
  font-weight: ${fontWeights.weightBold};
`;

const linkText = css`
  ${text};
  color: ${colors.primary};
`;

const noPaddingText = css`
  ${text};
  padding: 0;
  p,
  span {
    padding: 0;
  }
`;

const staticWrapper = css`
  ${layout.wrapper};

  ${mqFrom.Med} {
    padding: ${unit * 10}px ${unit * 60}px;
  }

  ${mqFrom.Lrg} {
    padding: ${unit * 10}px ${unit * 80}px;
  }

  display: flex;
  width: 100%;
  flex-direction: column;

  ol {
    list-style: decimal;
    li  {
      ${noPaddingText};
      padding-top: 20px;
      ol {
        list-style: circle;
        padding-left: ${unit * 6}px;
      }
    }
  }
`;

const privacyPolicyText = css`
  & > * + * {
    margin-top: ${unit * 2}px;
  }
`;

const privacyPolicySubtitle = css`
  margin-bottom: ${unit * 2}px 0;
`;

export const staticStyles = {
  root,
  boldTitle,
  title,
  subtitle,
  text,
  boldText,
  linkText,
  staticWrapper,
  noPaddingText,
  privacyPolicyText,
  privacyPolicySubtitle,
};
