import { graphql, useStaticQuery } from 'gatsby';

export const useRoofWindowModels = () => {
  const { roofWindowsModels } = useStaticQuery(graphql`
    query roofWindowsModels {
      roofWindowsModels {
        roofWindowModels
      }
    }
  `);

  const [
    tendaISjenilaZaProzore,
    toplinskaIzolacija,
    opsavZaKrovneProzore,
    project,
  ] = roofWindowsModels.roofWindowModels;

  const sortedAllRoofWindowModels = [
    project,
    tendaISjenilaZaProzore,
    toplinskaIzolacija,
    opsavZaKrovneProzore,
  ];

  return { sortedAllRoofWindowModels };
};
