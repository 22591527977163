import { Link } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';
import { layout } from 'styles';
import { slugify } from 'utils';

import { useFacadeModels } from '../hooks';

interface Props {
  visible: boolean;
  handleClose: VoidFunction;
}

export const FacadeSubnav: React.FC<Props> = ({ visible, handleClose }) => {
  const { facadeModels } = useFacadeModels();

  return (
    <ul css={[layout.subnav, visible ? layout.subnavVisible : null]}>
      <div css={layout.subnavCol}>
        <Link
          to="/fasada/pregled-svih-proizvoda"
          css={layout.model}
          onClick={handleClose}
        >
          <FormattedMessage id="navigation.all-items" />
        </Link>
      </div>

      {divideInChunks(8, facadeModels.facadeModels).map((chunk, index) => (
        <div key={index} css={layout.subnavCol}>
          {chunk.map((variant: string) => (
            <Link
              to={`/fasada/terca/${slugify(variant)}`}
              css={layout.model}
              key={variant}
              onClick={handleClose}
            >
              {variant}
            </Link>
          ))}
        </div>
      ))}
    </ul>
  );

  function divideInChunks(size: number, array: string[]) {
    const result = array.reduce<typeof array[]>((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / size);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);

    return result;
  }
};
