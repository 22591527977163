import React from 'react';

export const SelectChevron: React.FC = () => (
  <svg
    width="8"
    height="5"
    viewBox="0 0 8 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.5"
      d="M1 0.828369L3.82843 3.65679L6.65686 0.828369"
      stroke="black"
    />
  </svg>
);
