import { Copy } from 'components';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { DefaultPhoto, Product } from 'models';
import { CopyThunks } from 'modules/copy';
import { ApplicationState } from 'modules/redux-store';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { buttons, listing } from 'styles';
import { getProductRoute, useWindowDimensions } from 'utils';

interface Props {
  products: Product[];
}

export const ProductListing: React.FC<Props> = ({ products }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { width } = useWindowDimensions();
  const { showCopy } = useSelector((state: ApplicationState) => state.copy);

  const defaultImagePhoto = useStaticQuery<DefaultPhoto>(graphql`
    query {
      defaultImagePhoto: file(relativePath: { eq: "no-image-product.png" }) {
        id
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  if (products.length === 0) {
    return (
      <p>
        <FormattedMessage id="products.empty-list" />
      </p>
    );
  }

  const copy = (index: number) =>
    showCopy &&
    ((index === 3 && width >= 1200) || (index === 2 && width < 1200));

  function handleCloseClick() {
    dispatch(CopyThunks.removeCopy());
  }

  return (
    <ul css={listing.listWrapper}>
      {products.map((product, index) => {
        const productImage =
          product.commercial.assets.mainImg ||
          product.commercial.assets.otherImgs[0];

        return (
          <React.Fragment key={product.id}>
            {copy(index) && (
              <Copy
                title={formatMessage({ id: 'products.listing.copy.title' })}
                step1Title={formatMessage({
                  id: 'products.listing.copy.step1Title',
                })}
                step1Description={formatMessage({
                  id: 'products.listing.copy.step1Description',
                })}
                step2Title={formatMessage({
                  id: 'products.listing.copy.step2Title',
                })}
                step2Description={formatMessage({
                  id: 'products.listing.copy.step2Description',
                })}
                step3Title={formatMessage({
                  id: 'products.listing.copy.step3Title',
                })}
                step3Description={formatMessage({
                  id: 'products.listing.copy.step3Description',
                })}
                containerStyles={listing.copy}
                showCloseButton={true}
                handleCloseClick={handleCloseClick}
              />
            )}
            <li key={product.id} css={listing.listItem}>
              <Link to={getProductRoute(product.commercial)}>
                {productImage && (
                  <Image
                    fluid={{
                      src:
                        product.commercial.assets.mainImg ||
                        product.commercial.assets.otherImgs[0].imgUrl,
                      aspectRatio: 1,
                      srcSet: '',
                      sizes: '',
                    }}
                    imgStyle={{ height: 'auto' }}
                    css={listing.photo}
                  />
                )}

                {!productImage && (
                  <Image
                    {...defaultImagePhoto.defaultImagePhoto.childImageSharp}
                    alt="Bez slike"
                    css={listing.photo}
                    imgStyle={{ height: 'auto' }}
                  />
                )}
                <span css={listing.productText}>
                  {product.commercial.localizedName}
                </span>
                <button
                  type="button"
                  css={[buttons.primary.small, listing.button]}
                >
                  <FormattedMessage id="products.more" />
                </button>
              </Link>
            </li>
          </React.Fragment>
        );
      })}
    </ul>
  );
};
