import firebase from 'firebase/app';

import { User } from '../models';
import { AuthActions } from './actions';
import { AuthTypes } from './types';

export interface AuthenticationState {
  authStateChanging: boolean;
  isLoggedIn: boolean;
  user?: User;
  error?: firebase.FirebaseError | string;
  isUserAnon?: boolean;
}

const INITIAL_STATE: AuthenticationState = {
  authStateChanging: true,
  isLoggedIn: false,
  user: undefined,
  error: undefined,
  isUserAnon: undefined,
};

export default (
  state: AuthenticationState = INITIAL_STATE,
  action: AuthActions,
): AuthenticationState => {
  switch (action.type) {
    case AuthTypes.AuthStateChange:
      return {
        ...state,
        authStateChanging: true,
        error: undefined,
        isUserAnon: action.payload.isUserAnon,
      };
    case AuthTypes.LoginSuccess:
      return {
        ...state,
        authStateChanging: false,
        isLoggedIn: true,
        user: action.payload.user,
      };
    case AuthTypes.LoginError:
      return {
        ...state,
        authStateChanging: false,
        isLoggedIn: false,
        error: action.payload.error,
      };
    case AuthTypes.Logout:
      return {
        ...INITIAL_STATE,
        authStateChanging: false,
      };
    default:
      return state || INITIAL_STATE;
  }
};
