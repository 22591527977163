import { CloseSearch, SearchIcon } from 'assets';
import { useIntl } from 'gatsby-plugin-intl';
import { AppDispatch, ApplicationState } from 'modules/redux-store';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { layout } from 'styles';

import { SearchThunks } from '../redux';

interface Props {
  onClose: VoidFunction;
}

export const SearchInput: React.FC<Props> = ({ onClose }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();

  const { searchInput } = useSelector(
    (state: ApplicationState) => state.search,
  );

  return (
    <div css={layout.searchWrapper}>
      <SearchIcon disabled />
      <input
        type="search"
        id="search"
        autoFocus
        placeholder={formatMessage({ id: 'search.products-question' })}
        onChange={onInputChange}
        css={layout.searchInput}
        value={searchInput}
      />

      <button onClick={onClose} css={layout.closeSearchWrapper}>
        <CloseSearch />
      </button>
    </div>
  );

  function onInputChange(data: React.FormEvent<HTMLInputElement>) {
    dispatch(SearchThunks.setSearchInput(data.currentTarget.value));
  }
};
