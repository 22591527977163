import { SerializedStyles } from '@emotion/core';
import React, { useState } from 'react';

interface Props {
  className?: SerializedStyles;
  disabled?: boolean;
}

export const SearchIcon: React.FC<Props> = ({ className, disabled }) => {
  const [hover, setHover] = useState(false);

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={className}
      onMouseEnter={disabled ? undefined : handleEnter}
      onMouseLeave={disabled ? undefined : handleLeave}
    >
      <path
        d="M8.4375 15.1875C12.1654 15.1875 15.1875 12.1654 15.1875 8.4375C15.1875 4.70958 12.1654 1.6875 8.4375 1.6875C4.70958 1.6875 1.6875 4.70958 1.6875 8.4375C1.6875 12.1654 4.70958 15.1875 8.4375 15.1875Z"
        stroke={hover ? '#B30000' : 'black'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4375 17.4375L13.2097 13.2097"
        stroke={hover ? '#B30000' : 'black'}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  );

  function handleEnter() {
    setHover(true);
  }

  function handleLeave() {
    setHover(false);
  }
};
