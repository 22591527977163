import React from 'react';
import { fields, inputs, typescale } from 'styles';

interface Props {
  name: string;
  text: string;
  checked?: boolean;
  section?: string;
  onChange: (name: string, value: boolean, section?: string) => void;
}

export const ControlledCheckbox: React.FC<Props> = ({
  name,
  text,
  checked,
  onChange,
  section,
}) => {
  return (
    <div css={fields.field}>
      <label css={[inputs.inputCheckbox, typescale.small]}>
        <input
          type="checkbox"
          name={name}
          onChange={handleChange}
          id={name}
          checked={checked || false}
          data-section={section}
        />
        <span></span>
        {text}
      </label>
    </div>
  );

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, checked, dataset } = event.currentTarget;

    onChange(name, checked, dataset.section);
  }
};
