import { SerializedStyles } from '@emotion/core';
import React from 'react';

interface Props {
  className?: SerializedStyles | string[];
  stroke?: string;
}

export const ArchiveIcon: React.FC<Props> = ({ className, stroke }) => {
  return (
    <svg
      css={className}
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7344 1.29688H1.26562V4.39062H16.7344V1.29688Z"
        stroke={stroke || 'black'}
        strokeMiterlimit="10"
      />
      <path
        d="M14.6719 6.45312V14.7031H3.32812V6.45312"
        stroke={stroke || 'black'}
        strokeMiterlimit="10"
      />
      <path
        d="M11.5781 7.48438H6.42188V10.5781H11.5781V7.48438Z"
        stroke={stroke || 'black'}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};
