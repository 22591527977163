import React from 'react';

export const HamburgerIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.4375 6.375H18.5625"
      stroke="#B30000"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M5.25 12H18.75"
      stroke="#B30000"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M5.4375 17.625H18.5625"
      stroke="#B30000"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
