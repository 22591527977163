import { FirebaseFunctionsService } from 'modules/firebase';
import { ApplicationState } from 'modules/redux-store';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { InteractionProduct } from '../models';
import {
  generateUniqueId,
  getCommunicationMedium,
  getContactIdOrigin,
  getInteractionType,
  getTimestamp,
} from '../utils';

interface CartItemsInteractionInput {
  interactionProducts: InteractionProduct[];
}

interface ContactInteractionInput {
  uid: string;
  firstName: string;
  lastName: string;
  email: string;
  isSubscribed?: boolean;
}

interface BusinessContact {
  companyName?: string;
  /* companyEmail?: string;
  companyPhone?: string;
  companyAddress?: string;
  companyPostcode?: string;
  companyCity?: string; */
}

interface OfferContactInteractionInput {
  address: string;
  postcode: string;
  city: string;
  country: string;
  email?: string;
  firstName: string;
  lastName: string;
  phone: string;
  isSubscribed: boolean;
  company?: BusinessContact;
}

const funcService = FirebaseFunctionsService();

export const useAnalytics = () => {
  const { user } = useSelector((state: ApplicationState) => state.auth);

  const getCartInteractionData = useCallback(
    ({ interactionProducts }: CartItemsInteractionInput, uid?: string) => {
      return {
        Timestamp: getTimestamp(),
        CommunicationMedium: getCommunicationMedium(),
        ContactIdOrigin: getContactIdOrigin(),
        InteractionType: getInteractionType(),
        InteractionReason: 'CART_ITEMS',
        ContactId: uid || user?.uid,
        MarketingAreaID: 'ZBU01_HR',
        SourceDataUrl: location.href,
        WebFormRequestUID: generateUniqueId(32),
        Products: {
          InteractionProduct: interactionProducts,
        },
      };
    },
    [user],
  );

  const getInteractionData = useCallback(
    (interactionReason: string, uid?: string) => {
      return {
        Timestamp: getTimestamp(),
        CommunicationMedium: getCommunicationMedium(),
        ContactIdOrigin: getContactIdOrigin(),
        InteractionType: getInteractionType(),
        InteractionReason: interactionReason,
        ContactId: uid || user?.uid,
        MarketingAreaID: 'ZBU01_HR',
        SourceDataUrl: location.href,
        WebFormRequestUID: generateUniqueId(32),
      };
    },
    [user],
  );

  const getCartItemsRequestConfig = useCallback(
    (data: CartItemsInteractionInput, uid?: string) => {
      return {
        ImportHeaders: {
          ImportHeader: {
            UserName: '', // Should be left empty
            Timestamp: getTimestamp(),
            Id: getTimestamp() + generateUniqueId(4),
            SystemID: process.env.GATSBY_SYSTEM_ID,
            SystemType: process.env.GATSBY_SYSTEM_ID,
            Interactions: {
              Interaction: {
                ...getCartInteractionData(data, uid),
              },
            },
          },
        },
      };
    },
    [getCartInteractionData],
  );

  const getRegistrationRequestConfig = useCallback(
    (uid: string) => {
      return {
        ImportHeaders: {
          ImportHeader: {
            UserName: '', // Should be left empty
            Timestamp: getTimestamp(),
            Id: getTimestamp() + generateUniqueId(4),
            SystemID: process.env.GATSBY_SYSTEM_ID,
            SystemType: process.env.GATSBY_SYSTEM_ID,
            Interactions: {
              Interaction: {
                ...getInteractionData('SEE_REGISTRATION', uid),
              },
            },
          },
        },
      };
    },
    [getInteractionData],
  );

  const getOfferInteractionConfig = useCallback(
    (email: string) => {
      return {
        ImportHeaders: {
          ImportHeader: {
            UserName: '', // Should be left empty
            Timestamp: getTimestamp(),
            Id: getTimestamp() + generateUniqueId(4),
            SystemID: process.env.GATSBY_SYSTEM_ID,
            SystemType: process.env.GATSBY_SYSTEM_ID,
            Interactions: {
              Interaction: {
                ...getInteractionData('REQUEST_AN_OFFER'),
                ContentData: email,
              },
            },
          },
        },
      };
    },
    [getInteractionData],
  );

  const getContactConfig = useCallback(
    ({
      firstName,
      lastName,
      email,
      uid,
      isSubscribed,
    }: ContactInteractionInput) => {
      return {
        importHeaders: {
          importHeader: {
            userName: '', // Should be left empty
            timestamp: getTimestamp(),
            id: generateUniqueId(32),
            systemID: process.env.GATSBY_SYSTEM_ID,
            systemType: process.env.GATSBY_SYSTEM_ID,
            interactionContacts: {
              interactionContact: {
                firstName: firstName,
                lastName: lastName,
                email: email,
                marketingAreaID: 'ZBU01_HR',
                interactionType: 'SEE_QUOTE_TOOL',
                contactIdOrigin: 'SEE_QUOTE_PLATFORM',
                communicationMedium: 'WEB',
                contactId: uid,
                permissions: {
                  permission: [
                    {
                      contactPermissionID: email,
                      contactPermissionOrigin: 'EMAIL',
                      communicationMedium: 'EMAIL',
                      timestampConsent: getTimestamp(),
                      optIn: 'true',
                      isConfirmationRequired: 'true',
                    },
                  ],
                },
                subscriptions: isSubscribed && {
                  subscription: [
                    {
                      contactSubscriptionID: email,
                      contactSubscriptionOrigin: 'EMAIL',
                      communicationMedium: 'EMAIL',
                      timestampConsent: getTimestamp(),
                      categoryID: process.env.GATSBY_SUBSCRIPTION_CODE,
                      optIn: 'true',
                      isConfirmationRequired: 'false',
                    },
                  ],
                },
              },
            },
          },
        },
      };
    },
    [],
  );

  const getOfferContactConfig = useCallback(
    ({
      address,
      postcode,
      city,
      country,
      firstName,
      lastName,
      email,
      phone,
      company,
      isSubscribed,
    }: OfferContactInteractionInput) => {
      return {
        importHeaders: {
          importHeader: {
            userName: '', // Should be left empty
            timestamp: getTimestamp(),
            id: generateUniqueId(32),
            systemID: process.env.GATSBY_SYSTEM_ID,
            systemType: process.env.GATSBY_SYSTEM_ID,
            interactionContacts: {
              interactionContact: {
                firstName: firstName,
                lastName: lastName,
                email: email || user?.email,
                streetname: address,
                postalcode: postcode,
                cityName: city,
                country: country,
                company: company?.companyName,
                mobileNumber: phone,
                marketingAreaID: 'ZBU01_HR',
                interactionType: 'SEE_QUOTE_TOOL',
                contactIdOrigin: 'SEE_QUOTE_PLATFORM',
                communicationMedium: 'WEB',
                contactId: user?.uid,
                permissions: {
                  permission: [
                    {
                      contactPermissionID: email || user?.email,
                      contactPermissionOrigin: 'EMAIL',
                      communicationMedium: 'EMAIL',
                      timestampConsent: getTimestamp(),
                      optIn: 'true',
                      isConfirmationRequired: 'true',
                    },
                  ],
                },
                subscriptions: (isSubscribed || user?.subscription) && {
                  subscription: [
                    {
                      contactSubscriptionID: email || user?.email,
                      contactSubscriptionOrigin: 'EMAIL',
                      communicationMedium: 'EMAIL',
                      timestampConsent: getTimestamp(),
                      categoryID: process.env.GATSBY_SUBSCRIPTION_CODE,
                      optIn: 'true',
                      isConfirmationRequired: 'false',
                    },
                  ],
                },
              },
            },
          },
        },
      };
    },
    [user],
  );

  const sendCartItemsInteraction = useCallback(
    async (data: CartItemsInteractionInput, uid?: string) => {
      if (!process.env.GATSBY_INTERACTION_ENDPOINT) return;
      await (
        await funcService
      ).sendInteractionToApi(
        process.env.GATSBY_INTERACTION_ENDPOINT,
        getCartItemsRequestConfig(data, uid),
      );
    },

    [getCartItemsRequestConfig],
  );

  const sendRegistrationInteraction = useCallback(
    async (uid: string) => {
      if (!process.env.GATSBY_INTERACTION_ENDPOINT) return;
      await (
        await funcService
      ).sendInteractionToApi(
        process.env.GATSBY_INTERACTION_ENDPOINT,
        getRegistrationRequestConfig(uid),
      );
    },
    [getRegistrationRequestConfig],
  );

  const sendContactInteraction = useCallback(
    async (data: ContactInteractionInput) => {
      if (!process.env.GATSBY_CONTACT_ENDPOINT) return;
      await (
        await funcService
      ).sendInteractionToApi(
        process.env.GATSBY_CONTACT_ENDPOINT,
        getContactConfig(data),
      );
    },
    [getContactConfig],
  );

  const sendOfferContact = useCallback(
    async (data: OfferContactInteractionInput) => {
      if (!process.env.GATSBY_CONTACT_ENDPOINT) return;
      await (
        await funcService
      ).sendInteractionToApi(
        process.env.GATSBY_CONTACT_ENDPOINT,
        getOfferContactConfig(data),
      );
    },
    [getOfferContactConfig],
  );

  const sendOfferInteraction = useCallback(
    async (email: string) => {
      if (!process.env.GATSBY_INTERACTION_ENDPOINT) return;
      await (
        await funcService
      ).sendInteractionToApi(
        process.env.GATSBY_INTERACTION_ENDPOINT,
        getOfferInteractionConfig(email),
      );
    },
    [getOfferInteractionConfig],
  );

  return {
    sendCartItemsInteraction,
    sendOfferContact,
    sendOfferInteraction,
    sendRegistrationInteraction,
    sendContactInteraction,
  };
};
