import { css } from '@emotion/core';

import { color } from './mixins.styles';
import { spacers } from './spacers.styles';
import { colors, globalRadial, unit } from './vars.styles';

const container = css`
  position: relative;
  display: block;
  padding: ${unit * 4}px ${unit * 6}px;
  border-radius: ${globalRadial}px;
  ${spacers.bottom.xLarge}
`;

const success = css`
  ${container}

  color: ${colors.success};
  background-color: ${color(colors.success, 0.1)};
`;

const error = css`
  ${container}

  color: ${colors.error};
  background-color: ${color(colors.error, 0.1)};
`;

const info = css`
  ${container}

  color: ${colors.info};
  background-color: ${color(colors.info, 0.1)};
`;

const warning = css`
  ${container}

  color: ${colors.warning};
  background-color: ${color(colors.warning, 0.1)};
`;

export const alert = {
  success,
  error,
  info,
  warning,
};
