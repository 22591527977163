import React from 'react';

export const Step1: React.FC = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 11.5H1.5C1.23478 11.5 0.98043 11.3946 0.792893 11.2071C0.605357 11.0196 0.5 10.7652 0.5 10.5V1.5C0.5 1.23478 0.605357 0.98043 0.792893 0.792893C0.98043 0.605357 1.23478 0.5 1.5 0.5H10.5C10.7652 0.5 11.0196 0.605357 11.2071 0.792893C11.3946 0.98043 11.5 1.23478 11.5 1.5V3.5"
      stroke="#B30000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5 11.5L6.5 6.5"
      stroke="#B30000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 10.5V6.5H10.5"
      stroke="#B30000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
