import { Location } from '../models';

export function haversineDistance(loc1: Location, loc2: Location) {
  const R = 6371.071; // Radius of the Earth in km
  const rlat1 = loc1.lat * (Math.PI / 180); // Convert degrees to radians
  const rlat2 = loc2.lat * (Math.PI / 180); // Convert degrees to radians
  const difflat = rlat2 - rlat1; // Radian difference (latitudes)
  const difflon = (loc2.lng - loc1.lng) * (Math.PI / 180); // Radian difference (longitudes)

  const distance =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) *
            Math.cos(rlat2) *
            Math.sin(difflon / 2) *
            Math.sin(difflon / 2),
      ),
    );
  return +distance.toFixed(0);
}
