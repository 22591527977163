import { pdf } from '@react-pdf/renderer';
import { Distributor } from 'modules/distributors';
import {
  ClientRequest,
  MessageLocation,
  RequestsActions,
  RequestsService,
  RequestsThunks,
} from 'modules/inbox';
import { OfferPdf } from 'modules/pdf';
import { AppDispatch, ApplicationState } from 'modules/redux-store';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isMessageLocationType } from 'utils';

import { SuccessToast } from '../components';
import {
  ExternalOfferModel,
  InternalOfferModel,
  OfferSummary,
  ProductOffer,
} from '../models';

export interface OfferState {
  loading: boolean;
  distributorRemark: string;
  productOffers: ProductOffer[];
  paleteAmount: number;
  paleteRebate: number;
  paletePrice: number;
  shippingPrice: number;
  externalDocumentUrl?: string;
  externalDocumentName?: string;
}

export const useDistributorInbox = (distributors: Distributor[]) => {
  const dispatch = useDispatch<AppDispatch>();
  const { clientRequests, requestsAreChanging, error } = useSelector(
    (state: ApplicationState) => state.clientRequests,
  );
  const [currentRequest, setCurrentRequest] =
    useState<
      ClientRequest & {
        index: number;
      }
    >();
  const [activeMailCategory, setActiveMailCategory] =
    useState<MessageLocation>('inbox');
  const [searchText, setSearchText] = useState('');
  const [offerType, setOfferType] =
    useState<'internal' | 'external' | null>(null);
  const [newOfferOpened, toggleNewOfferOpened] = useState(false);
  const [offerState, setOfferState] = useState<OfferState>({
    loading: false,
    distributorRemark: '',
    productOffers: currentRequest?.productRequests
      ? [...currentRequest.productRequests.table]
      : [],
    shippingPrice: 0,
    paleteAmount: 1,
    paleteRebate: 5,
    paletePrice: 43.5,
  });
  const [offerSummary, setOfferSummary] = useState<OfferSummary>({
    totalWithoutTax: 0,
    rebate: 0,
    tax: 0,
    total: 0,
  });
  const [isDistributorActive, setIsDistributorActive] = useState<boolean>(true);

  useEffect(() => {
    const totalWithoutTax =
      offerState.productOffers.reduce(
        (acc, offer) => acc + (offer.price || 0) * offer.amount,
        0,
      ) +
      offerState.paletePrice * offerState.paleteAmount;
    const rebate =
      offerState.productOffers.reduce(
        (acc, offer) =>
          acc + (offer.price || 0) * offer.amount * ((offer.rebate || 0) / 100),
        0,
      ) +
      offerState.paletePrice *
        offerState.paleteAmount *
        (offerState.paleteRebate / 100);
    const tax = (totalWithoutTax - rebate) * 0.25;
    const total = totalWithoutTax - rebate + offerState.shippingPrice + tax;

    setOfferSummary({
      totalWithoutTax,
      rebate,
      tax,
      total,
    });
  }, [
    offerState.productOffers,
    offerState.shippingPrice,
    offerState.paleteAmount,
    offerState.paletePrice,
    offerState.paleteRebate,
  ]);

  useEffect(() => {
    if (currentRequest && !currentRequest.distributorRead) {
      dispatch(
        RequestsThunks.setIsRead(currentRequest.id || '', 'distributorRead'),
      );
    }
  }, [currentRequest, dispatch]);

  useEffect(() => {
    resetCurrentRequest();
  }, [activeMailCategory]);

  useEffect(() => {
    distributors.forEach((dist) => {
      if (dist) dispatch(RequestsThunks.getAllRequestsFromClients(dist.id));
    });
    if (distributors.some((dist) => !dist.isActive))
      setIsDistributorActive(false);
  }, [dispatch, distributors]);

  useEffect(() => {
    setOfferState({
      loading: false,
      distributorRemark: '',
      productOffers: currentRequest?.productRequests?.table
        ? [
            ...currentRequest.productRequests.table.map((request) => ({
              ...new ProductOffer(request),
            })),
          ]
        : [],
      shippingPrice: 0,
      paleteAmount: 1,
      paleteRebate: 5,
      paletePrice: 43.5,
    });

    setOfferType(currentRequest?.offerType || null);
    toggleNewOfferOpened(false);
  }, [currentRequest]);

  const currentMailCategoryRequests = clientRequests.filter(
    (request) => request.distributorMsgLocation === activeMailCategory,
  );

  const filteredClientRequests = (
    searchText.length >= 2
      ? currentMailCategoryRequests
          .filter((request) =>
            `${request.clientInformation?.firstName} ${request.clientInformation?.lastName}`
              .toLowerCase()
              .includes(searchText.toLowerCase()),
          )
          .map((request, index) => ({ ...request, index }))
      : currentMailCategoryRequests.map((request, index) => ({
          ...request,
          index,
        }))
  ).sort((a, b) => b.date - a.date);

  const answeredClientRequests = (
    searchText.length >= 2
      ? clientRequests
          .filter((request) => request.isAnswered)
          .filter((request) =>
            `${request.clientInformation?.firstName} ${request.clientInformation?.lastName}`
              .toLowerCase()
              .includes(searchText.toLowerCase()),
          )
          .map((request, index) => ({ ...request, index }))
      : clientRequests
          .filter((request) => request.isAnswered)
          .map((request, index) => ({
            ...request,
            index,
          }))
  ).sort((a, b) => b.date - a.date);

  function handleMailCategoryChange(
    event: React.MouseEvent<HTMLButtonElement>,
  ) {
    const { category } = event.currentTarget.dataset;
    if (!category || !isMessageLocationType(category)) return;

    setActiveMailCategory(category);
  }

  function handleRequestMailClick(
    message: ClientRequest & {
      index: number;
    },
  ) {
    setCurrentRequest(message);
  }

  function handleRequestForwardClick() {
    if (!currentRequest) return;

    const currentRequestGroup =
      activeMailCategory === 'sent'
        ? answeredClientRequests
        : filteredClientRequests;

    const currentIndex = currentRequest.index;
    const newMessage = currentRequestGroup.find(
      (request) => request.index === currentIndex + 1,
    );
    if (!newMessage) return;

    setCurrentRequest(newMessage);
  }

  function handleRequestBackwardClick() {
    if (!currentRequest) return;

    const currentRequestGroup =
      activeMailCategory === 'sent'
        ? answeredClientRequests
        : filteredClientRequests;

    const currentIndex = currentRequest.index;
    const newMessage = currentRequestGroup.find(
      (request) => request.index === currentIndex - 1,
    );
    if (!newMessage) return;

    setCurrentRequest(newMessage);
  }

  function moveRequestToArchive() {
    if (!currentRequest) return;

    dispatch(
      RequestsThunks.moveToArchive(currentRequest.id || '', 'distributor'),
    );
    setCurrentRequest(undefined);
    toast.success(
      <SuccessToast
        title=" "
        text="Uspješno ste premjestili zahtjev u arhivu"
      />,
    );
  }

  function moveRequestToTrash() {
    if (!currentRequest) return;

    dispatch(
      RequestsThunks.moveToTrash(currentRequest.id || '', 'distributor'),
    );
    setCurrentRequest(undefined);
    toast.success(
      <SuccessToast
        title=" "
        text="Uspješno ste premjestili zahtjev u smeće"
      />,
    );
  }

  function handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const { name, value } = event.currentTarget;

    if (name === 'distributorRemark') {
      setOfferState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      return;
    }

    const valueAsFloat = parseFloat(value);
    const checkValue = valueAsFloat;

    setOfferState((prevState) => ({
      ...prevState,
      [name]: checkValue,
    }));
  }

  function handleProductOffersChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    const { name, valueAsNumber, dataset } = event.currentTarget;
    const { index } = dataset;
    if (!index) return;

    setOfferState((prevState) => {
      const newProductOffers = [...prevState.productOffers];
      newProductOffers[index][name] = valueAsNumber;

      return {
        ...prevState,
        productOffers: newProductOffers,
      };
    });
  }

  async function uploadExternalOfferDocument(file: File) {
    if (!currentRequest) return;

    setOfferState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    const externalDocumentUrl =
      await RequestsService.Storage.addFileAndGetDocUrl(
        currentRequest.clientRequestId,
        file,
      );
    if (!externalDocumentUrl) {
      dispatch(
        RequestsActions.addError('Greška prilikom prenošenja dokumenta'),
      );
      return;
    }

    setOfferState((prevState) => ({
      ...prevState,
      externalDocumentUrl,
      externalDocumentName: file.name,
      loading: false,
    }));
  }

  function removeExternalDocument() {
    if (!offerState.externalDocumentUrl) return;

    RequestsService.Storage.deleteFile(offerState.externalDocumentUrl);
    setOfferState((prevState) => ({
      ...prevState,
      externalDocumentUrl: undefined,
    }));
  }

  async function createNewOffer() {
    if (!currentRequest) return;

    const {
      id,
      internalOffers,
      externalOffers,
      clientRequestId,
      clientInformation,
      distributorId,
    } = currentRequest;
    const {
      productOffers,
      paleteAmount,
      paleteRebate,
      paletePrice,
      shippingPrice,
      externalDocumentUrl,
      externalDocumentName,
      distributorRemark,
    } = offerState;

    const currentDistributor = distributors.find(
      (dist: Distributor) => dist.id === distributorId,
    );

    let fileDetails: {
      fileReader: FileReader | null;
      fileName: string;
    } = {
      fileReader: null,
      fileName: '',
    };

    if (offerType === 'internal') {
      const newInternalOffers = [
        ...internalOffers,
        {
          ...new InternalOfferModel({
            productOffers,
            palete: {
              amount: paleteAmount,
              rebate: paleteRebate,
              price: paletePrice,
            },
            shippingPrice,
            distributorRemark,
            summary: offerSummary,
          }),
        },
      ];

      // Creating PDF file from OfferPdf component
      const offerPdf = (
        <OfferPdf
          currentRequest={currentRequest}
          distributors={distributors}
          offer={newInternalOffers[newInternalOffers.length - 1]}
        />
      );
      const blobPdf = await pdf(offerPdf).toBlob();
      const pdfFile = new File(
        [blobPdf],
        `WEBSHOP_${currentRequest?.clientRequestId}`,
        { lastModified: Date.now(), type: 'application/pdf' },
      );

      const fileReader = new FileReader();
      fileReader.readAsDataURL(pdfFile);

      fileDetails = {
        fileReader: fileReader,
        fileName: pdfFile.name,
      };

      await dispatch(
        RequestsThunks.addNewOffer(
          {
            id,
            offerType,
            internalOffers: newInternalOffers,
            isAnswered: true,
            clientMsgLocation: 'inbox',
            date: Date.now(),
            clientRead: false,
            clientRequestId,
            clientInformation,
            fileDetails,
          },
          currentDistributor,
          true,
        ),
      );

      toggleNewOfferOpened(false);
      setCurrentRequest(undefined);
      toast.success(SuccessToast);
      return;
    }

    const newExternalOffers = [
      ...externalOffers,
      {
        ...new ExternalOfferModel({
          documentUrl: externalDocumentUrl,
          documentName: externalDocumentName,
          distributorRemark,
        }),
      },
    ];

    await dispatch(
      RequestsThunks.addNewOffer(
        {
          id,
          offerType,
          externalOffers: newExternalOffers,
          isAnswered: true,
          clientMsgLocation: 'inbox',
          date: Date.now(),
          clientRead: false,
          clientRequestId,
          clientInformation,
        },
        currentDistributor,
        true,
      ),
    );
    toggleNewOfferOpened(false);
    setCurrentRequest(undefined);

    toast.success(SuccessToast);
  }

  function deleteExternalOffer(externalOffer: ExternalOfferModel) {
    if (!currentRequest?.externalOffers) return;

    const newExternalOffers = [...currentRequest.externalOffers];
    const index = newExternalOffers.findIndex(
      (offer) => offer.documentUrl === externalOffer.documentUrl,
    );
    if (index === -1) return;

    newExternalOffers.splice(index, 1);
    setCurrentRequest({
      ...currentRequest,
      externalOffers: newExternalOffers,
      offerType:
        newExternalOffers.length === 0 ? null : currentRequest.offerType,
      isAnswered:
        newExternalOffers.length === 0 ? false : currentRequest.isAnswered,
    });

    dispatch(
      RequestsThunks.addNewOffer({
        id: currentRequest.id,
        externalOffers: newExternalOffers,
        offerType:
          newExternalOffers.length === 0 ? null : currentRequest.offerType,
        isAnswered:
          newExternalOffers.length === 0 ? false : currentRequest.isAnswered,
      }),
    );

    RequestsService.Storage.deleteFile(externalOffer.documentUrl);
    toggleNewOfferOpened(false);
    toast.success(
      <SuccessToast
        title="Uspješno brisanje"
        text="Uspješno ste obrisali eksternu ponudu"
      />,
    );
  }

  function deleteInternalOffer(internalOffer: InternalOfferModel) {
    if (!currentRequest?.internalOffers) return;

    const newInternalOffers = [...currentRequest.internalOffers];
    const index = newInternalOffers.findIndex(
      (offer) => offer.date === internalOffer.date,
    );
    if (index === -1) return;

    newInternalOffers.splice(index, 1);
    setCurrentRequest({
      ...currentRequest,
      internalOffers: newInternalOffers,
      offerType:
        newInternalOffers.length === 0 ? null : currentRequest.offerType,
      isAnswered:
        newInternalOffers.length === 0 ? false : currentRequest.isAnswered,
    });

    dispatch(
      RequestsThunks.addNewOffer({
        id: currentRequest.id,
        internalOffers: newInternalOffers,
        offerType:
          newInternalOffers.length === 0 ? null : currentRequest.offerType,
        isAnswered:
          newInternalOffers.length === 0 ? false : currentRequest.isAnswered,
      }),
    );

    toggleNewOfferOpened(false);
    toast.success(
      <SuccessToast
        title="Uspješno brisanje"
        text="Uspješno ste obrisali internu ponudu"
      />,
    );
  }

  function resetCurrentRequest() {
    setCurrentRequest(undefined);
  }

  return {
    error,
    filteredClientRequests,
    answeredClientRequests,
    requestsAreChanging,
    currentRequest,
    activeMailCategory,
    searchText,
    offerType,
    offerState,
    offerSummary,
    newOfferOpened,
    isDistributorActive,
    toggleNewOfferOpened,
    setSearchText,
    setOfferType,
    handleMailCategoryChange,
    handleRequestMailClick,
    handleRequestForwardClick,
    handleRequestBackwardClick,
    moveRequestToArchive,
    moveRequestToTrash,
    handleChange,
    handleProductOffersChange,
    uploadExternalOfferDocument,
    removeExternalDocument,
    createNewOffer,
    deleteExternalOffer,
    deleteInternalOffer,
    resetCurrentRequest,
  };
};
