import { css } from '@emotion/core';

import { fontSize, lineHeight } from './vars.styles';

const huge = css`
  font-size: ${fontSize.huge}px;
  line-height: ${lineHeight.huge}px;
`;

const xxxlarge = css`
  font-size: ${fontSize.xxxlarge}px;
  line-height: ${lineHeight.xxxlarge}px;
`;

const xxlarge = css`
  font-size: ${fontSize.xxlarge}px;
  line-height: ${lineHeight.xxlarge}px;
`;

const xlarge = css`
  font-size: ${fontSize.xlarge}px;
  line-height: ${lineHeight.xlarge}px;
`;

const large = css`
  font-size: ${fontSize.large}px;
  line-height: ${lineHeight.large}px;
`;

const medium = css`
  font-size: ${fontSize.medium}px;
  line-height: ${lineHeight.medium}px;
`;

const base = css`
  font-size: ${fontSize.base}px;
  line-height: ${lineHeight.base}px;
`;

const small = css`
  font-size: ${fontSize.small}px;
  line-height: ${lineHeight.small}px;
`;

const tiny = css`
  font-size: ${fontSize.tiny}px;
  line-height: ${lineHeight.tiny}px;
`;

const xtiny = css`
  font-size: ${fontSize.xTiny}px;
  line-height: ${lineHeight.xTiny}px;
`;

export const typescale = {
  huge,
  xxxlarge,
  xxlarge,
  xlarge,
  large,
  medium,
  base,
  small,
  tiny,
  xtiny,
};
