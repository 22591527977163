import 'firebase/auth';
import 'firebase/functions';

import firebase from 'firebase/app';

/**
 * FirebaseService needs to implement singleton pattern for creating and
 * maintaining its instance - it's created as a IIFE
 */
export const FirebaseService = (() => {
  let instance: firebase.app.App;

  let providers: {
    fb: firebase.auth.FacebookAuthProvider;
    google: firebase.auth.GoogleAuthProvider;
    email: (email: string, password: string) => firebase.auth.AuthCredential;
  };

  const firebaseConfig = {
    apiKey: process.env.GATSBY_API_KEY,
    authDomain: process.env.GATSBY_AUTH_DOMAIN,
    projectId: process.env.GATSBY_PROJECT_ID,
    storageBucket: process.env.GATSBY_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_MESSAGING_SENDER_ID,
    appId: process.env.GATSBY_APP_ID,
  };

  function Instance() {
    if (typeof window === 'undefined') return null;

    if (instance) {
      return { instance, providers };
    }

    instance = firebase.initializeApp(firebaseConfig);
    providers = {
      fb: new firebase.auth.FacebookAuthProvider(),
      google: new firebase.auth.GoogleAuthProvider(),
      email: firebase.auth.EmailAuthProvider.credential,
    };

    if (process.env.GATSBY_FIREBASE_ENV === 'local') {
      console.log('using emulators');
      firebase.auth().useEmulator('http://localhost:9099');
      firebase.functions().useEmulator('localhost', 5001);
    }

    return { instance, providers };
  }

  return { Instance };
})();
