import { RoofCalc } from 'modules/products';

export function isRoofCalc(item: unknown): item is RoofCalc {
  return (
    item === 'gableRoof' ||
    item === 'singleRoof' ||
    item === 'LType' ||
    item === 'hippedRoof'
  );
}
