import { SerializedStyles } from '@emotion/core';
import React from 'react';

interface Props {
  className: SerializedStyles;
}

export const GreenDotIcon: React.FC<Props> = ({ className }) => (
  <svg
    css={className}
    width="6"
    height="6"
    viewBox="0 0 6 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="3" cy="3" r="3" fill="#1EAD55" />
  </svg>
);
