import React from 'react';

export const CloseIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.4">
      <path
        d="M19.75 12C19.75 16.2802 16.2802 19.75 12 19.75C7.71979 19.75 4.25 16.2802 4.25 12C4.25 7.71979 7.71979 4.25 12 4.25C16.2802 4.25 19.75 7.71979 19.75 12Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        id="hover"
      />
      <path
        d="M15.5357 8.46387L8.46423 15.5353"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinejoin="round"
        id="hover"
      />
      <path
        d="M8.46423 8.46387L15.5357 15.5353"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinejoin="round"
        id="hover"
      />
    </g>
  </svg>
);
