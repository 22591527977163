import { graphql, useStaticQuery } from 'gatsby';

export const useFacadeModels = () => {
  const { facadeModels } = useStaticQuery(graphql`
    query facadeModels {
      facadeModels {
        facadeModels
      }
    }
  `);

  return { facadeModels };
};
