import { pdf } from '@react-pdf/renderer';
import { RequestPdf } from 'modules/pdf';
import React from 'react';

import { CartProduct, ClientInformation } from '..';

export const createPdfRequest = () => {
  async function getPdfFile(
    clientInformation: ClientInformation,
    products: CartProduct[],
  ) {
    const requestPdf = (
      <RequestPdf clientInfo={clientInformation} products={products} />
    );

    const blobPdf = await pdf(requestPdf).toBlob();

    const pdfFile = new File(
      [blobPdf],
      `WEBSHOP_${clientInformation.firstName} ${clientInformation.lastName}}`,
      { lastModified: Date.now(), type: 'application/pdf' },
    );

    const fileReader = new FileReader();
    fileReader.readAsDataURL(pdfFile);

    const fileDetails = {
      fileReader: fileReader,
      fileName: pdfFile.name,
    };

    return fileDetails;
  }

  return { getPdfFile };
};
