/**
 * Color variables
 */
export const colors = {
  primary: '#B30000',
  secondary: '#EDF1F7',
  dark: '#000000',
  error: '#F13456',
  success: '#31B468',
  info: '#0069B4',
  warning: '#EBAA2B',
  text: '#555555',
  textSecondary: '#3C3737',
  backgroundGray: '#F8F8F8',
  gray: '#DEDEDE',
  bg: '#FFFFFF',
};

/**
 * Project base line height and font size
 */
export const baseLineHeight = 24;
export const baseFontSize = 16;

export const fontSize = {
  xTiny: 10,
  tiny: 12,
  small: 14,
  base: baseFontSize,
  medium: 20,
  large: 25,
  xlarge: 31,
  xxlarge: 39,
  xxxlarge: 49,
  huge: 61,
};

export const lineHeight = {
  xTiny: 12,
  tiny: 16,
  small: 20,
  base: baseLineHeight,
  medium: 28,
  large: 36,
  xlarge: 40,
  xxlarge: 48,
  xxxlarge: 56,
  huge: 76,
};

/**
 * Type weights
 */
export const fontWeights = {
  weightRegular: 400,
  weightBold: 700,
};

/**
 * Font families
 */

export const fontFamily = {
  sans: "'DM Sans', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto','Oxygen-Sans', 'Ubuntu', 'Cantarell',\
    'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
  serif: 'Georgia, Times, serif',
};

/**
 * Units and spacers
 */
export const unit = 4;
export const spacer = unit * 4;
export const globalRadial = 4;
