import { SerializedStyles } from '@emotion/core';
import React from 'react';

interface Props {
  className?: SerializedStyles | string[];
}

export const FiltersIcon: React.FC<Props> = ({ className }) => (
  <svg
    css={className}
    width="20"
    height="20"
    viewBox="0 0 16 16"
    fill="none"
    opacity="0.5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="M3.78125 4.71875V0.96875" stroke="black" strokeMiterlimit="10" />
      <path d="M3.78125 15.0312V13.1562" stroke="black" strokeMiterlimit="10" />
      <path d="M12.2188 11.2812V15.0312" stroke="black" strokeMiterlimit="10" />
      <path d="M12.2188 0.96875V2.84375" stroke="black" strokeMiterlimit="10" />
      <path
        d="M3.78125 13.1562C5.33455 13.1562 6.59375 11.8971 6.59375 10.3438C6.59375 8.79045 5.33455 7.53125 3.78125 7.53125C2.22795 7.53125 0.96875 8.79045 0.96875 10.3438C0.96875 11.8971 2.22795 13.1562 3.78125 13.1562Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2188 8.46875C13.7721 8.46875 15.0312 7.20955 15.0312 5.65625C15.0312 4.10295 13.7721 2.84375 12.2188 2.84375C10.6654 2.84375 9.40625 4.10295 9.40625 5.65625C9.40625 7.20955 10.6654 8.46875 12.2188 8.46875Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="15"
          height="15"
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
