import React from 'react';

export const CloseSearch: React.FC = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.8"
      d="M9.41987 6.96724L15.6323 0.708424L17.3752 2.45131L11.1601 8.71276L10.8092 9.06631L11.1614 9.41855L17.1254 15.3825L15.3405 17.1324L9.36955 11.1614L9.01468 10.8066L8.66114 11.1628L2.44868 17.4216L0.705795 15.6787L6.92087 9.41724L7.2718 9.06369L6.91955 8.71145L0.955625 2.74752L2.74052 0.997623L8.71145 6.96855L9.06632 7.32342L9.41987 6.96724Z"
      fill="#3C3737"
      stroke="white"
    />
  </svg>
);
