import { SearchActions } from './actions';
import { SearchTypes } from './types';

export interface SearchState {
  isActive: boolean;
  searchInput: string;
}

const INITIAL_STATE: SearchState = {
  isActive: false,
  searchInput: '',
};

export default (
  state: SearchState = INITIAL_STATE,
  action: SearchActions,
): SearchState => {
  switch (action.type) {
    case SearchTypes.SetIsActive: {
      return { ...state, isActive: action.payload.value };
    }

    case SearchTypes.SetSearchInput: {
      return { ...state, searchInput: action.payload.value };
    }
    default:
      return state || INITIAL_STATE;
  }
};
