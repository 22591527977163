import { SerializedStyles } from '@emotion/core';
import { ErrorMessage } from '@hookform/error-message';
import { TooltipIcon } from 'assets';
import React, { useState } from 'react';
import { useFormContext, ValidationRules } from 'react-hook-form';
import { fields, inputs } from 'styles';

interface Props {
  name: string;
  label: string;
  size?: 'xsmall' | 'small' | 'normal';
  defaultValue?: string;
  placeholder?: string;
  textarea?: boolean;
  disabled?: boolean;
  tooltip?: string;
  validationObject?: ValidationRules;
  className?: SerializedStyles;
  onChange?: (value: string) => void;
}

export const ValidatedInput: React.FC<Props> = ({
  name,
  label,
  size = window.innerWidth > 584 ? 'normal' : 'small',
  defaultValue,
  placeholder,
  textarea,
  disabled,
  tooltip,
  validationObject,
  className,
  onChange,
}) => {
  const { register, errors, trigger } = useFormContext();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  let delayDebounceFn: NodeJS.Timeout;

  return (
    <div css={className || fields.field}>
      <label
        css={errors && errors[name] ? fields.labelError : fields.label}
        htmlFor={name}
      >
        {label}
      </label>
      {!textarea && (
        <input
          type="text"
          id={name}
          name={name}
          defaultValue={defaultValue}
          ref={validationObject ? register(validationObject) : register}
          css={
            errors && errors[name]
              ? inputs.inputError[size || 'normal']
              : inputs.input[size || 'normal']
          }
          placeholder={placeholder}
          disabled={disabled}
          onChange={handleInputChange}
        />
      )}

      {textarea && (
        <textarea
          name={name}
          id={name}
          placeholder={placeholder}
          ref={validationObject ? register(validationObject) : register}
          css={
            errors && errors[name]
              ? inputs.inputTextareaError
              : inputs.inputTextarea
          }
          disabled={disabled}
          defaultValue={defaultValue}
        />
      )}

      {tooltip && (
        <TooltipIcon
          className={inputs.tooltipIcon}
          onMouseEnter={handleTooltipEnter}
          onMouseLeave={handleTooltipLeave}
        />
      )}

      {tooltipOpen && (
        <div css={inputs.tooltipBox}>
          <p>{tooltip}</p>
        </div>
      )}

      <ErrorMessage
        errors={errors}
        name={name}
        as={<p css={fields.errorMsg} />}
      >
        {({
          messages,
        }: {
          messages: Array<{ type: string; message: string }>;
        }) =>
          messages &&
          Object.entries(messages).map(([type, message]) => (
            <p key={type}>{message}</p>
          ))
        }
      </ErrorMessage>
    </div>
  );

  function handleInputChange(event: React.FormEvent<HTMLInputElement>) {
    clearTimeout(delayDebounceFn);

    if (onChange) onChange(event.currentTarget.value);

    if (errors[name]) {
      trigger(name);
    }

    delayDebounceFn = setTimeout(() => {
      trigger(name);
    }, 1200);
  }

  function handleTooltipEnter() {
    setTooltipOpen(true);
  }

  function handleTooltipLeave() {
    setTooltipOpen(false);
  }
};
