import React from 'react';

export const ShieldIcon: React.FC = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00019 4L8.97469 5.975L11.1542 6.292L9.57719 7.8295L9.94969 10L8.00019 8.975L6.05069 10L6.42319 7.8295L4.84619 6.292L7.02569 5.975L8.00019 4Z"
      stroke="#B30000"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M8 15C8 15 1 12.5 1 3L8 1L15 3C15 12.5 8 15 8 15Z"
      stroke="#B30000"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);
