import React from 'react';

interface Props {
  stroke?: string;
}

export const BackArrow: React.FC<Props> = ({ stroke }) => (
  <svg
    width="6"
    height="12"
    viewBox="0 0 6 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.60001 1.19996L0.800005 5.99996L5.60001 10.8"
      stroke={stroke || 'black'}
    />
  </svg>
);
