import { useNavigate } from '@reach/router';
import { FacebookIcon } from 'assets';
import { AppDispatch } from 'modules/redux-store';
import React from 'react';
import { useDispatch } from 'react-redux';
import { auth } from 'styles';

import { AuthThunks } from '../redux';

interface Props {
  text: string;
}

export const FacebookAuth: React.FC<Props> = ({ text }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigateTo = useNavigate();

  return (
    <button
      type="button"
      css={auth.socialWrapper}
      onClick={handleFacebookSignIn}
    >
      <FacebookIcon />
      <p>{text}</p>
    </button>
  );

  async function handleFacebookSignIn() {
    const error = await dispatch(AuthThunks.signInWithFacebookAccount());
    if (!error && location.href.includes('kosarica')) {
      navigateTo('/kosarica');
    } else if (!error) {
      navigateTo('/');
    }
  }
};
