import { CloseIcon } from 'assets';
import { navigate } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { AppDispatch, ApplicationState } from 'modules/redux-store';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { buttons, cartPopup } from 'styles';
import { formatPrice } from 'utils';

import { CartProduct } from '../models';
import { CartThunks } from '../redux';

export const CartPopup: React.FC = () => {
  const { products } = useSelector((state: ApplicationState) => state.cart);
  const dispatch = useDispatch<AppDispatch>();

  let totalPrice = 0;

  return (
    <div css={cartPopup.root}>
      <div>
        <div css={cartPopup.section}>
          <p css={cartPopup.title}>
            <FormattedMessage id="cart.popup.title" />
          </p>
          {products.length > 0 && (
            <div css={cartPopup.infoWrapper}>
              <p css={cartPopup.info}>
                <FormattedMessage id="cart.popup.calculation" />
              </p>
            </div>
          )}
        </div>
        {products.length === 0 ? (
          <p css={cartPopup.text}>
            <FormattedMessage id="cart.popup.empty" />
          </p>
        ) : (
          <>
            <div css={cartPopup.contentTop} />
            <div css={cartPopup.content}>
              <div css={cartPopup.gridWrapper}>
                <p css={[cartPopup.extendedColumn, cartPopup.contentTitle]}>
                  <FormattedMessage id="cart.popup.name" />
                </p>
                <p css={cartPopup.contentTitle}>
                  <FormattedMessage id="cart.popup.amount" />
                </p>
                <p css={cartPopup.contentTitle}>
                  <FormattedMessage id="cart.popup.price" />
                </p>
              </div>
              {products.map((item: CartProduct) => {
                totalPrice += item.quantity.quantity * item.price;

                return (
                  <div key={item.id} css={cartPopup.gridWrapper}>
                    <p css={[cartPopup.extendedColumn, cartPopup.text]}>
                      {item.title}
                    </p>
                    <p css={cartPopup.text}>
                      {item.quantity.quantity} {item.quantity.type}
                    </p>
                    <div css={cartPopup.priceWrapper}>
                      <p css={[cartPopup.text, cartPopup.noWrap]}>
                        {formatPrice(item.price)} €
                      </p>
                      <button
                        type="button"
                        css={[buttons.container, cartPopup.closeWrapper]}
                        onClick={handleRemove}
                        data-id={item.id}
                      >
                        <CloseIcon />
                      </button>
                    </div>
                    <div css={[cartPopup.line, cartPopup.fullWidth]} />
                  </div>
                );
              })}
              <div css={cartPopup.gridWrapper}>
                <p css={[cartPopup.extendedColumnTo3, cartPopup.taxesText]}>
                  <FormattedMessage id="cart.home-page.product.price-without-vat" />
                </p>
                <p css={[cartPopup.taxesText, cartPopup.noOpacity]}>
                  {formatPrice(totalPrice)} €
                </p>
                <p css={[cartPopup.extendedColumnTo3, cartPopup.taxesText]}>
                  PDV
                </p>
                <p css={[cartPopup.taxesText, cartPopup.noOpacity]}>
                  {formatPrice(totalPrice * 0.25)} €
                </p>
                <div css={[cartPopup.line, cartPopup.fullWidth]} />

                <p
                  css={[
                    cartPopup.extendedColumnTo3,
                    cartPopup.taxesText,
                    cartPopup.noOpacity,
                    cartPopup.boldText,
                    cartPopup.taxesPadding,
                  ]}
                >
                  <FormattedMessage id="cart.home-page.product.price-with-vat" />
                </p>
                <p
                  css={[
                    cartPopup.taxesText,
                    cartPopup.noOpacity,
                    cartPopup.boldText,
                    cartPopup.taxesPadding,
                  ]}
                >
                  {formatPrice(totalPrice * 1.25)} €
                </p>
              </div>
            </div>
            <div css={cartPopup.buttonWrapper}>
              <button css={cartPopup.buttonAdd} onClick={goToCart}>
                <FormattedMessage id="cart.popup.go-to-cart" />
              </button>
              <button css={cartPopup.buttonDelete} onClick={deleteCart}>
                <FormattedMessage id="cart.popup.empty-cart" />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );

  function goToCart() {
    navigate('/kosarica');
  }

  function deleteCart() {
    dispatch(CartThunks.removeAll());
  }

  function handleRemove(event: React.MouseEvent<HTMLButtonElement>) {
    const { id } = event.currentTarget.dataset;
    if (!id) return;

    dispatch(CartThunks.removeProduct(id));
  }
};
