import { FormattedMessage } from 'gatsby-plugin-intl';
import { CommercialProduct, SkuProduct } from 'models';
import React from 'react';
import { product } from 'styles';
import { exists } from 'utils';

interface Props {
  commercial: CommercialProduct;
  sku: SkuProduct;
}

export const TechnicalDetails: React.FC<Props> = ({ commercial, sku }) => (
  <div css={product.desktopTechnicalDetails}>
    <h4 css={product.subTitle}>
      <FormattedMessage id="products.mobile-accordion.technical-details" />
    </h4>
    <div css={product.characteristicsTable}>
      {sku.coveringPattern && (
        <p>
          <span>
            <FormattedMessage id="products.mobile-accordion.covering-method" />
          </span>
          <span>{sku.coveringPattern}</span>
        </p>
      )}
      {exists(sku.salesRelation.roofPitchStraightBondWelded) && (
        <p>
          <span>
            <FormattedMessage id="products.mobile-accordion.roof-foil" />
          </span>
          <span>
            {sku.salesRelation.roofPitchStraightBondWelded}
            {'°'}
          </span>
        </p>
      )}
      {exists(sku.salesRelation.roofPitchStraightBondFleecePremium) && (
        <p>
          <span>
            <FormattedMessage id="products.mobile-accordion.normal-roof-foil" />
          </span>
          <span>
            {sku.salesRelation.roofPitchStraightBondFleecePremium}
            {'°'}
          </span>
        </p>
      )}
      {sku.dimensions &&
        (sku.dimensions.length ||
          sku.dimensions.width ||
          sku.dimensions.height) && (
          <p>
            <span>
              <FormattedMessage id="products.mobile-accordion.dimensions" />(
              {`${sku.dimensions.length ? 'D' : ''}${
                sku.dimensions.width ? 'xŠ' : ''
              }${sku.dimensions.height ? 'xV' : ''}`}
              ):
            </span>
            <span>
              {sku.dimensions.length && `${sku.dimensions.length}`}{' '}
              {sku.dimensions.width && `x ${sku.dimensions.width}`}{' '}
              {sku.dimensions.height && `x ${sku.dimensions.height}`} mm
            </span>
          </p>
        )}
      {commercial.navigationCategories.code === 'RoofAcc' &&
        (exists(commercial.roofAccLength) ||
          exists(commercial.roofAccWidth)) && (
          <p>
            <span>
              <FormattedMessage id="products.mobile-accordion.dimensions" />:
            </span>
            <span>
              {commercial.roofAccLength}
              {commercial.roofAccWidth && ` - ${commercial.roofAccWidth}`} mm
            </span>
          </p>
        )}
      {exists(sku.salesRelation.piecesMin) &&
        exists(sku.salesRelation.piecesMax) && (
          <p>
            <span>
              <FormattedMessage id="products.mobile-accordion.piece-by-square" />
              :
            </span>
            <span>
              {sku.salesRelation.piecesMin} - {sku.salesRelation.piecesMax}{' '}
              kom/m2
            </span>
          </p>
        )}
      {exists(sku.salesRelation.netWeightm2) && (
        <p>
          <span>
            <FormattedMessage id="products.mobile-accordion.weight-by-square" />
            :
          </span>
          <span>{sku.salesRelation.netWeightm2} kg</span>
        </p>
      )}
      {exists(sku.minBattenDistance) && exists(sku.maxBattenDistance) && (
        <p>
          <span>
            <FormattedMessage id="products.mobile-accordion.roof-lathing" />:
          </span>
          <span>
            {sku.maxBattenDistance} - {sku.minBattenDistance} mm
          </span>
        </p>
      )}
      {(exists(sku.salesRelation.piecesDry) ||
        exists(sku.salesRelation.piecesMortar)) && (
        <p>
          <span>
            <FormattedMessage id="products.mobile-accordion.brick-consumption" />
            :
          </span>
          <span>
            {sku.salesRelation.piecesDry || sku.salesRelation.piecesMortar}{' '}
            kom/m2
          </span>
        </p>
      )}
      {exists(sku.thermalConductivity) && (
        <p>
          <span>
            <FormattedMessage id="products.mobile-accordion.thermal-conductivity" />
            :
          </span>
          <span>{sku.thermalConductivity} W/mK</span>
        </p>
      )}
      {exists(sku.verticalCompressiveStrength) && (
        <p>
          <span>
            <FormattedMessage id="products.mobile-accordion.vertical-compressive-strength" />
            :
          </span>
          <span>{sku.verticalCompressiveStrength} N/mm2</span>
        </p>
      )}
      {exists(sku.weightPerPiece) && (
        <p>
          <span>
            <FormattedMessage id="products.mobile-accordion.weight" />:
          </span>
          <span>{sku.weightPerPiece} kg</span>
        </p>
      )}
      {exists(sku.pcsPerPallet) && (
        <p>
          <span>
            <FormattedMessage id="products.mobile-accordion.piece-by-palette" />
            :
          </span>
          <span>{sku.pcsPerPallet} kom/pal</span>
        </p>
      )}
    </div>
  </div>
);
