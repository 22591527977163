import React, { useState } from 'react';
import { navStyles } from 'styles';
import { disableBodyScroll } from 'utils';

import { FacadeNavigation } from './FacadeNavigation';
import { RoofNavigation } from './RoofNavigation';
import { RoofWindowNavigation } from './RoofWindowNavigation';
import { RootNavigation } from './RootNavigation';
import { WallsNavigation } from './WallsNavigation';

const INITIAL_STATE = {
  root: true,
  walls: false,
  roof: false,
  facade: false,
  roofWindows: false,
};

interface NavState {
  root: boolean;
  walls: boolean;
  roof: boolean;
  facade: boolean;
  roofWindows: boolean;
}

interface Props {
  isActive: boolean;
}

export const MobileNavigation: React.FC<Props> = ({ isActive }) => {
  const [navState, setNavState] = useState<NavState>(INITIAL_STATE);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const hidden = isActive && navStyles.hidden;
  disableBodyScroll(isChecked);

  return (
    <div css={[navStyles.root, hidden]}>
      <input type="checkbox" css={navStyles.toggler} onClick={changeCheckbox} />

      <div css={navStyles.hamburger}>
        <div css={navStyles.line(isChecked)} />
      </div>

      <div css={navStyles.menu(isChecked)}>
        <div css={navStyles.menuWrapper(isChecked)}>
          <div css={navStyles.menuContent(isChecked)}>
            {navState.root && <RootNavigation onItemClick={onItemClick} />}

            {navState.walls && (
              <WallsNavigation
                closeNav={changeCheckbox}
                onBackClick={onBackClick}
              />
            )}

            {navState.roof && (
              <RoofNavigation
                closeNav={changeCheckbox}
                onBackClick={onBackClick}
              />
            )}

            {navState.facade && (
              <FacadeNavigation
                closeNav={changeCheckbox}
                onBackClick={onBackClick}
              />
            )}

            {navState.roofWindows && (
              <RoofWindowNavigation
                closeNav={changeCheckbox}
                onBackClick={onBackClick}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );

  function changeCheckbox() {
    setIsChecked(!isChecked);
    setNavState(INITIAL_STATE);
  }

  function onItemClick(event: React.MouseEvent<HTMLLIElement, MouseEvent>) {
    const { id } = event.currentTarget.dataset;

    if (!id) return;

    setNavState({
      ...INITIAL_STATE,
      root: false,
      [id]: true,
    });
  }

  function onBackClick() {
    setNavState(INITIAL_STATE);
  }
};
