import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { fields, inputs } from 'styles';

interface Props {
  name: string;
  label: string;
  size?: 'xsmall' | 'small' | 'normal';
  defaultValue?: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
}

export const Input: React.FC<Props> = ({
  name,
  label,
  size,
  defaultValue,
  value,
  placeholder,
  disabled,
}) => {
  const { register, setValue } = useFormContext();

  useEffect(() => {
    register(name);
  }, [register, name]);

  useEffect(() => {
    setValue(name, value);
  }, [setValue, name, value]);

  return (
    <div css={fields.field}>
      <label css={fields.label} htmlFor={name}>
        {label}
      </label>
      <input
        type="text"
        id={name}
        name={name}
        defaultValue={defaultValue}
        value={value}
        css={inputs.input[size || 'normal']}
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  );
};
