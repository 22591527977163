import { FacadeListingState } from 'template/AllFacadeListing';
import { RoofListingState } from 'template/AllRoofListing';
import { RoofWindowsState } from 'template/AllRoofWindowsListing';
import { WallListingState } from 'template/AllWallListing';
import { CategoryFiltersState } from 'template/CategoryPage';
import { ModelFiltersState } from 'template/ModelPage';

type FiltersState =
  | ModelFiltersState
  | FacadeListingState
  | CategoryFiltersState
  | RoofListingState
  | RoofWindowsState
  | WallListingState;

export function handleShowFilterButton(filtersState: FiltersState) {
  const isButtonVisible = Object.values(filtersState).some((filter) => {
    return Object.values(filter).includes(true);
  });
  return isButtonVisible;
}
