import { FormattedMessage } from 'gatsby-plugin-intl';
import React, { useEffect, useState } from 'react';
import { buttons, listing, spacers } from 'styles';
import { RoofWindowsState } from 'template/AllRoofWindowsListing';
import { handleShowFilterButton } from 'utils';

import { ProductBrand } from './ProductBrand';
import { RoofWindowFilters } from './RoofWindowFilters';

interface Props {
  amount: number;
  models: string[];
  handleChange: (name: string, value: boolean, section?: string) => void;
  clearFilters: VoidFunction;
  filterState: RoofWindowsState;
}

export const DesktopRoofWindowFilters: React.FC<Props> = ({
  models,
  handleChange,
  clearFilters,
  filterState,
  amount,
}) => {
  const [isFilterPicked, setIsFilterPicked] = useState(false);

  useEffect(() => {
    setIsFilterPicked(handleShowFilterButton(filterState));
  }, [filterState]);

  const handleClick = () => {
    clearFilters();
    setIsFilterPicked(false);
  };

  const isButtonHidden = isFilterPicked ? '' : buttons.hidden;
  return (
    <section css={listing.filters}>
      <ProductBrand brand="Tondach" amount={amount} />

      <div css={isButtonHidden}>
        <button
          type="button"
          css={[buttons.ghost.small, spacers.bottom.huge]}
          onClick={handleClick}
        >
          <FormattedMessage id="products.clear-filter" />
        </button>
      </div>
      <RoofWindowFilters
        models={models}
        handleChange={handleChange}
        filterState={filterState}
      />
    </section>
  );
};
