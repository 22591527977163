import { SerializedStyles } from '@emotion/core';
import React from 'react';

interface Props {
  className?: SerializedStyles;
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
}

export const TooltipIcon: React.FC<Props> = ({
  className,
  onMouseEnter,
  onMouseLeave,
}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    css={className}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <circle opacity="0.2" cx="10" cy="10" r="9.5" stroke="black" />
    <path
      opacity="0.4"
      d="M8.90341 11.8196H10.2493V11.6989C10.2564 11.0064 10.505 10.6832 11.0661 10.3459C11.7301 9.9517 12.1634 9.42969 12.1634 8.59517C12.1634 7.35227 11.1619 6.62784 9.75213 6.62784C8.46307 6.62784 7.41193 7.30256 7.37997 8.72301H8.82884C8.85014 8.14418 9.27983 7.83523 9.74503 7.83523C10.2244 7.83523 10.6115 8.15483 10.6115 8.64844C10.6115 9.11364 10.2741 9.42259 9.83736 9.69957C9.24077 10.076 8.90696 10.456 8.90341 11.6989V11.8196ZM9.60298 14.0923C10.0575 14.0923 10.4517 13.7124 10.4553 13.2401C10.4517 12.7749 10.0575 12.3949 9.60298 12.3949C9.13423 12.3949 8.74716 12.7749 8.75071 13.2401C8.74716 13.7124 9.13423 14.0923 9.60298 14.0923Z"
      fill="black"
    />
  </svg>
);
