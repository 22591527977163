import { AppDispatch } from 'modules/redux-store';

import { CopyActions } from './actions';

const removeCopy = () => (dispatch: AppDispatch) => {
  dispatch(CopyActions.removeCopy());
};

export const CopyThunks = {
  removeCopy,
};
