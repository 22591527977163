import { css } from '@emotion/core';

import { buttons } from './button.styles';
import { mqFrom } from './responsive.styles';
import { typescale } from './typescale.styles';
import { fonts } from './variables';
import { colors, fontFamily, fontWeights, unit } from './vars.styles';

const root = css`
  position: fixed;
  top: 38px;
  right: 20px;
  z-index: 2;
  display: flex;
  justify-content: center;

  ${mqFrom.Lrg} {
    display: none;
  }
`;

const toggler = css`
  position: absolute;
  top: -8px;
  z-index: 3;
  cursor: pointer;
  width: 40px;
  height: 40px;
  opacity: 0;

  ${mqFrom.Med} {
    right: -8px;
  }

  ${mqFrom.Lrg} {
    right: 80px;
  }
`;

const hamburger = css`
  z-index: 2;
  width: 30px;
  height: 30px;
  padding: 4px;
  background: ${colors.bg};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

const line = (isChecked: boolean) => css`
  position: relative;
  flex: none;
  width: 100%;
  height: 2px;
  background: ${colors.textSecondary};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;

  ${isChecked && `transform: rotate(135deg);`}

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: -5px;
    width: 100%;
    height: 2px;
    background: inherit;

    ${isChecked &&
    `top: 0;
    transform: rotate(90deg);`}
  }

  &:after {
    top: 5px;

    ${isChecked &&
    `top: 0;
      transform: rotate(90deg);`}
  }
`;

const menu = (isChecked: boolean) => css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  ${isChecked && `visibility: visible;`}
`;

const menuWrapper = (isChecked: boolean) => css`
  background: ${colors.bg};
  width: 100%;
  height: 100vh;
  display: flex;
  flex: none;
  align-items: flex-start;
  justify-content: flex-start;

  transform: scale(0);
  transition: all 0.4s ease;

  overflow-y: scroll;
  overflow-x: hidden;

  padding-top: 100px;

  ${isChecked &&
  `transform: scale(1);
  transition-duration: 0.5s;
  `}
`;

const menuContent = (isChecked: boolean) => css`
  max-width: 100vw;
  opacity: 0;
  transition: opacity 0.4s ease;
  padding: 32px 20px;

  width: 100%;

  ${isChecked &&
  `opacity: 1;
  transition: opacity 0.4s ease 0.4s;`}
`;

const title = css`
  text-transform: uppercase;
  white-space: normal;
  text-align: left;

  font-family: ${fonts.sansSerif};
  font-weight: ${fontWeights.weightBold};
  font-style: normal;
  ${typescale.small};

  padding: 0 ${unit * 3}px;
`;

const list = css`
  width: 100%;

  li {
    list-style: none;
    padding: ${unit * 3}px 0;

    cursor: pointer;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    &:hover {
      color: ${colors.primary};

      svg {
        g  {
          opacity: 1;
        }
        #fillPrimary {
          fill: ${colors.primary};
        }
        #borderPrimary  {
          stroke: ${colors.primary};
        }
      }
    }

    div {
      display: flex;
      align-items: center;
    }
  }
`;

const categoryList = css`
  width: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  padding: ${unit * 3}px 0;

  &:hover {
    p {
      color: ${colors.primary};
    }
    svg {
      #borderPrimary  {
        stroke: ${colors.primary};
      }
    }
  }
`;

const category = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-style: normal;
  ${typescale.small};

  color: ${colors.dark};

  &:hover {
    color: ${colors.primary};
  }
`;

const allProducts = css`
  display: flex;
  justify-content: flex-start;
  padding: ${unit * 3}px 0;
`;

const backWrapper = css`
  position: absolute;
  top: 44px;
  left: ${unit * 6}px;
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;

  &:hover {
    svg {
      path {
        stroke: ${colors.primary};
      }
    }
  }
`;

const back = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-style: normal;

  ${typescale.tiny};

  color: ${colors.primary};
  text-transform: capitalize;

  padding: 0 ${unit * 2}px;
`;

const closeIcon = css`
  ${buttons.container}
  display: block;
  width: 100%;
  text-align: right;
  margin-bottom: 60px;
`;

const hidden = css`
  display: none;
`;

export const navStyles = {
  root,
  toggler,
  hamburger,
  title,
  list,
  back,
  categoryList,
  category,
  allProducts,
  backWrapper,
  line,
  menu,
  menuWrapper,
  menuContent,
  closeIcon,
  hidden,
};
