import { SerializedStyles } from '@emotion/core';
import React from 'react';

interface Props {
  fill?: string;
  stroke?: string;
  opacity?: string;
  className?: SerializedStyles;
}

export const ChevronLeft: React.FC<Props> = ({
  fill,
  stroke,
  opacity,
  className,
}) => (
  <svg
    css={className}
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill={fill || 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={opacity || '1'}
      d="M5 1L1 5L5.00001 9.00001"
      stroke={stroke || '#3C3737'}
    />
  </svg>
);
