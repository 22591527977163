import { MessageLocation } from 'modules/inbox';

export function isMessageLocationType(type: unknown): type is MessageLocation {
  return (
    type === 'inbox' ||
    type === 'requested' ||
    type === 'sent' ||
    type === 'archive' ||
    type === 'trash'
  );
}
