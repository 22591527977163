import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';
import { inbox } from 'styles';
import { formatPrice } from 'utils';

import { OfferState } from '../hooks';
import { OfferSummary } from '../models';

interface Props {
  offerState: OfferState;
  offerSummary: OfferSummary;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}

export const OfferSumarySection: React.FC<Props> = ({
  offerState,
  offerSummary,
  handleChange,
}) => (
  <>
    <div css={inbox.offerSummary}>
      <div css={inbox.summaryRow}>
        <p>
          <FormattedMessage id="inbox.offer-summary.shipping-price" />
        </p>
        <input
          type="number"
          name="shippingPrice"
          min="0"
          value={offerState.shippingPrice}
          onChange={handleChange}
          css={inbox.internalOfferPriceInput}
        />
      </div>
      <div css={inbox.summaryRow}>
        <p>
          <FormattedMessage id="admin.answered-requests.price-without-vat-and-rebate" />
        </p>
        <p>{formatPrice(offerSummary.totalWithoutTax)} €</p>
      </div>

      <div css={inbox.summaryRow}>
        <p>
          <FormattedMessage id="cart.home-page.product.rebate" />
        </p>
        <p>{formatPrice(offerSummary.rebate)} €</p>
      </div>

      <div css={inbox.summaryRow}>
        <p>
          <FormattedMessage id="cart.home-page.product.shipping" />
        </p>
        <p>{formatPrice(offerState.shippingPrice)} €</p>
      </div>

      <div css={inbox.summaryRow}>
        <p>PDV</p>
        <p>{formatPrice(offerSummary.tax)} €</p>
      </div>
    </div>

    <div css={inbox.summaryRowTotal}>
      <p>
        <span>UKUPNO</span> (PDV uračunat)
      </p>
      <p>{formatPrice(offerSummary.total)} €</p>
    </div>
  </>
);
