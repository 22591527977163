import React, { CSSProperties } from 'react';

interface Props {
  style?: CSSProperties | undefined;
}

export const CalculatorIcon: React.FC<Props> = ({ style }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    style={style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g>
        <path
          d="M1.25 16.75V1.25H16.75V16.75H1.25Z"
          stroke="#B30000"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M5.90625 4.35938V7.45312"
          stroke="#B30000"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M7.45312 5.90625H4.35938"
          stroke="#B30000"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M7.19531 10.8047L4.61719 13.3828"
          stroke="#B30000"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M7.19531 13.3828L4.61719 10.8047"
          stroke="#B30000"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M13.6406 5.90625H10.5469"
          stroke="#B30000"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M13.6406 11.0625H10.5469"
          stroke="#B30000"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M13.6406 13.125H10.5469"
          stroke="#B30000"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
    </g>
    <rect x="1.25" y="1.25" width="15.5" height="15.5" stroke="#B30000" />
    <defs>
      <clipPath id="clip0"></clipPath>
      <clipPath id="clip1">
        <rect
          width="16.5"
          height="16.5"
          fill="white"
          transform="translate(0.75 0.75)"
        />
      </clipPath>
    </defs>
  </svg>
);
