import { SerializedStyles } from '@emotion/core';
import React from 'react';

interface Props {
  className?: SerializedStyles;
  isValid: boolean;
}

export const PasswordCheckmark: React.FC<Props> = ({ className, isValid }) => {
  return (
    <svg
      css={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8"
        cy="8"
        r="8"
        fill={isValid ? '#3AD675' : '#000000'}
        opacity={isValid ? '1' : '0.1'}
      />
      <path d="M4.5 8.5L6.5 10.5L11.5 5.5" stroke="white" strokeWidth="2" />
    </svg>
  );
};
