import { graphql, useStaticQuery } from 'gatsby';
import { Product } from 'models';

export const useGetAllProducts = () => {
  const { allCombinedProductsJson } = useStaticQuery(graphql`
    query allQueryProducts {
      allCombinedProductsJson {
        nodes {
          commercial {
            brand
            code
            assets {
              mainImg
              otherImgs {
                imgUrl
                assetType
              }
            }
            category
            name
            localizedName
            model
            navigationCategories {
              code
            }
          }
          id
        }
      }
    }
  `);

  if (allCombinedProductsJson) {
    const filteredProducts = allCombinedProductsJson.nodes.filter(
      (product: Product) => {
        if (product.commercial.navigationCategories.code === 'Wall')
          return product.commercial.category !== null;
        return true;
      },
    );

    return filteredProducts;
  }

  return [];
};
