import { ProductFirebase } from 'models';
import { ProductRequest } from 'modules/inbox';

export interface ProductOffer {
  product: ProductFirebase;
  amount: number;
  unit: string;
  rebate?: number;
  price?: number;
}

export class ProductOffer {
  constructor(product: ProductRequest) {
    this.product = product.product;
    this.amount = product.amount || 0;
    this.price = product.suggestedPrice || 0;
    this.unit = product.unit || 'paleta';
    this.rebate = 5;
  }
}
