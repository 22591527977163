export const RooftileColors = {
  A21: '#040404',
  E18: '#13100F',
  E20: '#060101',
  E19: '#5f5556',
  A22: '#68260e',
  A23: '#201d28',
  E16: '#7b3f2d',
  E56: '#934c2f',
  E10: '#64331d',
  E44: '#4b4b4c',
  E02: '#e8dddf',
  E08: '#963617',
  E26: '#88461c',
  E35: '#565656',
  E34: '#362c2c',
  E11: '#432618',
  E30: '#9a5d3b',
  F01: '#8e3314',
};
