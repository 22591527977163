import { Collection } from 'consts';
import { FirestoreService } from 'modules/firebase';

import { Distributor } from '../models';

const Database = FirestoreService<Distributor>(Collection.Distributors);

export const DistributorService = {
  Database,
};
