import { CommercialProduct } from 'models';

import { localizeNavCategory } from './localizeNavCategory';
import { slugify } from './slugify';

export function getProductRoute(commercial: CommercialProduct) {
  function getCategoryRoute() {
    if (commercial.navigationCategories.code === 'RoofWindows')
      return 'tondach';
    if (commercial.category) return commercial.category;
    return 'terca';
  }

  return `/${slugify(
    localizeNavCategory(commercial.navigationCategories.code),
  )}/${slugify(getCategoryRoute())}/${slugify(commercial.model)}/${slugify(
    commercial.name,
  )}`;
}
