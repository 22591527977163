import { AppDispatch } from 'modules/redux-store';

import { SearchActions } from './actions';

const setSearchInput = (value: string) => (dispatch: AppDispatch) => {
  dispatch(SearchActions.setSearchInput(value));
};

const setIsActive = (value: boolean) => (dispatch: AppDispatch) => {
  if (!value) dispatch(SearchActions.setSearchInput(''));
  dispatch(SearchActions.setIsActive(value));
};

export const SearchThunks = {
  setIsActive,
  setSearchInput,
};
