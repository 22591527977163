import { ActionUnion, createAction } from 'modules/redux-store';

import { FirestoreListenerActionTypes } from './types';

const AddActions = {
  SetListener: (listenerName: string, listener: VoidFunction) =>
    createAction(FirestoreListenerActionTypes.SetListener, {
      listenerName,
      listener,
    }),
};

const GeneralActions = {
  RemoveListener: (listenerName: string) =>
    createAction(FirestoreListenerActionTypes.RemoveListener, {
      listenerName,
    }),
  RemoveAllListeners: () =>
    createAction(FirestoreListenerActionTypes.RemoveAllListeners),
};

export const FirestoreListenerActions = {
  ...AddActions,
  ...GeneralActions,
};

export type FirestoreListenerActions = ActionUnion<
  typeof FirestoreListenerActions
>;
