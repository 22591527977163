import React from 'react';

export const Step2: React.FC = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 6.5L0.5 4.5L11.5 0.5L7.5 11.5L5.5 6.5ZM5.5 6.5L7.5 4.5"
      stroke="#B30000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
