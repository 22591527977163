import { BackArrow } from 'assets';
import { Link } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { useRoofWindowModels } from 'modules/navigation/hooks';
import React from 'react';
import { navStyles } from 'styles';
import { slugify } from 'utils';

interface Props {
  closeNav: VoidFunction;
  onBackClick: VoidFunction;
}

export const RoofWindowNavigation: React.FC<Props> = ({
  closeNav,
  onBackClick,
}) => {
  const { sortedAllRoofWindowModels } = useRoofWindowModels();

  return (
    <ul>
      <div css={navStyles.backWrapper} onClick={onBackClick}>
        <BackArrow />
        <p css={navStyles.back}>
          <FormattedMessage id="navigation.back" />
        </p>
      </div>
      <li css={navStyles.allProducts}>
        <Link
          to={`/krovni-prozori/pregled-svih-proizvoda`}
          onClick={closeNav}
          css={navStyles.category}
        >
          <FormattedMessage id="navigation.all-items" />
        </Link>
      </li>
      <li css={navStyles.allProducts}>
        <Link
          to={`/krovni-prozori-oprema/pregled-svih-proizvoda`}
          onClick={closeNav}
          css={navStyles.category}
        >
          <FormattedMessage id="navigation.roof-window-addons" />
        </Link>
      </li>
      {sortedAllRoofWindowModels.map((model: string, index: number) => (
        <div key={index} css={navStyles.categoryList}>
          <li key={model}>
            <Link
              to={`/krovni-prozori/tondach/${slugify(model)}`}
              onClick={closeNav}
              css={navStyles.category}
            >
              {model}
            </Link>
          </li>
        </div>
      ))}
    </ul>
  );
};
