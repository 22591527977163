import React from 'react';
import { accordionStyles } from 'styles';
import { createMarkup } from 'utils';

interface Props {
  id: number;
  title: string;
  description: string;
  handleChangeAccordion: (id: number) => void;
  isOpen: boolean;
}

export const Accordion: React.FC<Props> = ({
  id,
  title,
  description,
  handleChangeAccordion,
  isOpen,
}) => {
  return (
    <div css={accordionStyles.accordion}>
      <div css={accordionStyles.accordionHeader}>
        <div css={accordionStyles.titleWrapper} onClick={chnageAccordion}>
          <span css={accordionStyles.title}>{title}</span>
        </div>
        <div css={accordionStyles.accordionContent(isOpen)}>
          <p
            css={accordionStyles.description}
            dangerouslySetInnerHTML={createMarkup(description)}
          />
        </div>
        <div css={accordionStyles.line} />
      </div>
      <div css={accordionStyles.iconWrapper} onClick={chnageAccordion}>
        <div css={accordionStyles.horizontalIcon}></div>
        <div css={accordionStyles.verticalIcon(isOpen)}></div>
      </div>
    </div>
  );

  function chnageAccordion() {
    handleChangeAccordion(id);
  }
};
