import { PasswordInput } from 'components/PasswordInput';
import { PasswordRegExp } from 'consts';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { ClientData, DistributorLogo } from 'models';
import React from 'react';
import { cart, fields } from 'styles';

interface Props {
  user?: ClientData;
  logo?: DistributorLogo;
  isPasswordInvalid?: boolean;
  isValidPassword?: boolean;
  arePasswordsTheSame?: boolean;
  isNewPasswordEmpty?: boolean;
}

export const DistributorUserInfo: React.FC<Props> = ({
  isValidPassword,
  arePasswordsTheSame,
  isNewPasswordEmpty,
  logo,
  user,
}) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <section css={cart.infoSection}>
        <div>
          <h4 css={cart.subtitle}>
            <FormattedMessage id="cart.distributor-user-info.title" />
          </h4>
        </div>
        <div css={cart.infoInputs}>
          {logo?.logoFull && (
            <img
              src={logo.logoFull}
              css={cart.distributorLogo}
              alt={user?.userInfoData?.firstName}
            />
          )}
          <div css={cart.infoRowFlex}>
            <p css={cart.checkboxText}>
              <FormattedMessage id="cart.distributor-user-info.name" />
            </p>
            <p css={cart.checkboxText}>{user?.userInfoData?.firstName}</p>
          </div>
          <div css={cart.infoRowFlex}>
            <p css={cart.checkboxText}>
              <FormattedMessage id="cart.distributor-user-info.email" />
            </p>
            <p css={cart.checkboxText}>{user?.userInfoData?.email}</p>
          </div>
        </div>
      </section>

      <section css={cart.infoSection}>
        <div>
          <h4 css={cart.subtitle}>
            <FormattedMessage id="cart.distributor-user-info.change-password" />
          </h4>
        </div>

        <div css={cart.infoInputs}>
          <div css={cart.infoRowFlex}>
            <div>
              <PasswordInput
                name="oldPassword"
                label={formatMessage({ id: 'shared.input.password.label' })}
                placeholder={formatMessage({
                  id: 'shared.input.password.placeholder',
                })}
                validationObject={{
                  validate: (value) =>
                    PasswordRegExp.test(value) ||
                    formatMessage({ id: 'shared.validation.password.length' }),
                }}
                showPasswordScore
              />
              {isValidPassword && (
                <p css={fields.errorMsg}>
                  <FormattedMessage id="cart.distributor-user-info.wrong-password" />
                </p>
              )}
            </div>

            <div>
              <PasswordInput
                name="newPassword"
                label={formatMessage({ id: 'shared.input.new-password.label' })}
                placeholder={formatMessage({
                  id: 'shared.input.new-password.placeholder',
                })}
                validationObject={{
                  validate: (value) =>
                    PasswordRegExp.test(value) ||
                    formatMessage({ id: 'shared.validation.password.length' }),
                }}
                showPasswordScore
              />
              {arePasswordsTheSame && (
                <p css={fields.errorMsg}>
                  <FormattedMessage id="cart.distributor-user-info.old-new-password" />
                </p>
              )}
              {isNewPasswordEmpty && (
                <p css={fields.errorMsg}>
                  <FormattedMessage id="cart.distributor-user-info.new-password" />
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
