import { useLocation } from '@reach/router';
import { EllipseColorIcon } from 'assets';
import { Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { ProductColorVariant } from 'models';
import React, { useState } from 'react';
import { product } from 'styles';
import { useGetRoofColorImages } from 'utils';

import { Tooltip } from './Tooltip';

interface Props {
  colors: ProductColorVariant[];
}

export const ProductColorAlternatives: React.FC<Props> = ({ colors }) => {
  const [activeTooltip, setActiveTooltip] = useState<string | null>(null);
  const { pathname } = useLocation();

  const { colorPlus, natur, naturColor } = useGetRoofColorImages();
  const slugFragments = pathname.split('/');
  const lastSlug = slugFragments.splice(4, slugFragments.length);

  return (
    <div css={product.colorsWrapper}>
      <div css={product.colorAlternativeWrapper}>
        <span>
          <FormattedMessage id="products.color-alternative.title" />
        </span>

        <div css={product.colorsPallete}>
          {colors.map((color, index) => (
            <div key={index} css={product.colorAlternative}>
              <Link to={`${slugFragments.join('/')}${color.slug}`}>
                <EllipseColorIcon
                  colorCode={color.colorCode}
                  color={color.color}
                  active={`/${lastSlug[0]}` === color.slug}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                />
              </Link>

              {color.color && (
                <Tooltip
                  active={activeTooltip === color.color}
                  text={color.color}
                  className={product.tooltip}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <div css={product.colorInfoWrapper}>
        <div css={product.colorInfoBox}>
          <GatsbyImage {...colorPlus} css={product.colorImage} />
          <p css={product.colorInfo}>
            <FormattedMessage id="products.color-alternative.natur" />
          </p>
        </div>
        <div css={product.colorInfoBox}>
          <GatsbyImage {...natur} css={product.colorImage} />
          <p css={product.colorInfo}>
            <FormattedMessage id="products.color-alternative.color-plus" />
          </p>
        </div>
        <div css={product.colorInfoBox}>
          <GatsbyImage {...naturColor} css={product.colorImage} />
          <p css={product.colorInfo}>
            <FormattedMessage id="products.color-alternative.natur-color" />
          </p>
        </div>
      </div>
    </div>
  );

  function onMouseEnter(color: string) {
    setActiveTooltip(color);
  }

  function onMouseLeave() {
    setActiveTooltip(null);
  }
};
