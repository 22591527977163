import React from 'react';

export const PlusIcon: React.FC = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M6 1.2002V10.8002"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M1.19995 6H10.8"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="9.6"
          height="9.6"
          fill="white"
          transform="translate(1.19995 1.2002)"
        />
      </clipPath>
    </defs>
  </svg>
);
