import { ClientRequest } from '../models';
import { RequestsActions } from './actions';
import { RequestsTypes } from './types';

export interface RequestsState {
  requestsAreChanging: boolean;
  error?: string;
  clientRequests: ClientRequest[];
  ownRequests: ClientRequest[];
}

const INITIAL_STATE: RequestsState = {
  requestsAreChanging: true,
  error: undefined,
  clientRequests: [],
  ownRequests: [],
};

export default (
  state: RequestsState = INITIAL_STATE,
  action: RequestsActions,
) => {
  switch (action.type) {
    case RequestsTypes.AddLoading:
      return {
        ...state,
        requestsAreChanging: true,
        error: undefined,
      };

    case RequestsTypes.AddClientRequests:
      return {
        ...state,
        requestsAreChanging: false,
        error: undefined,
        clientRequests: action.payload.requests,
      };

    case RequestsTypes.AddOwnRequests:
      return {
        ...state,
        requestsAreChanging: false,
        error: undefined,
        ownRequests: action.payload.requests,
      };

    case RequestsTypes.AddError:
      return {
        ...state,
        requestsAreChanging: false,
        error: action.payload.error,
      };

    default:
      return state || INITIAL_STATE;
  }
};
