import css from '@emotion/css';
import {
  colors,
  fontFamily,
  fontSize,
  fontWeights,
  lineHeight,
  unit,
} from 'styles';

const wrapper = css`
  max-width: 220px;
  height: 264px;
  cursor: pointer;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;

  border: 2px solid transparent;
  margin: auto;

  &:hover {
    border: 2px solid ${colors.primary};
  }
`;

const container = css`
  padding-bottom: ${unit * 6}px;
  padding-left: ${unit * 2}px;
  padding-right: ${unit * 2}px;
  text-align: center;
`;

const title = css`
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightRegular};
  font-size: ${fontSize.small}px;
  line-height: ${lineHeight.small}px;

  color: ${colors.textSecondary};
  max-width: 140px;
`;

const image = css`
  width: 140px;
  height: 140px;
`;

export const cardStyles = {
  wrapper,
  container,
  title,
  image,
};
