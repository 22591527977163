import 'react-image-gallery/styles/css/image-gallery.css';

import { SerializedStyles } from '@emotion/core';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import { DefaultPhoto } from 'models';
import React from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { product, productImageStyles } from 'styles';

interface Props {
  productImage: string;
  className?: SerializedStyles | string;
  images: string[];
}

export const ProductImage: React.FC<Props> = ({
  productImage,
  className,
  images,
}) => {
  const galleryImages: ReactImageGalleryItem[] = images
    ? images.map((img) => ({
        original: img,
        thumbnail: img,
        originalWidth: 320,
        originalHeight: 320,
        originalClass: 'product-image',
        thumbnailWidth: 100,
        thumbnailHeight: 100,
        thumbnailClass: 'product-thumbnail',
      }))
    : [];

  const defaultImagePhoto = useStaticQuery<DefaultPhoto>(graphql`
    query {
      defaultImagePhoto: file(relativePath: { eq: "no-image-product.png" }) {
        id
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div css={[productImageStyles.root, className]}>
      {galleryImages.length > 1 && (
        <ImageGallery
          items={galleryImages}
          showPlayButton={false}
          additionalClass={'image-carousel'}
          showFullscreenButton={false}
        />
      )}
      {galleryImages.length <= 1 && productImage && (
        <Image
          fixed={{
            src: productImage,
            srcSet: '',
            width: 320,
            height: 320,
          }}
        />
      )}

      {galleryImages.length <= 1 && !productImage && (
        <Image
          {...defaultImagePhoto.defaultImagePhoto.childImageSharp}
          alt="Bez slike"
          css={product.defaultPhoto}
        />
      )}
    </div>
  );
};
