import { SerializedStyles } from '@emotion/core';
import { ShieldIcon } from 'assets';
import React from 'react';

interface Props {
  className?: SerializedStyles;
}

export const Guarantee: React.FC<Props> = ({ className }) => (
  <div css={className}>
    <ShieldIcon />
    <span>33 godine</span>
  </div>
);
