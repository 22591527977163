import React from 'react';

export const WorldIcon: React.FC = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 23.25C15.5507 23.25 18.429 18.2132 18.429 12C18.429 5.7868 15.5507 0.75 12 0.75C8.44941 0.75 5.57104 5.7868 5.57104 12C5.57104 18.2132 8.44941 23.25 12 23.25Z"
      stroke="#3C3737"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.75 12H23.25"
      stroke="#3C3737"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 0.75V23.25"
      stroke="#3C3737"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 23.25C18.2132 23.25 23.25 18.2132 23.25 12C23.25 5.7868 18.2132 0.75 12 0.75C5.7868 0.75 0.75 5.7868 0.75 12C0.75 18.2132 5.7868 23.25 12 23.25Z"
      stroke="#3C3737"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
