import { Container, Pagination } from 'components';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { Product, ProductFirebase } from 'models';
import { usePagination } from 'modules/products';
import { ApplicationState } from 'modules/redux-store';
import { selectProductsByQuery, useGetAllProducts } from 'modules/search';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getProductRoute } from 'utils';

import { listingStyles } from '../styles';
import { isProductFirebase } from '../utils';

export const Search: React.FC = () => {
  const [filteredProducts, setFilteredProducts] = useState<ProductFirebase[]>(
    [],
  );
  const { searchInput } = useSelector(
    (state: ApplicationState) => state.search,
  );

  const queryProducts = selectProductsByQuery({
    allProducts: [...useGetAllProducts()],
    query: searchInput,
  });

  useEffect(() => {
    if (
      checkArrayOfObjectsAreEqual<Product[]>(filteredProducts, queryProducts) ||
      !isProductFirebase(queryProducts)
    )
      return;

    setFilteredProducts(queryProducts);
  }, [searchInput, queryProducts, filteredProducts]);

  const [itemsPerPage, setItemsPerPage] = useState(12);
  const { next, prev, jump, currentData, currentPage, maxPage } =
    usePagination<ProductFirebase>(filteredProducts, itemsPerPage);

  return (
    <div css={listingStyles.root}>
      <Container css={listingStyles.wrapper}>
        {filteredProducts.length === 0 ? (
          <div css={listingStyles.text}>
            <FormattedMessage id="search.no-results" />
          </div>
        ) : (
          <section css={listingStyles.listSection}>
            <h2 css={listingStyles.text}>
              <FormattedMessage id="search.results-for" />
              {searchInput}
            </h2>
            <ul css={listingStyles.listWrapper}>
              {currentData.map((product) => (
                <li
                  key={product.id + product.commercial.name}
                  css={listingStyles.listItem}
                >
                  <Link to={getProductRoute(product.commercial)}>
                    <Image
                      fluid={{
                        src:
                          product.commercial.assets.mainImg ||
                          product.commercial.assets.otherImgs[0],
                        aspectRatio: 1,
                        srcSet: '',
                        sizes: '',
                      }}
                      imgStyle={{ height: 'auto' }}
                      css={listingStyles.photo}
                    />
                    <span css={listingStyles.productText}>
                      {product.commercial.localizedName}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>

            {filteredProducts.length > 10 && (
              <Pagination
                currentPage={currentPage}
                next={next}
                prev={prev}
                jump={jump}
                maxPage={maxPage}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
              />
            )}
          </section>
        )}
      </Container>
    </div>
  );

  function checkArrayOfObjectsAreEqual<T>(o1: T, o2: T) {
    return (
      Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every((p) => o1[p] === o2[p])
    );
  }
};
