import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';
import { inbox } from 'styles';

interface Props {
  offerType: 'internal' | 'external' | null;
  setOfferType: React.Dispatch<
    React.SetStateAction<'internal' | 'external' | null>
  >;
}

export const OfferTypeTabs: React.FC<Props> = ({ offerType, setOfferType }) => {
  return (
    <div css={inbox.offerTypeTabs}>
      <button
        type="button"
        data-category="internal"
        onClick={handleOfferTypeChange}
        css={[
          inbox.categoryBtn,
          offerType === 'internal' && inbox.categoryBtnActive,
        ]}
      >
        <FormattedMessage id="inbox.offer-type-tabs.internal-offer" />
      </button>

      <button
        type="button"
        data-category="external"
        onClick={handleOfferTypeChange}
        css={[
          inbox.categoryBtn,
          offerType === 'external' && inbox.categoryBtnActive,
        ]}
      >
        <FormattedMessage id="inbox.offer-type-tabs.external-offer" />
      </button>
    </div>
  );

  function handleOfferTypeChange(event: React.MouseEvent<HTMLButtonElement>) {
    const { category } = event.currentTarget.dataset;
    if (!category || (category !== 'internal' && category !== 'external'))
      return;

    setOfferType(category);
  }
};
