import { SerializedStyles } from '@emotion/core';
import React from 'react';

interface Props {
  className?: SerializedStyles | string[];
}

export const UploadIcon: React.FC<Props> = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    css={className}
  >
    <g>
      <path
        d="M11.2812 4.71875L8 0.96875L4.71875 4.71875H6.59375V10.3438H9.40625V4.71875H11.2812Z"
        stroke="#B30000"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2188 6.59375H14.0938C14.6112 6.59375 15.0312 7.01375 15.0312 7.53125V14.0938C15.0312 14.6112 14.6112 15.0312 14.0938 15.0312H1.90625C1.38875 15.0312 0.96875 14.6112 0.96875 14.0938V7.53125C0.96875 7.01375 1.38875 6.59375 1.90625 6.59375H3.78125"
        stroke="#B30000"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="15"
          height="15"
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
