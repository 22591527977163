import { css } from '@emotion/core';
import { CSSProperties } from 'react';
import { DeepMap, FieldError } from 'react-hook-form';
import { Styles } from 'react-select';

import { spacers } from './spacers.styles';

export const selectStyles = (
  name: string,
  errors?: DeepMap<Record<string, unknown>, FieldError>,
): Partial<Styles<typeof Option, boolean>> => ({
  indicatorSeparator: (styles: CSSProperties) => ({
    ...styles,
    display: 'none',
  }),
  dropdownIndicator: (styles: CSSProperties) => ({
    ...styles,
    paddingRight: 16,
  }),
  control: (styles: CSSProperties) => ({
    ...styles,
    border:
      errors && errors[name]
        ? `1px solid rgba(241, 52, 86, 0.6)`
        : `1px solid rgba(0, 0, 0, 0.2)`,
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 0.6)',
      cursor: 'pointer',
    },
  }),
  placeholder: (styles: CSSProperties) => ({
    ...styles,
    opacity: 0.6,
  }),
});

const row = css`
  display: flex;
  align-items: center;

  img {
    ${spacers.right.large}
  }
`;

export const select = {
  row,
};
