import { useRoofCatAndModels } from 'modules/navigation/hooks';
import React from 'react';

import { CategoriesNav } from './CategoriesNav';

interface Props {
  closeNav: VoidFunction;
  onBackClick: VoidFunction;
}

export const RoofNavigation: React.FC<Props> = ({ closeNav, onBackClick }) => {
  const { allRoofCategoriesWithModelsJson } = useRoofCatAndModels();

  return (
    <CategoriesNav
      product="krov"
      closeNav={closeNav}
      allCategories={allRoofCategoriesWithModelsJson}
      onBackClick={onBackClick}
    />
  );
};
