import { Collection } from 'consts';
import { FirestoreService, StorageService } from 'modules/firebase';

import { ClientRequest } from '../models';

const Database = FirestoreService<ClientRequest>(Collection.ClientRequest);

const Storage = StorageService(Collection.ClientRequest);

export const RequestsService = {
  Database,
  Storage,
};
