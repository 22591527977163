import { ChevronRight } from 'assets';
import { Link } from 'gatsby';
import { ApplicationState } from 'modules/redux-store';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { layout } from 'styles';
import { slugify } from 'utils';

interface Props {
  navigationCategory: string;
  category?: string;
  model?: string;
  product?: string;
}

export const Breadcrumbs: React.FC<Props> = ({
  navigationCategory,
  category,
  model,
  product,
}) => {
  const { user } = useSelector((state: ApplicationState) => state.auth);

  const breadCrumbs =
    user && !user.emailVerified
      ? layout.breadcrumbs
      : layout.breadcrumbsNoEmailVerificationMessage;

  return (
    <section css={breadCrumbs}>
      <Link to={`/${slugify(navigationCategory)}`}>{navigationCategory}</Link>

      {category && (
        <Fragment>
          <ChevronRight opacity="0.15" width="28" />

          {!model && !product ? (
            <span>{category}</span>
          ) : (
            <Link
              to={`/${slugify(navigationCategory)}/${removeCategoryLine(
                slugify(category),
              )}`}
            >
              {category}
            </Link>
          )}
        </Fragment>
      )}

      {category && model && (
        <Fragment>
          <ChevronRight opacity="0.15" width="28" />

          {!product ? (
            <span>{model}</span>
          ) : (
            <Link
              to={`/${slugify(navigationCategory)}/${removeCategoryLine(
                slugify(category),
              )}/${slugify(model)}`}
            >
              {model}
            </Link>
          )}
        </Fragment>
      )}

      {product && (
        <Fragment>
          <ChevronRight opacity="0.15" width="28" />

          <span>{product}</span>
        </Fragment>
      )}
    </section>
  );

  function removeCategoryLine(text: string) {
    return text.replace('-line', '');
  }
};
