import { Position } from 'modules/cart';

import { MapActions } from './actions';
import { MapTypes } from './types';

export interface MapState {
  location: Position | null;
}

const INITIAL_STATE: MapState = {
  location: null,
};

export default (
  state: MapState = INITIAL_STATE,
  action: MapActions,
): MapState => {
  switch (action.type) {
    case MapTypes.AddLocation:
      return {
        ...state,
        location: action.payload.position,
      };

    default:
      return state || INITIAL_STATE;
  }
};
