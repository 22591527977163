import { Product } from 'models';
import { ActionUnion, createAction } from 'modules/redux-store';

import { ProductsTypes } from './types';

const AddActions = {
  addLastViewedProducts: (products: Product[]) =>
    createAction(ProductsTypes.AddLastViewedProducts, { products }),
  addError: (error?: string) => createAction(ProductsTypes.AddError, { error }),
};

export const ProductsActions = {
  ...AddActions,
};

export type ProductsActions = ActionUnion<typeof ProductsActions>;
