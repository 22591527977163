import { ActionUnion, createAction } from 'modules/redux-store';

import { Distributor } from '../models';
import { DistributorTypes } from './types';

export const DistributorActions = {
  addActiveDistributors: (activeDistributors: Distributor[]) =>
    createAction(DistributorTypes.addActiveDistributors, {
      activeDistributors,
    }),
  addLoading: () => createAction(DistributorTypes.addLoading),
  addError: (error: string) =>
    createAction(DistributorTypes.addError, { error }),
};

export type DistributorActions = ActionUnion<typeof DistributorActions>;
