import { css } from '@emotion/core';

import { typescale } from './typescale.styles';
import { colors, fontFamily } from './vars.styles';

export const globalStyles = css`
  html {
    box-sizing: border-box;

    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }
  }

  input {
    -webkit-user-select: text; /* Chrome, Opera, Safari */
    -moz-user-select: text; /* Firefox 2+ */
    -ms-user-select: text; /* IE 10+ */
    user-select: text; /* Standard syntax */
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
  }

  body {
    margin: 0;
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;

    font-family: ${fontFamily.sans};
    ${typescale.base}
    color: ${colors.text};
    background: ${colors.bg};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  p {
    margin: 0;
  }

  ul,
  ol,
  dl {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul[class],
  ol[class],
  dl[class] {
    list-style: none;
  }

  img {
    display: block;
    height: auto;
  }

  a {
    text-decoration: none;
    color: ${colors.primary};
  }

  @font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/DMSans-Regular.ttf') format('ttf');
  }
  @font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    src: url('../assets/fonts/DMSans-Bold.ttf') format('ttf');
  }
`;
