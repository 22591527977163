import { graphql, useStaticQuery } from 'gatsby';

export const useRoofCatAndModels = () => {
  const { allRoofCategoriesWithModelsJson } = useStaticQuery(graphql`
    query allRoofCategoriesWithModelsJson {
      allRoofCategoriesWithModelsJson {
        nodes {
          category
          models
        }
      }
    }
  `);

  return { allRoofCategoriesWithModelsJson };
};
