import { SerializedStyles } from '@emotion/core';
import React from 'react';
import { buttons, loading } from 'styles';

interface Props {
  text: string;
  stateIsChanging?: boolean;
  disabled?: boolean;
  className?: SerializedStyles | SerializedStyles[];
  isUserAnon?: boolean;
}

export const FormSubmit: React.FC<Props> = ({
  text,
  stateIsChanging,
  disabled,
  className,
  isUserAnon,
}) =>
  stateIsChanging ? (
    <button type="button" disabled css={className || buttons.primary.medium}>
      <span css={isUserAnon ? loading.anonSpinner : loading.spinner} />
    </button>
  ) : (
    <input
      type="submit"
      value={text}
      css={className || buttons.primary.medium}
      disabled={disabled}
    />
  );
