import { SerializedStyles } from '@emotion/core';
import { TimesIcon } from 'assets';
import React from 'react';
import { fields, inputs } from 'styles';

interface Props {
  name: string;
  label?: string;
  size?: 'xsmall' | 'small' | 'normal';
  defaultValue?: string;
  value?: string | number;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: SerializedStyles;
  fieldClassName?: SerializedStyles;
  onChange?: React.Dispatch<React.SetStateAction<any>>;
  onClose?: VoidFunction;
}

export const ControlledInput: React.FC<Props> = ({
  name,
  label,
  size,
  defaultValue,
  value,
  type,
  className,
  fieldClassName,
  placeholder,
  disabled,
  onChange,
  onClose,
}) => {
  return (
    <div css={fieldClassName || fields.field}>
      {label && (
        <label css={fields.label} htmlFor={name}>
          {label}
        </label>
      )}
      <input
        type={type || 'text'}
        id={name}
        name={name}
        defaultValue={defaultValue}
        value={value}
        onChange={handleChange}
        css={className || inputs.input[size || 'normal']}
        placeholder={placeholder}
        disabled={disabled}
      />

      {onClose && (
        <button type="button" css={inputs.closeBtn} onClick={onClose}>
          <TimesIcon />
        </button>
      )}
    </div>
  );

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!onChange) return;
    const { value, valueAsNumber } = event.currentTarget;

    onChange(isNaN(parseFloat(value)) ? value : valueAsNumber);
  }
};
