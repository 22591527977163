import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { MapStyles } from 'consts/MapStyles';
import { MapActions } from 'modules/map';
import { AppDispatch, ApplicationState } from 'modules/redux-store';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const containerStyle = {
  width: '100%',
  height: '50vh',
};

const center = {
  lat: 44.8919646,
  lng: 16.2523895,
};

export const ChooseLocationModal: React.FC = () => {
  const googleMap = useRef<google.maps.Map>();
  const dispatch = useDispatch<AppDispatch>();
  const { location } = useSelector((state: ApplicationState) => state.map);

  return (
    <LoadScript googleMapsApiKey={process.env.GATSBY_API_KEY}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={8.6}
        onClick={handleMapClick}
        onLoad={handleOnLoad}
        options={{
          styles: MapStyles,
        }}
      >
        {location && (
          <Marker
            position={{
              lat: location.lat,
              lng: location.lng,
            }}
            draggable
          />
        )}
      </GoogleMap>
    </LoadScript>
  );

  function handleMapClick(event: google.maps.MouseEvent) {
    dispatch(
      MapActions.addLocation({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      }),
    );
  }

  function handleOnLoad(map: google.maps.Map) {
    googleMap.current = map;
  }
};
