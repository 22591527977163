export function resolveCategory(category: string) {
  switch (category) {
    case 'Roof':
      return 'KROVA';
    case 'Roof Acc':
    case 'RoofAcc':
      return 'KROVNIH DODATAKA';
    case 'Wall':
      return 'ZIDA';
    case 'Facade':
      return 'FASADNIH OPEKA';
    case 'RoofWindows':
      return 'KROVNIH PROZORA';
    case 'RoofWindowsAccessories':
      return 'OPREME KROVNIH PROZORA';

    default:
      return category;
  }
}
