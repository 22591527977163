import React from 'react';

export const DownloadIcon: React.FC = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M5.39062 6.42188L9 10.5469L12.6094 6.42188H10.5469V1.26562H7.45312V6.42188H5.39062Z"
        stroke="#B30000"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.26562 13.6406H16.7344"
        stroke="#B30000"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <rect x="1.25" y="1.25" width="15.5" height="15.5" stroke="#B30000" />
    <defs>
      <clipPath id="clip0"></clipPath>
    </defs>
  </svg>
);
