import { RooftileColors } from 'consts';
import React from 'react';
import { product } from 'styles';

interface Props {
  color: string;
  colorCode: string;
  active: boolean;
  onMouseEnter?: (color: string) => void;
  onMouseLeave?: VoidFunction;
}

export const EllipseColorIcon: React.FC<Props> = ({
  color,
  colorCode,
  active,
  onMouseEnter,
  onMouseLeave,
}) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    css={[product.colorCircle, active && product.colorCircleActive]}
    onMouseEnter={onMouseEnter?.bind(null, color)}
    onMouseLeave={onMouseLeave}
  >
    <circle cx="14" cy="14" r="14" fill={RooftileColors[colorCode]} />
  </svg>
);
