import { navigate as navigateTo, useLocation } from '@reach/router';
import { ResetPasswordRegExp } from 'consts';
import { AppDispatch } from 'modules/redux-store';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { AuthThunks } from '../redux';

interface State {
  loading: boolean;
  error?: string;
  success?: boolean;
  codeValid: boolean;
}

export const useAuthCheck = () => {
  const [state, setState] = useState<State>({
    loading: true,
    codeValid: false,
  });
  const methods = useForm<{ password: string; repeatPassword: string }>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const dispatch = useDispatch<AppDispatch>();

  const { search } = useLocation();
  const searchString = ResetPasswordRegExp.exec(search);
  const [, , mode, , resetPwCode] = searchString || [''];

  const checkQueryString = useCallback(() => {
    if (!mode || !resetPwCode) {
      navigateTo('/404');
      return false;
    }

    return true;
  }, [resetPwCode, mode]);

  const checkResetPwCodeValidity = useCallback(async () => {
    if (!checkQueryString()) {
      return;
    }

    const error = await dispatch(
      AuthThunks.verifyPasswordResetCode(resetPwCode),
    );

    setState({
      success: false,
      loading: false,
      error,
      codeValid: !error,
    });
  }, [dispatch, resetPwCode, checkQueryString]);

  const checkVerifyEmailCodeValidity = useCallback(async () => {
    const error = await dispatch(AuthThunks.verifyEmailValidCode(resetPwCode));

    setState({
      success: true,
      loading: false,
      error,
      codeValid: !error,
    });
  }, [dispatch, resetPwCode]);

  useEffect(() => {
    if (!state.codeValid && mode === 'resetPassword') {
      checkResetPwCodeValidity();
    }

    if (!state.codeValid && mode === 'verifyEmail') {
      checkVerifyEmailCodeValidity();
    }
  }, [
    checkResetPwCodeValidity,
    checkVerifyEmailCodeValidity,
    state.codeValid,
    mode,
  ]);

  async function onSubmit(data: { password: string; repeatPassword: string }) {
    if (!checkQueryString()) {
      return;
    }

    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    const error = await dispatch(
      AuthThunks.confirmPasswordReset(resetPwCode, data.password),
    );

    setState({
      loading: false,
      error,
      success: !error ? true : false,
      codeValid: true,
    });
  }

  return {
    state,
    mode,
    methods,
    onSubmit,
  };
};
