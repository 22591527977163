import { Document, Page, StyleSheet } from '@react-pdf/renderer';
import { Distributor } from 'modules/distributors';
import { ClientRequest, InternalOfferModel } from 'modules/inbox';
import { DistributorInfo, Offer } from 'modules/pdf';
import React from 'react';

interface Props {
  currentRequest?: ClientRequest;
  distributors: Distributor[];
  offer: InternalOfferModel;
}

export const OfferPdf: React.FC<Props> = ({
  currentRequest,
  distributors,
  offer,
}) => {
  const currentDistributor = distributors.find(
    (distributor) => distributor.id === currentRequest?.distributorId,
  );

  return (
    <Document>
      <Page style={styles.body}>
        <DistributorInfo
          distributor={currentDistributor}
          currentRequest={currentRequest}
        />
        <Offer offer={offer} />
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  body: {
    paddingTop: 45,
    paddingBottom: 75,
    paddingHorizontal: 50,
  },
});
