import { ArrowRight, BackArrow } from 'assets';
import { Link } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';
import React, { useState } from 'react';
import { navStyles } from 'styles';
import { slugify } from 'utils';

interface Props {
  product: string;
  closeNav: VoidFunction;
  onBackClick: VoidFunction;
  allCategories: any;
}

export const CategoriesNav: React.FC<Props> = ({
  product,
  closeNav,
  allCategories,
  onBackClick,
}) => {
  const [showModels, setShowModels] = useState<string[]>([]);
  const [category, setCategory] = useState<string | null>();

  return showModels.length === 0 ? (
    <ul>
      <div css={navStyles.backWrapper} onClick={onBackClick}>
        <BackArrow />
        <p css={navStyles.back}>
          <FormattedMessage id="navigation.back" />
        </p>
      </div>
      <li css={navStyles.allProducts}>
        <Link
          to={`/${product}/pregled-svih-proizvoda`}
          onClick={closeNav}
          css={navStyles.category}
        >
          <FormattedMessage id="navigation.all-items" />
        </Link>
      </li>
      {product === 'krov' && (
        <li css={navStyles.allProducts}>
          <Link
            to={`/krovni-dodaci/pregled-svih-proizvoda`}
            onClick={closeNav}
            css={navStyles.category}
          >
            <FormattedMessage id="navigation.roof-addons" />
          </Link>
        </li>
      )}

      {allCategories.nodes.map(
        (prod: { category: string; models: string[] }) => (
          <div key={prod.category}>
            <li>
              {prod.models.length > 0 ? (
                <div
                  css={navStyles.categoryList}
                  onClick={handleCategoryClick.bind(null, prod)}
                >
                  <p css={navStyles.category}>{prod.category}</p>
                  <ArrowRight />
                </div>
              ) : (
                <Link
                  to={`/${product}/${slugify(prod.category)}`}
                  onClick={closeNav}
                  css={navStyles.category}
                >
                  {prod.category}
                </Link>
              )}
            </li>
          </div>
        ),
      )}
    </ul>
  ) : (
    <ul css={navStyles.list}>
      <div css={navStyles.backWrapper} onClick={backToCategory}>
        <BackArrow />
        <p css={navStyles.back}>{product}</p>
      </div>
      <li css={navStyles.allProducts}>
        <Link
          to={`/${product}/${slugify(category || '')}`}
          onClick={closeNav}
          css={navStyles.category}
        >
          <FormattedMessage id="navigation.all-items" />
        </Link>
      </li>
      {showModels.map((model) => (
        <li key={model}>
          <Link
            to={`/${product}/${slugify(category || '')}/${slugify(model)}`}
            onClick={closeNav}
            css={navStyles.category}
          >
            {model}
          </Link>
        </li>
      ))}
    </ul>
  );

  function backToCategory() {
    setCategory(null);
    setShowModels([]);
  }

  function handleCategoryClick(prod: { category: string; models: string[] }) {
    setShowModels(prod.models);
    setCategory(prod.category);
  }
};
