import { css } from '@emotion/core';

import { color } from './mixins.styles';
import { mqFrom } from './responsive.styles';
import {
  colors,
  fontFamily,
  fontSize,
  fontWeights,
  globalRadial,
  lineHeight,
  unit,
} from './vars.styles';

const overlay = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${colors.secondary};
  opacity: 0.5;
`;

const wrapper = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

const container = css`
  background: white;
  width: 100%;
  margin: 0 32px;
  box-shadow: 0 2px 5px ${color(colors.dark, 0.2)};
  transition: transform 0.2s, opacity 0.2s;
  border-radius: ${globalRadial * 2}px;
  height: 600px;
  overflow: auto;

  ${mqFrom.Sml} {
    max-width: 85vw;
  }

  ${mqFrom.Med} {
    max-width: 75vw;
  }

  ${mqFrom.Lrg} {
    max-width: 65vw;
    height: auto;
  }

  ${mqFrom.Xlrg} {
    max-width: 60vw;
  }

  ${mqFrom.Xxlrg} {
    max-width: 55vw;
  }
`;

const header = css`
  display: flex;
  justify-content: flex-end;
  padding: ${unit * 8}px;
`;

const footer = css`
  padding: ${unit * 8}px;
  padding-top: 0;
`;

const content = css`
  padding: ${unit * 2}px;
  padding-top: 0;

  ${mqFrom.Med} {
    ${footer}
  }
`;

const close = css`
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: 0.3;
  cursor: pointer;
  border: none;
  outline: none;

  transition: all 0.3s ease-out;
  border-radius: 50%;
  height: 25px;

  &:hover {
    background-color: ${colors.primary};
    color: ${colors.bg};
    opacity: 1;
  }
`;

const declineButton = css`
  color: ${colors.primary};
  padding: 0 ${unit * 8}px;
  line-height: 48px;
  margin-left: 6px;

  &:hover,
  &:focus {
    box-shadow: 0px 0px 0px 5px ${color(colors.primary, 0.25)};
  }
`;

const title = css`
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightRegular};
  font-size: ${fontSize.medium}px;
  line-height: ${lineHeight.medium}px;

  color: ${colors.textSecondary};

  ${mqFrom.Sml} {
    font-size: ${fontSize.large}px;
    line-height: ${lineHeight.large}px;
  }
`;

const spaceBetween = css`
  justify-content: space-between;
`;

const backWrapper = css`
  width: 30px;
  height: 30px;
  margin-right: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  transition: all 0.3s;

  cursor: pointer;

  &:hover {
    svg {
      path {
        stroke: ${colors.primary};
      }
    }

    background-color: #e0e0e0;
  }
`;

const titleWrapper = css`
  display: flex;
  align-items: center;
`;

export const modal = {
  overlay,
  wrapper,
  container,
  header,
  footer,
  content,
  close,
  title,
  declineButton,
  backWrapper,
  spaceBetween,
  titleWrapper,
};
