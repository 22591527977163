enum SetActions {
  SetListener = 'listener/SET_LISTENER',
}

enum ClearActions {
  RemoveListener = 'listener/REMOVE_LISTENER',
  RemoveAllListeners = 'listener/REMOVE_ALL_LISTENERS',
}

export const FirestoreListenerActionTypes = {
  ...SetActions,
  ...ClearActions,
};
