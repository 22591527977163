import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';
import { listing } from 'styles';

interface Props {
  brand: string;
  amount: number;
}

export const ProductBrand: React.FC<Props> = ({ brand, amount }) => (
  <>
    <h1 css={listing.categoryTitle}>{brand}</h1>
    <p css={listing.categoryAmount}>
      <span>{amount}</span> <FormattedMessage id="products.category.count" />
    </p>
  </>
);
