import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { MapStyles } from 'consts/MapStyles';
import { Distributor } from 'modules/distributors';
import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { map } from 'styles';

import { Location, MapDistanceOptions } from '../models';

const containerStyle = {
  maxWidth: '980px',
  minHeight: '300px',
  height: '100%',
  width: '100%',
};

interface Props {
  userPosition: Location;
  distributors: Distributor[];
  handleDistanceChange(type: MapDistanceOptions): void;
}

const DistributorsMap: React.FC<Props> = ({
  userPosition,
  distributors,
  handleDistanceChange,
}) => {
  const methods = useFormContext();
  const [mapDistanceOption, setMapDistanceOption] =
    useState<MapDistanceOptions>('20km');
  const googleMap = useRef<google.maps.Map>();
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    if (!googleMap.current) return;
    const bounds = new google.maps.LatLngBounds();

    distributors.forEach((dist) => bounds.extend(dist.location));
    googleMap.current.fitBounds(bounds);
  }, [distributors]);

  return (
    <div css={map.wrapper}>
      <LoadScript googleMapsApiKey={process.env.GATSBY_API_KEY}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={userPosition}
          zoom={9}
          onLoad={handleOnLoad}
          options={{
            styles: MapStyles,
            disableDefaultUI: true,
          }}
        >
          {mapLoaded &&
            distributors.map((dist) => (
              <Marker
                key={dist.id}
                position={dist.location}
                onClick={onMarkerClick.bind(this, dist)}
                title={dist.name}
                label={{
                  color: '#FFFFFF',
                  text: dist.name.substr(0, 2),
                  fontSize: '12px',
                }}
                icon={{
                  url: require('content/MarkerBlank.png'),
                }}
              />
            ))}
          <Marker
            position={userPosition}
            options={{
              zIndex: 90,
            }}
          />
        </GoogleMap>
      </LoadScript>

      <div css={map.options}>
        <button
          data-type="20km"
          onClick={handleMapDistanceOptionChange}
          type="button"
          css={
            mapDistanceOption === '20km' ? map.optionBtnActive : map.optionBtn
          }
        >
          20km
        </button>
        <button
          data-type="Cijela Hrvatska"
          onClick={handleMapDistanceOptionChange}
          type="button"
          css={
            mapDistanceOption === 'Cijela Hrvatska'
              ? map.optionBtnActive
              : map.optionBtn
          }
        >
          Cijela Hrvatska
        </button>
      </div>
    </div>
  );

  function handleMapDistanceOptionChange(
    event: React.MouseEvent<HTMLButtonElement>,
  ) {
    const { type } = event.currentTarget.dataset;
    if (!type || !googleMap.current) return;

    setMapDistanceOption(type as MapDistanceOptions);

    if ((type as MapDistanceOptions) === '20km') {
      googleMap.current.panTo(userPosition);
      googleMap.current.setZoom(9);

      handleDistanceChange(type as MapDistanceOptions);
    } else if ((type as MapDistanceOptions) === 'Cijela Hrvatska') {
      handleDistanceChange(type as MapDistanceOptions);
    }
  }

  function handleOnLoad(map: google.maps.Map) {
    googleMap.current = map;
    setMapLoaded(true);
  }

  function onMarkerClick(event: Distributor) {
    const currentValue = methods.getValues(event.id);
    methods.setValue(event.id, !currentValue);
  }
};

export default React.memo(DistributorsMap);
