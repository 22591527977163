import { Link } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';
import { layout, spacers } from 'styles';
import { slugify } from 'utils';

import { useWallsCatAndModels } from '../hooks';

interface Props {
  visible: boolean;
  handleClose: VoidFunction;
}

export const WallsSubnav: React.FC<Props> = ({ visible, handleClose }) => {
  const { sortedAllWallCategoriesWithModels } = useWallsCatAndModels();

  return (
    <ul css={[layout.subnav, visible && layout.subnavVisible]}>
      <div css={layout.subnavCol}>
        <Link
          to="/zid/pregled-svih-proizvoda"
          css={[layout.model, spacers.top.extraHuge]}
          onClick={handleClose}
        >
          <FormattedMessage id="navigation.all-items" />
        </Link>
      </div>

      {sortedAllWallCategoriesWithModels.map(
        (wall: { category: string; models: string[] }) => (
          <div key={wall.category} css={layout.subnavCol}>
            <Link
              to={`/zid/${slugify(wall.category)}`}
              css={[layout.category, spacers.bottom.huge]}
              onClick={handleClose}
            >
              {wall.category}
            </Link>

            {wall.models.map((model) => (
              <Link
                key={model}
                to={`/zid/${slugify(wall.category)}/${slugify(model)}`}
                css={layout.model}
                onClick={handleClose}
              >
                {model}
              </Link>
            ))}
          </div>
        ),
      )}
    </ul>
  );
};
