import { Link } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';
import React, { useEffect, useState } from 'react';
import { headerStyles } from 'styles';

import { SubNavState } from './Header';

interface Props {
  pathname: string;
  subnavState: SubNavState;
  handleSubnavOpen: (event: React.PointerEvent<HTMLAnchorElement>) => void;
}

const INITIAL_STATE = {
  walls: false,
  roof: false,
  facade: false,
  roofWindows: false,
};

export const Navigation: React.FC<Props> = ({
  handleSubnavOpen,
  subnavState,
  pathname,
}) => {
  const [navActive, setNavActive] = useState<SubNavState>(INITIAL_STATE);

  useEffect(() => {
    if (pathname.includes('zid'))
      setNavActive({ ...INITIAL_STATE, walls: true });
    else if (pathname.includes('krovni-prozori'))
      setNavActive({ ...INITIAL_STATE, roofWindows: true });
    else if (pathname.includes('krov'))
      setNavActive({ ...INITIAL_STATE, roof: true });
    else if (pathname.includes('fasada'))
      setNavActive({ ...INITIAL_STATE, facade: true });
    else setNavActive({ ...INITIAL_STATE });
  }, [
    navActive.facade,
    navActive.roof,
    navActive.walls,
    navActive.roofWindows,
    pathname,
  ]);

  return (
    <nav css={headerStyles.center}>
      <ul css={headerStyles.nav}>
        <Link
          css={[
            headerStyles.navLink,
            subnavState.walls && headerStyles.navLinkOpened,
            navActive.walls && headerStyles.navLinkActive,
          ]}
          data-id="walls"
          activeClassName="active"
          to="/zid/pregled-svih-proizvoda"
          onMouseEnter={handleSubnavOpen}
        >
          <strong css={headerStyles.title}>
            <FormattedMessage id="navigation.wall" />
          </strong>
        </Link>
        <Link
          css={[
            headerStyles.navLink,
            subnavState.roof && headerStyles.navLinkOpened,
            navActive.roof && headerStyles.navLinkActive,
          ]}
          to="/krov/pregled-svih-proizvoda"
          data-id="roof"
          activeClassName="active"
          onMouseEnter={handleSubnavOpen}
        >
          <strong css={headerStyles.title}>
            <FormattedMessage id="navigation.roof" />
          </strong>
        </Link>
        <Link
          css={[
            headerStyles.navLink,
            subnavState.facade && headerStyles.navLinkOpened,
            navActive.facade && headerStyles.navLinkActive,
          ]}
          to="/fasada/pregled-svih-proizvoda"
          activeClassName="active"
          data-id="facade"
          onMouseEnter={handleSubnavOpen}
        >
          <strong css={headerStyles.title}>
            <FormattedMessage id="navigation.facade" />
          </strong>
        </Link>
        <Link
          css={[
            headerStyles.navLink,
            subnavState.roofWindows && headerStyles.navLinkOpened,
            navActive.roofWindows && headerStyles.navLinkActive,
          ]}
          to="/krovni-prozori/pregled-svih-proizvoda"
          activeClassName="active"
          data-id="roofWindows"
          onMouseEnter={handleSubnavOpen}
        >
          <strong css={headerStyles.title}>
            <FormattedMessage id="navigation.roof-windows" />
          </strong>
        </Link>
      </ul>
    </nav>
  );
};
