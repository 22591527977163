import { FormattedMessage } from 'gatsby-plugin-intl';
import React, { useEffect, useState } from 'react';
import { buttons, listing, spacers } from 'styles';
import { WallListingState } from 'template/AllWallListing';
import { handleShowFilterButton } from 'utils';

import { ProductBrand } from './ProductBrand';
import { WallFilters } from './WallFilters';

interface Props {
  amount: number;
  categories: string[];
  models: string[];
  wallWidths: string[];
  piecesPerMsq: string[];
  filterState: WallListingState;
  handleChange: (name: string, value: boolean, section?: string) => void;
  clearFilters: VoidFunction;
}

export const DesktopWallFilters: React.FC<Props> = ({
  amount,
  categories,
  models,
  wallWidths,
  piecesPerMsq,
  filterState,
  handleChange,
  clearFilters,
}) => {
  const [isFilterPicked, setIsFilterPicked] = useState(false);

  useEffect(() => {
    setIsFilterPicked(handleShowFilterButton(filterState));
  }, [filterState]);

  const handleClick = () => {
    clearFilters();
    setIsFilterPicked(false);
  };

  const isButtonHidden = isFilterPicked ? '' : buttons.hidden;
  return (
    <section css={listing.filters}>
      <ProductBrand brand="Porotherm" amount={amount} />

      <div css={isButtonHidden}>
        <button
          type="button"
          css={[buttons.ghost.small, spacers.bottom.huge]}
          onClick={handleClick}
        >
          <FormattedMessage id="products.clear-filter" />
        </button>
      </div>
      <WallFilters
        categories={categories}
        models={models}
        wallWidths={wallWidths}
        piecesPerMsq={piecesPerMsq}
        handleChange={handleChange}
        filterState={filterState}
      />
    </section>
  );
};
