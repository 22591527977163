import { Distributor } from 'modules/distributors';
import { ClientRequest } from 'modules/inbox';
import React from 'react';
import { inbox } from 'styles';

interface Props {
  currentRequest?: ClientRequest;
  distributors: Distributor[];
}

export const DistributorInfo: React.FC<Props> = ({
  currentRequest,
  distributors,
}) => {
  const currentDistributor = distributors.find(
    (distributor) => distributor.id === currentRequest?.distributorId,
  );

  return (
    <div css={inbox.distributorDetails}>
      <div css={inbox.detailsRow}>
        <div css={inbox.detailsTitle}>
          <div css={inbox.distributorLogoWrapper}>
            <h3>{currentDistributor?.name}</h3>
          </div>

          <p>#WBSHOP_{currentRequest?.clientRequestId}</p>
        </div>
      </div>

      <div>
        {currentDistributor?.logoFull && (
          <img
            src={currentDistributor?.logoFull}
            alt={currentDistributor?.name}
            css={inbox.distributorLogoFull}
          />
        )}
      </div>

      <div css={inbox.detailsRow}>
        <div css={inbox.detailsAddress}>
          <p>{currentDistributor?.address}</p>
          <p>
            {currentDistributor?.postCode} {currentDistributor?.city},{' '}
            {currentDistributor?.country}
          </p>
        </div>

        <div css={inbox.detailsEmailDesktop}>
          <p>{currentDistributor?.email || 'Temp email'}</p>
          <span>EMAIL</span>
        </div>
      </div>

      <div css={inbox.detailsRow}>
        <div>
          <p>{currentDistributor?.vatId || 'Temp VAT ID'}</p>
          <span>VAT ID</span>
        </div>

        <div>
          <p>{currentDistributor?.phone || 'Temp phone'}</p>
          <span>KONTAKT</span>
        </div>
      </div>

      <div css={inbox.detailsEmailMobile}>
        <p>{currentDistributor?.email || 'Temp email'}</p>
        <span>EMAIL</span>
      </div>
    </div>
  );
};
