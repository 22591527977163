import { SerializedStyles } from '@emotion/core';
import { CloseIcon, Step1, Step2, Step3 } from 'assets';
import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';
import { buttons, copyStyles } from 'styles';

interface Props {
  title: string;
  step1Title: string;
  step1Description: string;
  step2Title: string;
  step2Description: string;
  step3Title: string;
  step3Description: string | JSX.Element;
  containerStyles?: SerializedStyles;
  showCloseButton?: boolean;
  handleCloseClick?: VoidFunction;
}

export const Copy: React.FC<Props> = ({
  title,
  step1Title,
  step1Description,
  step2Title,
  step2Description,
  step3Title,
  step3Description,
  containerStyles,
  showCloseButton,
  handleCloseClick,
}) => (
  <div css={containerStyles}>
    <div css={copyStyles.closeWrapper}>
      <h2 css={copyStyles.infoTitle}>{title}</h2>
      {showCloseButton && (
        <button
          type="button"
          css={buttons.container}
          onClick={handleCloseClick}
        >
          <CloseIcon />
        </button>
      )}
    </div>
    <div css={copyStyles.infoSection}>
      <div css={copyStyles.content}>
        <div css={copyStyles.titleWrapper}>
          <Step1 />
          <p css={copyStyles.step}>
            <FormattedMessage id="shared.step" values={{ step: 1 }} />
          </p>
          <div css={copyStyles.dot} />
        </div>
        <h2 css={copyStyles.subtitle}>{step1Title}</h2>
        <p css={copyStyles.text}>{step1Description}</p>
      </div>
      <div css={copyStyles.content}>
        <div css={copyStyles.titleWrapper}>
          <Step2 />
          <p css={copyStyles.step}>
            <FormattedMessage id="shared.step" values={{ step: 2 }} />
          </p>
          <div css={copyStyles.dot} />
        </div>
        <h2 css={copyStyles.subtitle}>{step2Title}</h2>
        <p css={copyStyles.text}>{step2Description}</p>
      </div>
      <div css={copyStyles.content}>
        <div css={copyStyles.titleWrapper}>
          <Step3 />
          <p css={copyStyles.step}>
            <FormattedMessage id="shared.step" values={{ step: 3 }} />
          </p>
        </div>
        <h2 css={copyStyles.subtitle}>{step3Title}</h2>
        <p css={copyStyles.text}>{step3Description}</p>
      </div>
    </div>
  </div>
);
