import { css } from '@emotion/core';

import { color } from './mixins.styles';
import { colors } from './vars.styles';

const carousel = css`
  .image-carousel {
    width: 500px;
    height: 400px;

    .image-gallery-thumbnail.active {
      border: 4px solid ${color(colors.primary)};
    }

    .image-gallery-left-nav {
      padding: 10px;
      z-index: 1;
    }

    .image-gallery-left-nav svg {
      width: 40px;
      height: 40px;
      color: white;
      transition: color 0.2s ease;
    }

    .image-gallery-left-nav:hover svg {
      color: ${color(colors.primary)};
    }

    .image-gallery-right-nav {
      padding: 10px;
      z-index: 1;
    }

    .image-gallery-right-nav svg {
      width: 40px;
      height: 40px;
      color: white;
      transition: color 0.2s ease;
    }

    .image-gallery-right-nav:hover svg {
      color: ${color(colors.primary)};
    }

    .image-gallery-thumbnails {
      position: relative;
    }

    .image-gallery-thumbnails:after {
      content: '';
      position: absolute;
      width: 100px;
      height: 100%;
      top: 0;
      right: 0;
      background: linear-gradient(90deg, transparent 0%, white 100%);
      z-index: 1;
      pointer-events: none;
    }
  }

  .product-image img {
    width: 320px;
    height: 320px;
    margin: 0 auto;
  }

  .product-thumbnail {
    width: 100px;
    height: 100px;
    cursor: pointer;
  }

  .product-thumbnail:hover,
  .product-thumbnail:active {
    border: 4px solid ${color(colors.primary)};
  }
`;

const root = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 76px;
  width: 100%;
  ${carousel};
`;

export const productImageStyles = {
  root,
};
