import { Product } from 'models';

export function filterByColor(
  selectedColors: Record<string, boolean>,
  initialList: Product[],
) {
  const checkedColors = Object.entries(selectedColors).reduce(
    (acc: string[], [key, value]) => {
      if (value) return [...acc, key];
      return acc;
    },
    [],
  );

  const filteredColorProducts = checkedColors.length
    ? initialList.filter((product) =>
        product.commercial.corporateColor
          ? checkedColors.includes(product.commercial.corporateColor)
          : false,
      )
    : initialList;

  return filteredColorProducts;
}
